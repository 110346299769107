import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CancelInvoiceReason;
(function (CancelInvoiceReason) {
    CancelInvoiceReason[CancelInvoiceReason["InvalidFile"] = 0] = "InvalidFile";
    CancelInvoiceReason[CancelInvoiceReason["IncompleteData"] = 1] = "IncompleteData";
    CancelInvoiceReason[CancelInvoiceReason["InvalidBankDetails"] = 2] = "InvalidBankDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectAccountDetails"] = 3] = "IncorrectAccountDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectBeneficiaryDetails"] = 4] = "IncorrectBeneficiaryDetails";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectAmount"] = 5] = "IncorrectAmount";
    CancelInvoiceReason[CancelInvoiceReason["IncorrectCurrency"] = 6] = "IncorrectCurrency";
    CancelInvoiceReason[CancelInvoiceReason["InvalidDate"] = 7] = "InvalidDate";
    CancelInvoiceReason[CancelInvoiceReason["Other"] = 8] = "Other";
})(CancelInvoiceReason || (CancelInvoiceReason = {}));
export const CancelInvoiceReasonMap = new Map([
    [CancelInvoiceReason.InvalidFile, 'Global.Options.CancelInvoiceReason.InvalidFile'],
    [CancelInvoiceReason.IncompleteData, 'Global.Options.CancelInvoiceReason.IncompleteData'],
    [CancelInvoiceReason.InvalidBankDetails, 'Global.Options.CancelInvoiceReason.InvalidBankDetails'],
    [CancelInvoiceReason.IncorrectAccountDetails, 'Global.Options.CancelInvoiceReason.IncorrectAccountDetails'],
    [
        CancelInvoiceReason.IncorrectBeneficiaryDetails,
        'Global.Options.CancelInvoiceReason.IncorrectBeneficiaryDetails',
    ],
    [CancelInvoiceReason.IncorrectAmount, 'Global.Options.CancelInvoiceReason.IncorrectAmount'],
    [CancelInvoiceReason.IncorrectCurrency, 'Global.Options.CancelInvoiceReason.IncorrectCurrency'],
    [CancelInvoiceReason.InvalidDate, 'Global.Options.CancelInvoiceReason.InvalidDate'],
    [CancelInvoiceReason.Other, 'Global.Options.CancelInvoiceReason.Other'],
]);
let CancelInvoiceReasonService = class CancelInvoiceReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CancelInvoiceReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
CancelInvoiceReasonService.ctorParameters = () => [
    { type: TranslateService }
];
CancelInvoiceReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CancelInvoiceReasonService);
export { CancelInvoiceReasonService };
