import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { DictionariesQuery } from './dictionaries.query';
import { DictionariesStore } from './dictionaries.store';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
let DataSourceHandlerService = class DataSourceHandlerService {
    constructor(_httpClient, _dictionariesQuery, _dictionariesStore) {
        this._httpClient = _httpClient;
        this._dictionariesQuery = _dictionariesQuery;
        this._dictionariesStore = _dictionariesStore;
    }
    getData(apiKey, params) {
        let sortedParams = {};
        Object.keys(Object.assign({}, params))
            .sort()
            .forEach((key) => {
            sortedParams = params[key] ? Object.assign(Object.assign({}, sortedParams), { [key]: params[key] }) : Object.assign({}, sortedParams);
        });
        const newKey = apiKey + JSON.stringify(Object.assign({}, sortedParams));
        const { matchedQuery: query } = this._getActualStore(newKey);
        return query.length
            ? of({ data: query[0]['data'] })
            : this._httpClient.get(apiKey, params).pipe(tap((res) => {
                const { actualQueries, matchedQuery } = this._getActualStore(newKey);
                if (!matchedQuery.length) {
                    this._storeUpdate(actualQueries, newKey, res);
                }
            }));
    }
    _getActualStore(key) {
        const currentMoment = +new Date();
        const aliveQueries = this._dictionariesQuery.query.filter(item => currentMoment - item['_ts'] < 1440000);
        return {
            actualQueries: aliveQueries,
            matchedQuery: aliveQueries.filter(item => item['key'] === key)
        };
    }
    _storeUpdate(queryList, queryKey, response) {
        this._dictionariesStore.update(state => {
            const data = [...response['data']];
            return Object.assign(Object.assign({}, state), { query: queryList.length
                    ? [...queryList, ...[{ key: queryKey, data, _ts: +new Date() }]]
                    : [{ key: queryKey, data, _ts: +new Date() }] });
        });
    }
};
DataSourceHandlerService.ctorParameters = () => [
    { type: HttpClient },
    { type: DictionariesQuery },
    { type: DictionariesStore }
];
DataSourceHandlerService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient,
        DictionariesQuery,
        DictionariesStore])
], DataSourceHandlerService);
export { DataSourceHandlerService };
