import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./permissions-block.component.html";
import __NG_CLI_RESOURCE__1 from "./permissions-block.component.css";
import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PermissionsService } from '@ho/libs/shared/services/permissions.service';
import { RegistrationTypePermissions } from '../../../../../../data-access/core/src/lib/+state/settings.service';
let PermissionsBlockComponent = class PermissionsBlockComponent {
    constructor(_permissionsService, _cdr) {
        this._cdr = _cdr;
        const [hasPermission] = _permissionsService.hasPermissions([RegistrationTypePermissions.Moderated]);
        this.hasPermissionUIRegistrationTypeModerated = hasPermission;
    }
    ngOnInit() {
        setTimeout(() => {
            this._cdr.detectChanges();
        }, 2000);
    }
};
PermissionsBlockComponent.ctorParameters = () => [
    { type: PermissionsService },
    { type: ChangeDetectorRef }
];
PermissionsBlockComponent.propDecorators = {
    permissionForm: [{ type: Input }],
    block: [{ type: Input }]
};
PermissionsBlockComponent = __decorate([
    Component({
        selector: 'ho-permissions-block',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [PermissionsService, ChangeDetectorRef])
], PermissionsBlockComponent);
export { PermissionsBlockComponent };
