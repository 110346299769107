import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mapTo, pluck } from 'rxjs/operators';
import { FileHelper } from '@ho/shared/helpers/file.helper';
import { map as _map } from 'lodash-es';
import { CountryFlagService } from '@ho/shared/widgets/country-flag-widget';
import { SessionQuery } from '@ho/shared/data-access/session';
let OfferApiService = class OfferApiService {
    constructor(_httpClient, _countryFlagService, _sessionQuery) {
        this._httpClient = _httpClient;
        this._countryFlagService = _countryFlagService;
        this._sessionQuery = _sessionQuery;
        this.endpointUrl = '/admin-campaigns';
        this.offerManagementEndpointUrl = '/offer-management/admin-campaigns';
        this.pixelsEndpointUrl = '/pixels';
        this.landingPageEndpointUrl = '/offer-catalog/landing-page';
    }
    list(body) {
        return this._httpClient.post(`${this.offerManagementEndpointUrl}/list`, body, {
            observe: 'response',
        });
    }
    promoRequest(params = {}) {
        return this._httpClient
            .post(`${this.offerManagementEndpointUrl}/apply`, params)
            .pipe(pluck('data'));
    }
    get(id, options) {
        return this._httpClient.get(`${this.offerManagementEndpointUrl}/${id}`, options);
    }
    put(id, body, options) {
        return this._httpClient.put(`${this.offerManagementEndpointUrl}/${id}`, body, options);
    }
    getIncomingPixels(id, options) {
        return this._httpClient
            .get(`${this.pixelsEndpointUrl}/incoming-pixels/${id}`, options)
            .pipe(pluck('data'));
    }
    getPromoCodePixels(id, options) {
        return this._httpClient
            .get(`${this.pixelsEndpointUrl}/promo-pixels/${id}`, options)
            .pipe(pluck('data'));
    }
    getMyIncomingPixels(options) {
        return this._httpClient
            .get(`${this.pixelsEndpointUrl}/incoming-pixels`, options)
            .pipe(pluck('data'));
    }
    getMyPromoCodePixels(options) {
        return this._httpClient
            .get(`${this.pixelsEndpointUrl}/promo-pixels`, options)
            .pipe(pluck('data'));
    }
    setStatus(id, status, options) {
        return this._httpClient.put(`${this.offerManagementEndpointUrl}/${id}`, { status }, options);
    }
    init(options) {
        return this._httpClient.post(`${this.offerManagementEndpointUrl}/init`, {}, options);
    }
    duplicate(id, body, options) {
        return this._httpClient.post(`${this.offerManagementEndpointUrl}/${id}/duplicate`, body, options);
    }
    offerChangeLog(body, options) {
        return this._httpClient
            .post(`/logs/campaign-change-logs/list`, body, Object.assign(Object.assign({}, options), { observe: 'response' }))
            .pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (offerChangeLog) => {
                return Object.assign(Object.assign({}, offerChangeLog), { country_alpha_code: this._countryFlagService.getCountryCode(offerChangeLog.country_id) });
            });
            return response;
        }));
    }
    offerChangeLogCsv(body, options) {
        return this._httpClient
            .post(`/logs/campaign-change-logs/export-csv`, Object.assign({}, body), Object.assign(Object.assign({}, options), { responseType: 'arraybuffer', observe: 'response' }))
            .pipe(map((r) => FileHelper.save(r, '/campaign-change-log.csv')), mapTo(true));
    }
    imageUpload(id, options) {
        return this._httpClient.get(`${this.landingPageEndpointUrl}/${id}/preview-image`, {
            responseType: 'arraybuffer',
            observe: 'response',
        });
    }
    getBanners(options) {
        return this._httpClient.post(`${this.offerManagementEndpointUrl}/attachment-list`, Object.assign({}, options));
    }
    removeBanner(options) {
        return this._httpClient
            .post(`${this.offerManagementEndpointUrl}/remove-attachment`, Object.assign({}, options))
            .pipe(pluck('data'));
    }
    downloadBanner(url, name) {
        return this._httpClient
            .get(url, {
            responseType: 'arraybuffer',
            observe: 'response',
        })
            .pipe(map((r) => FileHelper.save(r, name)), mapTo(true));
    }
    downloadBanners(campaignId, name) {
        return this._httpClient
            .post(`${this.offerManagementEndpointUrl}/download-all-attachments?campaign_id=${campaignId}`, {}, {
            responseType: 'arraybuffer',
            observe: 'response',
        })
            .pipe(map((r) => FileHelper.save(r, name)), mapTo(true));
    }
};
OfferApiService.ctorParameters = () => [
    { type: HttpClient },
    { type: CountryFlagService },
    { type: SessionQuery }
];
OfferApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient,
        CountryFlagService,
        SessionQuery])
], OfferApiService);
export { OfferApiService };
