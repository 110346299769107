import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ContactTodayStatus;
(function (ContactTodayStatus) {
    ContactTodayStatus[ContactTodayStatus["New"] = 1] = "New";
    ContactTodayStatus[ContactTodayStatus["CallAgain"] = 2] = "CallAgain";
    ContactTodayStatus[ContactTodayStatus["Resolved"] = 3] = "Resolved";
})(ContactTodayStatus || (ContactTodayStatus = {}));
export const ContactTodayStatusLabels = new Map([
    [ContactTodayStatus.New, 'Global.Status.New'],
    [ContactTodayStatus.CallAgain, 'Global.Options.ContactTodayStatus.CallAgain'],
    [ContactTodayStatus.Resolved, 'Global.Options.ContactTodayStatus.Resolved'],
]);
let ContactTodayStatusService = class ContactTodayStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ContactTodayStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
ContactTodayStatusService.ctorParameters = () => [
    { type: TranslateService }
];
ContactTodayStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ContactTodayStatusService);
export { ContactTodayStatusService };
