import { version } from '../../../../package.json';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
export function TranslateLoaderFactory(http, document) {
    return new MultiTranslateHttpLoader(http, [
        {
            prefix: `./assets/vocabulary_${document.domain.indexOf('localhost') !== -1 ? '' : version + '_'}`,
            suffix: '.json',
        },
    ]);
}
export class MissingTranslationService {
    handle(params) {
        // TODO refactor custom filters
        // return `WARN: '${params.key}' is missing in '${params.translateService.currentLang}' locale`;
        return params.key;
    }
}
