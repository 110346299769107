import { APP_INITIALIZER } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export const translationsInitializerProvider = {
    provide: APP_INITIALIZER,
    useFactory: function initTranslations(_translate) {
        return () => {
            _translate.addLangs(['en', 'ru']);
            _translate.setDefaultLang(localStorage.getItem('language') || 'en');
            return _translate.get(['en', 'ru']).toPromise();
        };
    },
    multi: true,
    deps: [TranslateService],
};
