import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountryFlagWidgetComponent } from './components/country-flag-widget/country-flag-widget.component';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
let CountryFlagWidgetModule = class CountryFlagWidgetModule {
};
CountryFlagWidgetModule = __decorate([
    NgModule({
        imports: [CommonModule, QaAttributeWidgetModule],
        declarations: [CountryFlagWidgetComponent],
        exports: [CountryFlagWidgetComponent],
    })
], CountryFlagWidgetModule);
export { CountryFlagWidgetModule };
