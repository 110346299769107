import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAnalyticsComponent } from './components/google-analytics/google-analytics.component';
import { GoogleAnalyticsService } from './service/google-analytics.service';
import { RouterModule } from '@angular/router';
let GoogleAnalyticsModule = class GoogleAnalyticsModule {
};
GoogleAnalyticsModule = __decorate([
    NgModule({
        imports: [CommonModule, RouterModule],
        declarations: [GoogleAnalyticsComponent],
        providers: [GoogleAnalyticsService],
        exports: [GoogleAnalyticsComponent]
    })
], GoogleAnalyticsModule);
export { GoogleAnalyticsModule };
