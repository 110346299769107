import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { LandingPageApiService } from '@ho/shared/data-access/core/lib/+state/landing-page-api.service';
import { pluck } from 'rxjs/operators';
let LandingPageService = class LandingPageService {
    constructor(_landingPageApiService) {
        this._landingPageApiService = _landingPageApiService;
    }
    all(params) {
        return this._landingPageApiService.all(params);
    }
    get(id) {
        return this._landingPageApiService.get(id);
    }
    post(body, options) {
        return this._landingPageApiService.post(body, options);
    }
    save(body, options) {
        return this._landingPageApiService.save(body, options);
    }
    put(id, body, options) {
        return this._landingPageApiService.put(id, body, options);
    }
    delete(id) {
        return this._landingPageApiService.delete(id);
    }
    deleteImage(id) {
        return this._landingPageApiService.deleteImage(id);
    }
    init(body, options) {
        return this._landingPageApiService.init(body, options).pipe(pluck('data'));
    }
    batchChangeHost(body, options) {
        return this._landingPageApiService.batchChangeHost(body, options).pipe(pluck('data'));
    }
};
LandingPageService.ctorParameters = () => [
    { type: LandingPageApiService }
];
LandingPageService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [LandingPageApiService])
], LandingPageService);
export { LandingPageService };
