import { DateTime } from 'luxon';
import { InjectionToken } from '@angular/core';
export class PickerConfigItem {
    constructor(key, name = null, startFn, finishFn) {
        this.key = key;
        this.name = name;
        this.startFn = startFn;
        this.finishFn = finishFn;
        if (this.name === null) {
            const item = PickerConfigData.filter((v) => v.key === this.key)[0];
            this.name = (item && item.name) || 'Custom';
        }
    }
    get start() {
        return this.startFn() || null;
    }
    set start(v) {
        this.startFn = () => v;
    }
    get finish() {
        return this.finishFn() || null;
    }
    set finish(v) {
        this.finishFn = () => v;
    }
}
export const PickerConfigData = [
    new PickerConfigItem('today', 'Global.RangeSelect.Today', () => DateTime.utc().startOf('day'), () => DateTime.utc().endOf('day').startOf('second')),
    new PickerConfigItem('yesterday', 'Global.RangeSelect.Yesterday', () => DateTime.utc().minus({ days: 1 }).startOf('day'), () => DateTime.utc().minus({ days: 1 }).endOf('day').startOf('second')),
    new PickerConfigItem('last_3_days', 'Global.RangeSelect.Last3Days', () => DateTime.utc().minus({ days: 2 }).startOf('day'), () => DateTime.utc().endOf('day').startOf('second')),
    new PickerConfigItem('this_week', 'Global.RangeSelect.ThisWeek', () => DateTime.utc().startOf('week'), () => DateTime.utc().endOf('week').startOf('second')),
    new PickerConfigItem('last_week', 'Global.RangeSelect.LastWeek', () => DateTime.utc().minus({ weeks: 1 }).startOf('week'), () => DateTime.utc().minus({ weeks: 1 }).endOf('week').startOf('second')),
    new PickerConfigItem('this_month', 'Global.RangeSelect.ThisMonth', () => DateTime.utc().startOf('month'), () => DateTime.utc().endOf('month').startOf('second')),
    new PickerConfigItem('last_month', 'Global.RangeSelect.LastMonth', () => DateTime.utc().minus({ months: 1 }).startOf('month'), () => DateTime.utc().minus({ months: 1 }).endOf('month').startOf('second')),
    new PickerConfigItem('last_quarter', 'Global.RangeSelect.LastQuarter', () => DateTime.utc().minus({ quarter: 1 }).startOf('day'), () => DateTime.utc().endOf('day').startOf('second')),
    new PickerConfigItem('last_6_months', 'Global.RangeSelect.Last6Months', () => DateTime.utc().minus({ months: 6, days: 1 }).startOf('day'), () => DateTime.utc().minus({ days: 1 }).endOf('day')),
    new PickerConfigItem('last_year', 'Global.RangeSelect.LastYear', () => DateTime.utc().minus({ year: 1, days: 1 }).startOf('day'), () => DateTime.utc().minus({ days: 1 }).endOf('day')),
    new PickerConfigItem('custom_date', 'Global.RangeSelect.CustomDate', () => null, () => null),
    new PickerConfigItem('custom_time', 'Global.RangeSelect.CustomTime', () => null, () => null),
];
export const PickerConfig = new InjectionToken('PickerConfig');
export var PickerPeriodType;
(function (PickerPeriodType) {
    PickerPeriodType[PickerPeriodType["Today"] = 0] = "Today";
    PickerPeriodType[PickerPeriodType["Yesterday"] = 1] = "Yesterday";
    PickerPeriodType[PickerPeriodType["LastThreeDays"] = 2] = "LastThreeDays";
    PickerPeriodType[PickerPeriodType["ThisWeek"] = 3] = "ThisWeek";
    PickerPeriodType[PickerPeriodType["LastWeek"] = 4] = "LastWeek";
    PickerPeriodType[PickerPeriodType["ThisMonth"] = 5] = "ThisMonth";
    PickerPeriodType[PickerPeriodType["LastMonth"] = 6] = "LastMonth";
    PickerPeriodType[PickerPeriodType["LastQuarter"] = 7] = "LastQuarter";
    PickerPeriodType[PickerPeriodType["LastSixMonths"] = 8] = "LastSixMonths";
    PickerPeriodType[PickerPeriodType["LastYear"] = 9] = "LastYear";
    PickerPeriodType[PickerPeriodType["Custom"] = 10] = "Custom";
    PickerPeriodType[PickerPeriodType["CustomTime"] = 11] = "CustomTime";
})(PickerPeriodType || (PickerPeriodType = {}));
export const PickerDefaultPeriod = PickerConfigData[PickerPeriodType.Today];
