import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var EmailStatus;
(function (EmailStatus) {
    EmailStatus[EmailStatus["Unconfirmed"] = 0] = "Unconfirmed";
    EmailStatus[EmailStatus["Confirmed"] = 1] = "Confirmed";
})(EmailStatus || (EmailStatus = {}));
export const EmailStatusMap = new Map([
    [EmailStatus.Unconfirmed, 'Global.EmailStatus.Unconfirmed'],
    [EmailStatus.Confirmed, 'Global.EmailStatus.Confirmed'],
]);
let EmailStatusService = class EmailStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = EmailStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case EmailStatus.Confirmed:
                return 'text-success';
            case EmailStatus.Unconfirmed:
                return 'text-danger';
            default:
                return 'text-warning';
        }
    }
};
EmailStatusService.ctorParameters = () => [
    { type: TranslateService }
];
EmailStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], EmailStatusService);
export { EmailStatusService };
