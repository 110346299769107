import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
export var DeepLinkStatus;
(function (DeepLinkStatus) {
    DeepLinkStatus[DeepLinkStatus["NotAllowed"] = 0] = "NotAllowed";
    DeepLinkStatus[DeepLinkStatus["Allowed"] = 1] = "Allowed";
})(DeepLinkStatus || (DeepLinkStatus = {}));
export const DeepLinkStatusStringMap = new Map([
    [DeepLinkStatus.NotAllowed, 'Global.Options.DeepLinkStatusString.NotAllowed'],
    [DeepLinkStatus.Allowed, 'Global.Options.DeepLinkStatusString.Allowed'],
]);
let DeepLinkService = class DeepLinkService {
    setStatusClass(status) {
        switch (status) {
            case DeepLinkStatus.Allowed:
                return 'text-success';
            case DeepLinkStatus.NotAllowed:
                return 'text-danger';
        }
    }
};
DeepLinkService = __decorate([
    Injectable()
], DeepLinkService);
export { DeepLinkService };
