import { __decorate, __metadata } from "tslib";
import { Directive, ViewContainerRef, Renderer2, ElementRef } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { DEVICE, TypeDevice } from '@ho/shared/constants/device';
let MobileTooltipHandlerDirective = class MobileTooltipHandlerDirective {
    constructor(_vcRef, _renderer, _el) {
        this._vcRef = _vcRef;
        this._renderer = _renderer;
        this._el = _el;
    }
    ngOnInit() {
        this._tooltip = this._vcRef.injector.get(MatTooltip);
        this._isMobile = DEVICE.type === TypeDevice.Mobile;
        if (this._isMobile) {
            this._renderer.listen(this._el.nativeElement, 'mouseenter', () => this._tooltip.show());
            this._renderer.listen(this._el.nativeElement, 'mouseleave', () => this._tooltip.hide());
        }
    }
    ngOnDestroy() {
        if (this._isMobile) {
            this._renderer.destroy();
        }
    }
};
MobileTooltipHandlerDirective.ctorParameters = () => [
    { type: ViewContainerRef },
    { type: Renderer2 },
    { type: ElementRef }
];
MobileTooltipHandlerDirective = __decorate([
    Directive({
        selector: '[mobileTooltipHandler]',
    }),
    __metadata("design:paramtypes", [ViewContainerRef, Renderer2, ElementRef])
], MobileTooltipHandlerDirective);
export { MobileTooltipHandlerDirective };
