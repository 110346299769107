import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ResponseStatus;
(function (ResponseStatus) {
    ResponseStatus[ResponseStatus["Error"] = 0] = "Error";
    ResponseStatus[ResponseStatus["Success"] = 1] = "Success";
})(ResponseStatus || (ResponseStatus = {}));
export const ResponseStatusMap = new Map([
    [ResponseStatus.Error, 'Global.Options.ResponseStatus.Error'],
    [ResponseStatus.Success, 'Global.Options.ResponseStatus.Success'],
]);
let ResponseStatusService = class ResponseStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ResponseStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
ResponseStatusService.ctorParameters = () => [
    { type: TranslateService }
];
ResponseStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ResponseStatusService);
export { ResponseStatusService };
