import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "./google-analytics.component.css";
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Component, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
let GoogleAnalyticsComponent = class GoogleAnalyticsComponent {
    constructor(_document, _environment, renderer) {
        this._document = _document;
        this._environment = _environment;
        this.renderer = renderer;
        if (this._environment.hasOwnProperty('gaUID') && this._environment.gaUID) {
            const scriptInitGTM = this._document.createElement('script');
            const scriptInitGA = this._document.createElement('script');
            const noScriptInitGTM = this._document.createElement('noscript');
            const iframeGtm = this._document.createElement('iframe');
            const scriptImportGTM = this.renderer.createElement('script');
            scriptImportGTM.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${this._environment.gaUID}`);
            scriptImportGTM.setAttribute('async', '');
            iframeGtm.setAttribute('src', `https://www.googletagmanager.com/ns.html?id=${this._environment.gtmUID}`);
            iframeGtm.setAttribute('height', '0');
            iframeGtm.setAttribute('width', '0');
            iframeGtm.setAttribute('style', 'display:none;visibility:hidden');
            noScriptInitGTM.setAttribute('class', 'gtagNoscript');
            scriptInitGA.innerHTML = `
          window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${this._environment.gaUID}')`;
            scriptInitGTM.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this._environment.gtmUID}');
      `;
            this._document.head.appendChild(scriptInitGTM);
            this._document.body.appendChild(scriptImportGTM);
            this._document.body.appendChild(scriptInitGA);
            this._document.body.appendChild(noScriptInitGTM);
            this._document.getElementsByClassName('gtagNoscript')[0].appendChild(iframeGtm);
        }
    }
    ngOnInit() { }
};
GoogleAnalyticsComponent.ctorParameters = () => [
    { type: HTMLDocument, decorators: [{ type: Inject, args: [DOCUMENT,] }] },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Renderer2 }
];
GoogleAnalyticsComponent = __decorate([
    Component({
        selector: 'ho-google-analytics',
        template: '',
        styles: [__NG_CLI_RESOURCE__0]
    }),
    __metadata("design:paramtypes", [HTMLDocument, Object, Renderer2])
], GoogleAnalyticsComponent);
export { GoogleAnalyticsComponent };
