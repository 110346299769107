import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CampaignType;
(function (CampaignType) {
    CampaignType[CampaignType["OfferGroup"] = 1] = "OfferGroup";
    CampaignType[CampaignType["Offer"] = 2] = "Offer";
    CampaignType[CampaignType["Affiliate"] = 3] = "Affiliate";
})(CampaignType || (CampaignType = {}));
export const CampaignTypeLabels = new Map([
    [CampaignType.OfferGroup, 'Global.Options.CampaignTypeLabels.OfferGroup'],
    [CampaignType.Offer, 'Global.Options.Offer'],
    [CampaignType.Affiliate, 'Global.Options.CampaignTypeLabels.Affiliate'],
]);
let CampaignTypeService = class CampaignTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CampaignTypeLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
CampaignTypeService.ctorParameters = () => [
    { type: TranslateService }
];
CampaignTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CampaignTypeService);
export { CampaignTypeService };
