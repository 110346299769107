import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
let DictionariesStore = class DictionariesStore extends EntityStore {
    constructor() {
        super({ query: [] });
    }
};
DictionariesStore.ctorParameters = () => [];
DictionariesStore = __decorate([
    Injectable(),
    StoreConfig({
        name: StoreNames.Dictionaries,
    }),
    __metadata("design:paramtypes", [])
], DictionariesStore);
export { DictionariesStore };
