import { APP_INITIALIZER } from '@angular/core';
import { SettingsService } from '@ho/shared/data-access/+state';
export const settingsInitializerProvider = {
    provide: APP_INITIALIZER,
    useFactory: function customAppConfigInitializerFactory(settingsService) {
        return () => settingsService.system().toPromise();
    },
    multi: true,
    deps: [SettingsService],
};
