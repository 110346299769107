import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ContactTodayReasonATL;
(function (ContactTodayReasonATL) {
    ContactTodayReasonATL["NoLeads"] = "no_lead_24h";
    ContactTodayReasonATL["NoFTDs"] = "no_ftd_24h";
    ContactTodayReasonATL["CTLIncrease"] = "ctl_increase_24h";
    ContactTodayReasonATL["CRIncrease"] = "cr_increase_24h";
    ContactTodayReasonATL["EPCIncrease"] = "epc_increase_24h";
    ContactTodayReasonATL["CTLDecrease"] = "ctl_decrease_24h";
    ContactTodayReasonATL["CRDecrease"] = "cr_decrease_24h";
    ContactTodayReasonATL["EPCDecrease"] = "epc_decrease_24h";
    ContactTodayReasonATL["TrafficHalt"] = "no_traffic_24h";
    ContactTodayReasonATL["NewGeoStartedClick"] = "new_geo_started_click";
    ContactTodayReasonATL["NewGeoStartedLead"] = "new_geo_started_lead";
    ContactTodayReasonATL["TrafficOnTheWeekend"] = "traffic_on_weekend";
    ContactTodayReasonATL["NegativeLeadsIncrease"] = "lds_negative_status_increase_24h";
    ContactTodayReasonATL["ActiveAffiliatesIncrease"] = "active_affiliate_increase";
    ContactTodayReasonATL["ActiveAffiliatesDecrease"] = "active_affiliate_decrease";
    ContactTodayReasonATL["NewAffiliatesIncrease"] = "new_affiliate_increase";
    ContactTodayReasonATL["UnresolvedCriticalAlerts"] = "unresolved_critical_alerts";
    ContactTodayReasonATL["ExpiredPaymentDateInBilling"] = "expired_payment_date";
})(ContactTodayReasonATL || (ContactTodayReasonATL = {}));
export const ContactTodayReasonATLMap = new Map([
    [ContactTodayReasonATL.NoLeads, 'Global.Options.ContactTodayReasonATL.NoLeads'],
    [ContactTodayReasonATL.NoFTDs, 'Global.Options.ContactTodayReasonATL.NoFTDs'],
    [ContactTodayReasonATL.CTLIncrease, 'Global.Options.ContactTodayReasonATL.CTLIncrease'],
    [ContactTodayReasonATL.CRIncrease, 'Global.Options.ContactTodayReasonATL.CRIncrease'],
    [ContactTodayReasonATL.EPCIncrease, 'Global.Options.ContactTodayReasonATL.EPCIncrease'],
    [ContactTodayReasonATL.CTLDecrease, 'Global.Options.ContactTodayReasonATL.CTLDecrease'],
    [ContactTodayReasonATL.CRDecrease, 'Global.Options.ContactTodayReasonATL.CRDecrease'],
    [ContactTodayReasonATL.EPCDecrease, 'Global.Options.ContactTodayReasonATL.EPCDecrease'],
    [ContactTodayReasonATL.TrafficHalt, 'Global.Options.ContactTodayReasonATL.TrafficHalt'],
    [ContactTodayReasonATL.NewGeoStartedClick, 'Global.Options.ContactTodayReasonATL.NewGeoStartedClick'],
    [ContactTodayReasonATL.NewGeoStartedLead, 'Global.Options.ContactTodayReasonATL.NewGeoStartedLead'],
    [ContactTodayReasonATL.TrafficOnTheWeekend, 'Global.Options.ContactTodayReasonATL.TrafficOnTheWeekend'],
    [ContactTodayReasonATL.NegativeLeadsIncrease, 'Global.Options.ContactTodayReasonATL.NegativeLeadsIncrease'],
    [
        ContactTodayReasonATL.ActiveAffiliatesIncrease,
        'Global.Options.ContactTodayReasonATL.ActiveAffiliatesIncrease',
    ],
    [
        ContactTodayReasonATL.ActiveAffiliatesDecrease,
        'Global.Options.ContactTodayReasonATL.ActiveAffiliatesDecrease',
    ],
    [ContactTodayReasonATL.NewAffiliatesIncrease, 'Global.Options.ContactTodayReasonATL.NewAffiliatesIncrease'],
    [
        ContactTodayReasonATL.UnresolvedCriticalAlerts,
        'Global.Options.ContactTodayReasonATL.UnresolvedCriticalAlerts',
    ],
    [
        ContactTodayReasonATL.ExpiredPaymentDateInBilling,
        'Global.Options.ContactTodayReasonATL.ExpiredPaymentDateInBilling',
    ],
]);
let ContactTodayReasonAdminATLService = class ContactTodayReasonAdminATLService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ContactTodayReasonATLMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
    has(key) {
        return this._data.has(key);
    }
};
ContactTodayReasonAdminATLService.ctorParameters = () => [
    { type: TranslateService }
];
ContactTodayReasonAdminATLService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ContactTodayReasonAdminATLService);
export { ContactTodayReasonAdminATLService };
