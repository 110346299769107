import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationService } from './+state/registration.service';
import { RegistrationApiService } from './+state/registration-api.service';
let AuthDataAccessModule = class AuthDataAccessModule {
};
AuthDataAccessModule = __decorate([
    NgModule({
        imports: [CommonModule],
        providers: [RegistrationService, RegistrationApiService]
    })
], AuthDataAccessModule);
export { AuthDataAccessModule };
