import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UserRegistrationStatus;
(function (UserRegistrationStatus) {
    UserRegistrationStatus[UserRegistrationStatus["Pending"] = 0] = "Pending";
    UserRegistrationStatus[UserRegistrationStatus["Confirmed"] = 1] = "Confirmed";
    UserRegistrationStatus[UserRegistrationStatus["Rejected"] = 2] = "Rejected";
    UserRegistrationStatus[UserRegistrationStatus["Completed"] = 10] = "Completed";
})(UserRegistrationStatus || (UserRegistrationStatus = {}));
export const UserRegistrationStatusMap = new Map([
    [UserRegistrationStatus.Pending, 'Global.Status.Pending'],
    [UserRegistrationStatus.Confirmed, 'Global.Options.UserRegistrationStatus.Confirmed'],
    [UserRegistrationStatus.Rejected, 'Global.Status.Rejected'],
    [UserRegistrationStatus.Completed, 'Global.Options.UserRegistrationStatus.Completed'],
]);
let UserRegistrationStatusService = class UserRegistrationStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UserRegistrationStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
UserRegistrationStatusService.ctorParameters = () => [
    { type: TranslateService }
];
UserRegistrationStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UserRegistrationStatusService);
export { UserRegistrationStatusService };
