import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./confirm-action-modal.container.html";
import __NG_CLI_RESOURCE__1 from "./confirm-action-modal.container.scss";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
let ConfirmActionModalContainer = class ConfirmActionModalContainer {
    constructor(_dialogRef, modalData) {
        this._dialogRef = _dialogRef;
        this.modalData = modalData;
    }
    ngOnInit() {
        this.actions = this.modalData.actions.map((item) => {
            if (typeof item === 'string') {
                return {
                    value: item,
                };
            }
            else {
                return item;
            }
        });
    }
    onSubmit(key) {
        this._dialogRef.close(key);
    }
};
ConfirmActionModalContainer.ctorParameters = () => [
    { type: MatDialogRef },
    { type: undefined, decorators: [{ type: Inject, args: [MAT_DIALOG_DATA,] }] }
];
ConfirmActionModalContainer = __decorate([
    Component({
        selector: 'ho-confirm-modal-container',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], ConfirmActionModalContainer);
export { ConfirmActionModalContainer };
