import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
let UiSettingsGridCacheStore = class UiSettingsGridCacheStore extends Store {
    constructor() {
        super({ cache: {}, payload: {} });
    }
    addCache(data) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { cache: Object.assign(Object.assign({}, cloneDeep(state.cache)), data) });
        });
    }
    removeCache() {
        this.update((state) => (Object.assign(Object.assign({}, state), { cache: {} })));
    }
    addPayload(data) {
        this.update((state) => {
            return Object.assign(Object.assign({}, state), { payload: Object.assign(Object.assign({}, cloneDeep(state.payload)), data) });
        });
    }
    removePayload() {
        this.update((state) => (Object.assign(Object.assign({}, state), { payload: {} })));
    }
};
UiSettingsGridCacheStore.ctorParameters = () => [];
UiSettingsGridCacheStore = __decorate([
    Injectable(),
    StoreConfig({ name: StoreNames.UiSettingsGridCache }),
    __metadata("design:paramtypes", [])
], UiSettingsGridCacheStore);
export { UiSettingsGridCacheStore };
