import { __decorate, __metadata } from "tslib";
import { DashboardVisibleConfigModalContainer } from '../containers/dashboard-visible-config-modal/dashboard-visible-config-modal.container';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalHelper } from '@ho/shared/helpers/modal.helper';
let DashboardVisibleConfigModalService = class DashboardVisibleConfigModalService {
    constructor(dialog) {
        this.dialog = dialog;
        this._defaultOptions = {
            maxWidth: '368px',
            width: 'calc(100% - 30px)',
            minWidth: '290px',
            autoFocus: false,
            panelClass: 'modal-dialog',
        };
    }
    open(dashboardSettings, ids) {
        const ref = this.dialog.open(DashboardVisibleConfigModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: { dashboardSettings, ids } }));
        ModalHelper.addClassToParent(ref);
        return ref;
    }
};
DashboardVisibleConfigModalService.ctorParameters = () => [
    { type: MatDialog }
];
DashboardVisibleConfigModalService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [MatDialog])
], DashboardVisibleConfigModalService);
export { DashboardVisibleConfigModalService };
