import { __decorate, __metadata } from "tslib";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
let RegistrationApiService = class RegistrationApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    register(body, options) {
        return this.httpClient.post(`/registration/first-time?th-lang=${localStorage.getItem('language') || 'en'}&ws=${location.origin}`, Object.assign(Object.assign({}, body), { ws: location.origin }), options);
    }
    verifyEmail(body, options) {
        return this.httpClient.post(`/registration/verify-email?th-lang=${localStorage.getItem('language') || 'en'}`, body, options);
    }
};
RegistrationApiService.ctorParameters = () => [
    { type: HttpClient }
];
RegistrationApiService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], RegistrationApiService);
export { RegistrationApiService };
