import { Observable } from 'rxjs';
export function outsideZone(zone) {
    // tslint: disable-next-line
    return function (source) {
        return new Observable(observer => {
            let sub;
            zone.runOutsideAngular(() => {
                sub = source.subscribe(observer);
            });
            return sub;
        });
    };
}
