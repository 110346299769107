import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmModalContainer } from './containers/confirm-modal/confirm-modal.container';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { ConfirmModalService } from './services/confirm-modal.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ConfirmActionModalContainer } from './containers/confirm-action-modal/confirm-action-modal.container';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
let ConfirmModalModule = class ConfirmModalModule {
};
ConfirmModalModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            MatButtonModule,
            MatDialogModule,
            FlexLayoutModule,
            QaAttributeWidgetModule,
            TranslateModule,
        ],
        declarations: [ConfirmModalContainer, ConfirmActionModalContainer],
        entryComponents: [ConfirmModalContainer, ConfirmActionModalContainer],
        providers: [ConfirmModalService],
    })
], ConfirmModalModule);
export { ConfirmModalModule };
