import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
let AccountApiService = class AccountApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/account';
    }
    get(options) {
        return this._httpClient.get(`${this.endpointUrl}`, options).pipe(pluck('data'));
    }
    changePassword(body) {
        return this._httpClient.post(`${this.endpointUrl}/change-password`, body);
    }
};
AccountApiService.ctorParameters = () => [
    { type: HttpClient }
];
AccountApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], AccountApiService);
export { AccountApiService };
