import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "./success-snack-bar.component.scss";
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
let SuccessSnackBarComponent = class SuccessSnackBarComponent {
    constructor(data, snackBarRef) {
        this.data = data;
        this.snackBarRef = snackBarRef;
        this.duration = this.snackBarRef.containerInstance.snackBarConfig.duration;
    }
    ngOnInit() {
        this.snackBarRef.afterOpened().subscribe(() => {
            this.snackBarRef.containerInstance.snackBarConfig.duration = 300000;
            this.onCloseWithDuration();
        });
    }
    onClose() {
        this.snackBarRef.dismiss();
    }
    onCloseWithDuration() {
        this._timer = setTimeout(() => {
            this.snackBarRef.dismiss();
        }, this.duration);
    }
    onHover() {
        clearTimeout(this._timer);
    }
    onLeave() {
        this.onCloseWithDuration();
    }
};
SuccessSnackBarComponent.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [MAT_SNACK_BAR_DATA,] }] },
    { type: MatSnackBarRef }
];
SuccessSnackBarComponent = __decorate([
    Component({
        selector: 'ho-success-snack-bar',
        template: `
    <div
      fxLayout="row nowrap"
      class="snack-container toast-success"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="10px"
      (mouseenter)="onHover()"
      (mouseleave)="onLeave()"
    >
      <i class="icon-check-circle snack-container__icon"></i>
      <div fxLayout="column">
        <div>
          <b>{{ data?.title }}</b>
        </div>
        <div>
          <span>{{ data?.message }}</span>
        </div>
      </div>
      <button class="snack-container__icon snack-container__close-btn" (click)="onClose()">
        <span class="icon-close"></span>
      </button>
    </div>
  `,
        styles: [__NG_CLI_RESOURCE__0]
    }),
    __metadata("design:paramtypes", [Object, MatSnackBarRef])
], SuccessSnackBarComponent);
export { SuccessSnackBarComponent };
