import { __decorate } from "tslib";
import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
let ClickClosestDirective = class ClickClosestDirective {
    constructor() {
        this.clickChange = new EventEmitter();
    }
    click(event) {
        if (event.target.closest(this.clickClosest)) {
            this.clickChange.emit();
        }
    }
};
ClickClosestDirective.propDecorators = {
    clickClosest: [{ type: Input }],
    clickChange: [{ type: Output }],
    click: [{ type: HostListener, args: ['click', ['$event'],] }]
};
ClickClosestDirective = __decorate([
    Directive({
        selector: '[clickClosest]',
    })
], ClickClosestDirective);
export { ClickClosestDirective };
