export class CustomErrorStateMatcher {
    isErrorState(control, form) {
        return !!(control && control.invalid && (control.dirty || control.touched));
    }
}
// tslint:disable-next-line: max-classes-per-file
export class PasswordsMatcher {
    isErrorState(control, form) {
        return !!((control.invalid && control.touched) ||
            (control &&
                control.touched &&
                control.parent.controls['password'].touched &&
                control.value !== control.parent.controls['password'].value));
    }
}
