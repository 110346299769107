import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, pluck } from 'rxjs/operators';
import { DataSourceHandlerService } from '../+state/data-source-handler.service';
import { isArray } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
export var CommissionType;
(function (CommissionType) {
    CommissionType[CommissionType["CPA"] = 1] = "CPA";
    CommissionType[CommissionType["CPLLead"] = 2] = "CPLLead";
    CommissionType[CommissionType["CPLOpt"] = 3] = "CPLOpt";
    CommissionType[CommissionType["Revshare"] = 4] = "Revshare";
})(CommissionType || (CommissionType = {}));
export const CommissionLabels = new Map([
    [CommissionType.CPA, 'Global.Options.CommissionLabels.CPA'],
    [CommissionType.CPLLead, 'Global.Options.CommissionLabels.CPLLead'],
    [CommissionType.CPLOpt, 'Global.Options.CommissionLabels.CPLOpt'],
    [CommissionType.Revshare, 'Global.Options.Revshare'],
]);
let CommissionTypesService = class CommissionTypesService {
    constructor(_httpClient, _dataSourceHandlerService, _translate) {
        this._httpClient = _httpClient;
        this._dataSourceHandlerService = _dataSourceHandlerService;
        this._translate = _translate;
        this._endpointUrl = '/dictionary';
        this._data = CommissionLabels;
    }
    list(deprecatedIds = []) {
        return this._dataSourceHandlerService.getData(`${this._endpointUrl}/commission-types`).pipe(map((r) => {
            if (!isArray(deprecatedIds)) {
                return r;
            }
            r.data = r.data.filter((item) => !deprecatedIds.includes(item.id));
            return r;
        }));
    }
    get(id) {
        return this._httpClient
            .get(`${this._endpointUrl}/commission-types/${id}`)
            .pipe(pluck('data'));
    }
    getLanguages(body) {
        return this._httpClient
            .post(`${this._endpointUrl}/languages/list`, body)
            .pipe(pluck('data'))
            .toPromise();
    }
    getName(type) {
        const t = this._data.get(type);
        return t ? this._translate.instant(t) : t;
    }
};
CommissionTypesService.ctorParameters = () => [
    { type: HttpClient },
    { type: DataSourceHandlerService },
    { type: TranslateService }
];
CommissionTypesService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient,
        DataSourceHandlerService,
        TranslateService])
], CommissionTypesService);
export { CommissionTypesService };
