import { __decorate, __metadata } from "tslib";
import { SessionQuery, SessionService, SessionUserTypeService } from '@ho/shared/data-access/session';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@ho/shared/ui/snack-bar';
import { Observable, of } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { map as _map } from 'lodash-es';
import { parseQueryParams, prepareQueryParams } from '@ho/libs/shared/services/query-params.helper';
let AuthGuardService = class AuthGuardService {
    constructor(_authQuery, _authService, _router, _snack, _sessionUserTypeService, environment, document) {
        this._authQuery = _authQuery;
        this._authService = _authService;
        this._router = _router;
        this._snack = _snack;
        this._sessionUserTypeService = _sessionUserTypeService;
        this.environment = environment;
        this.document = document;
    }
    canLoad(route, segments) {
        this.requestParams = parseQueryParams(this.document.location.search.replace('?', ''));
        const path = (this.requestUrl = _map(segments, 'path').join('/'));
        return Observable.create((observer) => {
            const idToken = localStorage.getItem(this.environment.jwtTokenName);
            if (!this._authQuery.isLoggedIn()) {
                if (idToken) {
                    return this._autologin(path, observer, idToken);
                }
                else {
                    this._authService.logout();
                    return of(this._redirectToLogin(observer));
                }
            }
            else {
                if (!this._authQuery.userInfo()) {
                    return this._autologin(path, observer, idToken || this._authQuery.token());
                }
                else {
                    if (!this._appropriateRole()) {
                        return of(this._authService.switchApp(observer));
                    }
                    else if (idToken &&
                        this._authQuery.token() !== undefined &&
                        this._authQuery.token() !== idToken) {
                        return this._autologin(path, observer, idToken || this._authQuery.token());
                    }
                    else {
                        observer.next(true);
                        observer.complete();
                    }
                }
            }
        });
    }
    _autologin(path, observer, idToken) {
        return this._authService.autologin(idToken).subscribe(() => {
            if (this._appropriateRole()) {
                this._router.navigate([`/d/${path}`]);
                observer.next(false);
                observer.complete();
            }
            else {
                this._authService.switchApp(observer);
            }
        });
    }
    _appropriateRole() {
        return this.environment.role.includes(this._authQuery.userInfo().workspace_role_id);
    }
    _redirectToLogin(observer) {
        if (!this.environment.production) {
            this._router.navigate(['/a/login'], {
                queryParams: { requestUrl: this.requestUrl, requestParams: prepareQueryParams(this.requestParams) },
            });
        }
        else {
            this.document.location.href = `${location.origin}/v3/auth?requestUrl=${this.requestUrl}&requestParams=${prepareQueryParams(this.requestParams)}`;
        }
        observer.next(false);
        observer.complete();
    }
};
AuthGuardService.ctorParameters = () => [
    { type: SessionQuery },
    { type: SessionService },
    { type: Router },
    { type: SnackbarService },
    { type: SessionUserTypeService },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Document, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];
AuthGuardService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [SessionQuery,
        SessionService,
        Router,
        SnackbarService,
        SessionUserTypeService, Object, Document])
], AuthGuardService);
export { AuthGuardService };
