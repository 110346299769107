import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickClosestDirective } from './click-closest.directive';
let ClickClosestModule = class ClickClosestModule {
};
ClickClosestModule = __decorate([
    NgModule({
        imports: [CommonModule],
        declarations: [ClickClosestDirective],
        exports: [ClickClosestDirective],
    })
], ClickClosestModule);
export { ClickClosestModule };
