import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var VoidReason;
(function (VoidReason) {
    VoidReason["None"] = "none";
    VoidReason["Fraud"] = "fraud";
    VoidReason["DuplicateTransaction"] = "duplicate_transaction";
    VoidReason["TestSale"] = "test_sale";
    VoidReason["MisfiredPostback"] = "misfired_pixel";
    VoidReason["Other"] = "other";
})(VoidReason || (VoidReason = {}));
export const VoidReasonMap = new Map([
    [VoidReason.None, 'Global.Options.VoidReason.None'],
    [VoidReason.Fraud, 'Global.Options.VoidReason.Fraud'],
    [VoidReason.DuplicateTransaction, 'Global.Options.VoidReason.DuplicateTransaction'],
    [VoidReason.TestSale, 'Global.Options.VoidReason.TestSale'],
    [VoidReason.MisfiredPostback, 'Global.Options.VoidReason.MisfiredPostback'],
    [VoidReason.Other, 'Global.Options.VoidReason.Other'],
]);
let VoidReasonService = class VoidReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = VoidReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
    has(key) {
        return this._data.has(key);
    }
};
VoidReasonService.ctorParameters = () => [
    { type: TranslateService }
];
VoidReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], VoidReasonService);
export { VoidReasonService };
