import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let CountryCapacityRulesApiService = class CountryCapacityRulesApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/feature/country-capacity-rules';
    }
    list(body) {
        return this._httpClient.post(`/capacity/settings`, body, { observe: 'response' });
    }
    create(body) {
        return this._httpClient.post(`/capacity/create`, body);
    }
    getRule(id) {
        return this._httpClient.get(`${this.endpointUrl}/${id}`);
    }
    editRule(id, body) {
        return this._httpClient.put(`/capacity/${id}`, body);
    }
    deleteRule(id) {
        return this._httpClient.delete(`/capacity/${id}`);
    }
    setTop(id, isTop) {
        return this._httpClient.post(`/capacity/${id}/toggle-top`, { is_top: isTop });
    }
    simple(body) {
        return this._httpClient.post('/capacity/simple', body, { observe: 'response' });
    }
};
CountryCapacityRulesApiService.ctorParameters = () => [
    { type: HttpClient }
];
CountryCapacityRulesApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], CountryCapacityRulesApiService);
export { CountryCapacityRulesApiService };
