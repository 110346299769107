import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var TrackingType;
(function (TrackingType) {
    TrackingType["APILds"] = "api_lds";
    TrackingType["TrackingLink"] = "smart_link_tracker";
    TrackingType["AITrackingLink"] = "smart_link_ai_tracker";
    TrackingType["PromoCode"] = "promo_code";
})(TrackingType || (TrackingType = {}));
export const TrackingTypeMap = new Map([
    [TrackingType.APILds, 'Global.Options.TrackingType.APILds'],
    [TrackingType.TrackingLink, 'Global.Options.TrackingType.TrackingLink'],
    [TrackingType.AITrackingLink, 'Global.Options.TrackingType.AITrackingLink'],
    [TrackingType.PromoCode, 'Global.Options.TrackingType.PromoCode'],
]);
let TrackingTypeService = class TrackingTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TrackingTypeMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
TrackingTypeService.ctorParameters = () => [
    { type: TranslateService }
];
TrackingTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TrackingTypeService);
export { TrackingTypeService };
