export var SortDirection;
(function (SortDirection) {
    SortDirection["Top"] = "-";
    SortDirection["Bottom"] = "";
})(SortDirection || (SortDirection = {}));
export var DashboardWidgetView;
(function (DashboardWidgetView) {
    DashboardWidgetView["Table"] = "table";
    DashboardWidgetView["Chart"] = "chart";
    DashboardWidgetView["Map"] = "Map";
})(DashboardWidgetView || (DashboardWidgetView = {}));
