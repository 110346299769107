import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { OfferApiService } from './offer-api.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { CampaignStatusService, DeepLinkService, DeepLinkStatusStringMap, } from '@ho/shared/data-access/dictionary';
import { map, pluck } from 'rxjs/operators';
import { isNil, map as _map } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';
let OfferService = class OfferService {
    constructor(_campaignApiService, _translate, _campaignStatusService, _deepLinkService) {
        this._campaignApiService = _campaignApiService;
        this._translate = _translate;
        this._campaignStatusService = _campaignStatusService;
        this._deepLinkService = _deepLinkService;
        this.completeGeneralStep$ = new Subject();
        this.completeRuleStep$ = new Subject();
        this.completePixelsStep$ = new Subject();
        this.chosenCommissionType$ = new Subject();
        this.chosenRevenueType$ = new Subject();
        this.currentId$ = new BehaviorSubject(null);
        this.currentCampaignId$ = new BehaviorSubject(null);
    }
    list(params = {}) {
        return this._campaignApiService.list(params).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                var _a;
                const deepLinkStatus = DeepLinkStatusStringMap.get(item.allow_deep_link);
                return Object.assign(Object.assign({}, item), { status_label: this._campaignStatusService.get(+item.status_id), revshare_commission_value: isNil(item.revshare_first_commission_percent) &&
                        isNil(item.revshare_recurrent_commission_percent)
                        ? 'Default'
                        : `${item.revshare_first_commission_percent}% / ${item.revshare_recurrent_commission_percent}%`, revshare_tooltip_info: isNil(item.revshare_first_commission_percent) &&
                        isNil(item.revshare_recurrent_commission_percent)
                        ? null
                        : `${item.revshare_first_commission_percent}% for first commission, \n ${item.revshare_recurrent_commission_percent}% for recurrent ones`, deep_link_status: deepLinkStatus ? this._translate.instant(deepLinkStatus) : deepLinkStatus, deep_link_class: this._deepLinkService.setStatusClass(item.allow_deep_link), hosts: (_a = item === null || item === void 0 ? void 0 : item.landing_pages) === null || _a === void 0 ? void 0 : _a.map((landing) => landing.host).join(', '), statusClass: this._campaignStatusService.getStyleClass(+item.status_id) });
            });
            return response;
        }));
    }
    promoRequest(params = {}) {
        return this._campaignApiService.promoRequest(params);
    }
    get(id, options) {
        return this._campaignApiService.get(id, options).pipe(map((response) => {
            const data = response['data'];
            const deepLinkStatus = DeepLinkStatusStringMap.get(data.allow_deep_link);
            response['data'] = Object.assign(Object.assign({}, data), { revshare_commission_value: isNil(data.revshare_first_commission_percent) && isNil(data.revshare_recurrent_commission_percent)
                    ? 'Default'
                    : `${data.revshare_first_commission_percent}% / ${data.revshare_recurrent_commission_percent}%`, revshare_tooltip_info: isNil(data.revshare_first_commission_percent) && isNil(data.revshare_recurrent_commission_percent)
                    ? null
                    : `${data.revshare_first_commission_percent}% for first commission, \n ${data.revshare_recurrent_commission_percent}% for recurrent ones`, duration_tooltip_info: isNil(data.revshare_duration_days)
                    ? null
                    : `You'll be able to receive commissions for recurrent sales for ${data.revshare_duration_days !== -1 ? data.revshare_duration_days + 'days' : 'Lifetime'} after the first sale.`, deep_link_status: deepLinkStatus ? this._translate.instant(deepLinkStatus) : deepLinkStatus });
            return response;
        }), pluck('data'));
    }
    put(id, body, options) {
        return this._campaignApiService.put(id, body, options);
    }
    getIncomingPixels(id, options) {
        return this._campaignApiService.getIncomingPixels(id, options);
    }
    getPromoCodePixels(id, options) {
        return this._campaignApiService.getPromoCodePixels(id, options);
    }
    getMyIncomingPixels(options) {
        return this._campaignApiService.getMyIncomingPixels(options);
    }
    getMyPromoCodePixels(options) {
        return this._campaignApiService.getMyPromoCodePixels(options);
    }
    setStatus(id, status, options) {
        return this._campaignApiService.setStatus(id, status, options);
    }
    init(options) {
        return this._campaignApiService.init(options);
    }
    duplicate(id, body, options) {
        return this._campaignApiService.duplicate(id, body, options);
    }
    offerChangeLog(body, options) {
        return this._campaignApiService.offerChangeLog(body, options);
    }
    offerChangeLogCsv(body, options) {
        return this._campaignApiService.offerChangeLogCsv(body, options);
    }
    imageUpload(id, options) {
        return this._campaignApiService.imageUpload(id, options);
    }
    getBanners(options) {
        return this._campaignApiService.getBanners(options).pipe(map((response) => {
            response['data'] = _map(response['data'], (item) => {
                var _a, _b;
                return Object.assign(Object.assign({}, item), { short_name: ((_a = item.name) === null || _a === void 0 ? void 0 : _a.length) > 30 ? item.name.slice(0, 30) + '...' : item.name, tooltipInfo: ((_b = item.name) === null || _b === void 0 ? void 0 : _b.length) > 30 ? item.name : null });
            });
            return response;
        }), pluck('data'));
    }
    removeBanner(options) {
        return this._campaignApiService.removeBanner(options);
    }
    downloadBanner(url, name) {
        return this._campaignApiService.downloadBanner(url, name);
    }
    downloadBanners(campaignId, name) {
        return this._campaignApiService.downloadBanners(campaignId, name);
    }
};
OfferService.ctorParameters = () => [
    { type: OfferApiService },
    { type: TranslateService },
    { type: CampaignStatusService },
    { type: DeepLinkService }
];
OfferService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [OfferApiService,
        TranslateService,
        CampaignStatusService,
        DeepLinkService])
], OfferService);
export { OfferService };
