import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CommissionThresholdReachedFilter;
(function (CommissionThresholdReachedFilter) {
    CommissionThresholdReachedFilter["NotReached"] = "below";
    CommissionThresholdReachedFilter["Reached"] = "above";
})(CommissionThresholdReachedFilter || (CommissionThresholdReachedFilter = {}));
export const CommissionThresholdReachedFilterMap = new Map([
    [CommissionThresholdReachedFilter.NotReached, 'Global.Options.CommissionThresholdReachedFilter.NotReached'],
    [CommissionThresholdReachedFilter.Reached, 'Global.Options.CommissionThresholdReachedFilter.Reached'],
]);
export var CommissionThresholdReachedType;
(function (CommissionThresholdReachedType) {
    CommissionThresholdReachedType[CommissionThresholdReachedType["NotReached"] = 0] = "NotReached";
    CommissionThresholdReachedType[CommissionThresholdReachedType["Reached"] = 1] = "Reached";
})(CommissionThresholdReachedType || (CommissionThresholdReachedType = {}));
export const CommissionThresholdReachedTypesMap = new Map([
    [CommissionThresholdReachedType.NotReached, 'Global.Options.CommissionThresholdReachedTypes.NotReached'],
    [CommissionThresholdReachedType.Reached, 'Global.Options.CommissionThresholdReachedTypes.Reached'],
]);
let CommissionThresholdReachedTypesService = class CommissionThresholdReachedTypesService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CommissionThresholdReachedTypesMap;
    }
    list() {
        return this._data;
    }
    get(type) {
        const t = this._data.get(type);
        return t ? this._translate.instant(t) : t;
    }
};
CommissionThresholdReachedTypesService.ctorParameters = () => [
    { type: TranslateService }
];
CommissionThresholdReachedTypesService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CommissionThresholdReachedTypesService);
export { CommissionThresholdReachedTypesService };
