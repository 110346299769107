import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var DrillDownReportType;
(function (DrillDownReportType) {
    DrillDownReportType["UniqueClicks"] = "unique clicks";
    DrillDownReportType["Clicks"] = "clicks";
    DrillDownReportType["Leads"] = "leads";
    DrillDownReportType["Sales"] = "sales";
    DrillDownReportType["OptIns"] = "opt-in";
    DrillDownReportType["Impressions"] = "impression_count";
    DrillDownReportType["Visits"] = "visits";
    DrillDownReportType["BadQualityTraffic"] = "bad_quality_traffic";
})(DrillDownReportType || (DrillDownReportType = {}));
export const DrillDownReportTypeMap = new Map([
    [DrillDownReportType.UniqueClicks, 'Global.Options.DrillDownReportType.UniqueClicks'],
    [DrillDownReportType.Clicks, 'Global.Options.DrillDownReportType.Clicks'],
    [DrillDownReportType.Leads, 'Global.Options.DrillDownReportType.Leads'],
    [DrillDownReportType.Sales, 'Global.Options.DrillDownReportType.Sales'],
    [DrillDownReportType.OptIns, 'Global.Options.DrillDownReportType.OptIns'],
    [DrillDownReportType.Impressions, 'Global.Options.DrillDownReportType.Impressions'],
    [DrillDownReportType.BadQualityTraffic, 'Global.Options.DrillDownReportType.BadQualityTraffic'],
]);
let DrillDownReportTypeService = class DrillDownReportTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = DrillDownReportTypeMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
DrillDownReportTypeService.ctorParameters = () => [
    { type: TranslateService }
];
DrillDownReportTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], DrillDownReportTypeService);
export { DrillDownReportTypeService };
