import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ReferralStatus;
(function (ReferralStatus) {
    ReferralStatus[ReferralStatus["Inactive"] = -1] = "Inactive";
    ReferralStatus[ReferralStatus["Active"] = 1] = "Active";
})(ReferralStatus || (ReferralStatus = {}));
export const ReferralStatusMap = new Map([
    [ReferralStatus.Inactive, 'Global.Options.ReferralStatus.Inactive'],
    [ReferralStatus.Active, 'Global.Status.Active'],
]);
let ReferralStatusService = class ReferralStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ReferralStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case ReferralStatus.Active:
                return 'text-success';
            case ReferralStatus.Inactive:
                return 'text-danger';
            default:
                return '';
        }
    }
};
ReferralStatusService.ctorParameters = () => [
    { type: TranslateService }
];
ReferralStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ReferralStatusService);
export { ReferralStatusService };
