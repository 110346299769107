import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaAttributeWidgetDirective } from './directives/qa-attribute-widget.directive';
let QaAttributeWidgetModule = class QaAttributeWidgetModule {
};
QaAttributeWidgetModule = __decorate([
    NgModule({
        imports: [CommonModule],
        declarations: [QaAttributeWidgetDirective],
        exports: [QaAttributeWidgetDirective]
    })
], QaAttributeWidgetModule);
export { QaAttributeWidgetModule };
