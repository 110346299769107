import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let CountersApiService = class CountersApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this._endpointUrl = '/ui';
    }
    counters() {
        return this._httpClient.post(`${this._endpointUrl}/counters`, {});
    }
};
CountersApiService.ctorParameters = () => [
    { type: HttpClient }
];
CountersApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], CountersApiService);
export { CountersApiService };
