import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let AlertsApiService = class AlertsApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/notifications';
    }
    list(body) {
        return this._httpClient.post(`${this.endpointUrl}/list`, body, { observe: 'response' });
    }
    head(params) {
        return this._httpClient.head(`${this.endpointUrl}/list`, Object.assign(Object.assign({}, params), { observe: 'response' }));
    }
    batchMarkAsRead(body) {
        return this._httpClient.post(`${this.endpointUrl}/batch-mark-read`, body);
    }
    batchDelete(body) {
        return this._httpClient.post(`${this.endpointUrl}/batch-delete`, body);
    }
};
AlertsApiService.ctorParameters = () => [
    { type: HttpClient }
];
AlertsApiService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], AlertsApiService);
export { AlertsApiService };
