import { __decorate } from "tslib";
import { TranslateModule } from '@ngx-translate/core';
import { DashboardVisibleConfigModalService } from './services/dashboard-visible-config-modal.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardVisibleConfigModalContainer } from './containers/dashboard-visible-config-modal/dashboard-visible-config-modal.container';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
import { MobileTooltipHandlerModule } from '@ho/shared/ui/directives';
let DashboardVisibleConfigModalModule = class DashboardVisibleConfigModalModule {
};
DashboardVisibleConfigModalModule = __decorate([
    NgModule({
        declarations: [DashboardVisibleConfigModalContainer],
        imports: [
            CommonModule,
            MatDialogModule,
            MatButtonModule,
            MatCheckboxModule,
            ReactiveFormsModule,
            MatListModule,
            MatTooltipModule,
            MobileTooltipHandlerModule,
            FlexLayoutModule,
            QaAttributeWidgetModule,
            TranslateModule,
        ],
        providers: [DashboardVisibleConfigModalService],
        entryComponents: [DashboardVisibleConfigModalContainer],
    })
], DashboardVisibleConfigModalModule);
export { DashboardVisibleConfigModalModule };
