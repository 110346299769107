import { __decorate, __metadata } from "tslib";
import { Query } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { DictionariesStore } from './dictionaries.store';
let DictionariesQuery = class DictionariesQuery extends Query {
    constructor(store) {
        super(store);
        this.store = store;
    }
    get query() {
        return this.getValue().query;
    }
};
DictionariesQuery.ctorParameters = () => [
    { type: DictionariesStore }
];
DictionariesQuery = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [DictionariesStore])
], DictionariesQuery);
export { DictionariesQuery };
