import { __decorate, __metadata } from "tslib";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
let LandingPageApiService = class LandingPageApiService {
    constructor(_http) {
        this._http = _http;
        this.endpointUrl = '/offer-management/landing-pages';
    }
    all(params) {
        return this._http.get(this.endpointUrl, { params, observe: 'response' });
    }
    get(id) {
        return this._http.get(`${this.endpointUrl}/${id}`);
    }
    post(body, options) {
        return this._http.post(this.endpointUrl, body, options);
    }
    save(body, options) {
        return body.id ? this.put(body.id, body, options) : this.post(body, options);
    }
    put(id, body, options) {
        return this._http.put(`${this.endpointUrl}/${id}`, body, options);
    }
    delete(id) {
        return this._http.delete(`${this.endpointUrl}/${id}`);
    }
    deleteImage(id) {
        return this._http.post(`${this.endpointUrl}/${id}/remove-image`, {});
    }
    init(body, options) {
        return this._http.post(`${this.endpointUrl}/init`, body, options);
    }
    batchChangeHost(body, options) {
        return this._http.post(`${this.endpointUrl}/batch-change-host`, body, options);
    }
};
LandingPageApiService.ctorParameters = () => [
    { type: HttpClient }
];
LandingPageApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], LandingPageApiService);
export { LandingPageApiService };
