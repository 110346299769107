import { __decorate } from "tslib";
import { TranslateModule } from '@ngx-translate/core';
import { RequestInterceptorService } from './services/http-interceptor.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SessionDataAccessModule } from '@ho/shared/data-access/session';
import { AuthGuardService } from './guards/auth-guard.service';
import { GuestUserGuard } from '@ho/shared/data-access/core/lib/guards/guest-user.guard';
import { SnackBarModule } from '@ho/shared/ui/snack-bar';
import { AuthDataAccessModule } from '@ho/auth/data-access';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HasUnsavedData } from '@ho/shared/data-access/core/lib/components/has-unsaved-data/has-unsaved-data';
let CoreDataAccessModule = class CoreDataAccessModule {
};
CoreDataAccessModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            SessionDataAccessModule.forRoot(),
            SnackBarModule,
            NgxPermissionsModule.forRoot(),
            AuthDataAccessModule,
            TranslateModule,
        ],
        providers: [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: RequestInterceptorService,
                multi: true,
            },
            AuthGuardService,
            GuestUserGuard,
        ],
        declarations: [HasUnsavedData],
        exports: [HasUnsavedData],
    })
], CoreDataAccessModule);
export { CoreDataAccessModule };
