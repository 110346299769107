import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var TransactionLogActionType;
(function (TransactionLogActionType) {
    TransactionLogActionType["commission_void"] = "commission_void";
    TransactionLogActionType["commission_change"] = "commission_change";
    TransactionLogActionType["commission_unvoid"] = "commission_unvoid";
})(TransactionLogActionType || (TransactionLogActionType = {}));
export const TransactionLogActionTypeMap = new Map([
    [TransactionLogActionType.commission_void, 'Global.Options.TransactionLogActionType.VoidTransaction'],
    [TransactionLogActionType.commission_change, 'Global.Options.TransactionLogActionType.ChangeTransaction'],
    [TransactionLogActionType.commission_unvoid, 'Global.Options.TransactionLogActionType.UnvoidTransaction'],
]);
let LogActionTypeService = class LogActionTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TransactionLogActionTypeMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
LogActionTypeService.ctorParameters = () => [
    { type: TranslateService }
];
LogActionTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], LogActionTypeService);
export { LogActionTypeService };
