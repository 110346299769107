import { __decorate, __metadata } from "tslib";
import { Inject, Injectable } from '@angular/core';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { SessionQuery, SessionUserTypeService } from '@ho/shared/data-access/session';
import * as timeZonesList from '@ho/mocks/time-zones.json';
import { DateTime } from 'luxon';
let TimezonesService = class TimezonesService {
    constructor(environment, _sessionUserTypeService, _sessionQuery) {
        this.environment = environment;
        this._sessionUserTypeService = _sessionUserTypeService;
        this._sessionQuery = _sessionQuery;
        this.timeZonesList = timeZonesList.default;
        this.timeZonesList.map((zone) => {
            //actualize offset
            zone.offset = DateTime.local().setZone(zone.name).toFormat('ZZ');
            zone.label = `${zone.name} (${zone.offset})`;
            return zone;
        });
    }
    getTimeZoneOffset() {
        return this.timeZonesList.find((x) => {
            var _a;
            if (localStorage.getItem('session_timezone')) {
                return x.name === localStorage.getItem('session_timezone');
            }
            else {
                return x.name === (((_a = this._sessionQuery.userInfo()) === null || _a === void 0 ? void 0 : _a.time_zone) || 'UTC');
            }
        }).offset;
    }
    getTimeZoneName() {
        return this.timeZonesList.find((x) => {
            var _a;
            if (localStorage.getItem('session_timezone')) {
                return x.name === localStorage.getItem('session_timezone');
            }
            else {
                return x.name === (((_a = this._sessionQuery.userInfo()) === null || _a === void 0 ? void 0 : _a.time_zone) || 'UTC');
            }
        }).name;
    }
};
TimezonesService.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: SessionUserTypeService },
    { type: SessionQuery }
];
TimezonesService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [Object, SessionUserTypeService,
        SessionQuery])
], TimezonesService);
export { TimezonesService };
