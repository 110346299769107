import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
let SettingsApiService = class SettingsApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/settings/config';
        this._systemEndpointUrl = '/system';
        this._settingsEndpointUrl = '/settings';
    }
    system() {
        let params = new HttpParams({
            fromObject: {
                ws: location.origin,
            },
        });
        return this._httpClient
            .get(this._systemEndpointUrl, { params })
            .pipe(pluck('data'));
    }
    list(params = {}) {
        return this._httpClient.get(`${this.endpointUrl}`, params);
    }
    batchUpdate(section, body) {
        return this._httpClient.put(`${this.endpointUrl}/batch-update/${section}`, body);
    }
    updateRegistrationType(body) {
        return this._httpClient.put(`${this._settingsEndpointUrl}/workspace`, body);
    }
};
SettingsApiService.ctorParameters = () => [
    { type: HttpClient }
];
SettingsApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], SettingsApiService);
export { SettingsApiService };
