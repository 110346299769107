import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UserLeadStatus;
(function (UserLeadStatus) {
    UserLeadStatus["New"] = "new";
    UserLeadStatus["SkypeContact"] = "skype_contact";
    UserLeadStatus["CallAgain"] = "call_again";
    UserLeadStatus["NotInterested"] = "not_interested";
    UserLeadStatus["NoAnswer"] = "no_answer";
    UserLeadStatus["Emailed"] = "emailed";
    UserLeadStatus["Declined"] = "declined";
    UserLeadStatus["Approved"] = "approved";
})(UserLeadStatus || (UserLeadStatus = {}));
export const UserLeadStatusLabels = new Map([
    [UserLeadStatus.New, 'Global.Status.New'],
    [UserLeadStatus.SkypeContact, 'Global.UserLeadStatus.SkypeContact'],
    [UserLeadStatus.CallAgain, 'Global.UserLeadStatus.CallAgain'],
    [UserLeadStatus.NotInterested, 'Global.UserLeadStatus.NotInterested'],
    [UserLeadStatus.NoAnswer, 'Global.UserLeadStatus.NoAnswer'],
    [UserLeadStatus.Emailed, 'Global.UserLeadStatus.Emailed'],
    [UserLeadStatus.Declined, 'Global.UserLeadStatus.Declined'],
    [UserLeadStatus.Approved, 'Global.Status.Approved'],
]);
let UserLeadStatusLabelsService = class UserLeadStatusLabelsService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UserLeadStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case UserLeadStatus.New:
            case UserLeadStatus.Approved:
                return 'text-success';
            case UserLeadStatus.Declined:
            case UserLeadStatus.NotInterested:
                return 'text-danger';
            case UserLeadStatus.CallAgain:
            case UserLeadStatus.Emailed:
            case UserLeadStatus.NoAnswer:
            case UserLeadStatus.SkypeContact:
                return 'text-warning';
        }
    }
};
UserLeadStatusLabelsService.ctorParameters = () => [
    { type: TranslateService }
];
UserLeadStatusLabelsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UserLeadStatusLabelsService);
export { UserLeadStatusLabelsService };
