import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ContactTodayPriority;
(function (ContactTodayPriority) {
    ContactTodayPriority[ContactTodayPriority["Low"] = 1] = "Low";
    ContactTodayPriority[ContactTodayPriority["Medium"] = 2] = "Medium";
    ContactTodayPriority[ContactTodayPriority["High"] = 3] = "High";
})(ContactTodayPriority || (ContactTodayPriority = {}));
export const ContactTodayPriorityLabels = new Map([
    [ContactTodayPriority.Low, 'Global.Options.ContactTodayPriorityLabels.Low'],
    [ContactTodayPriority.Medium, 'Global.Options.ContactTodayPriorityLabels.Medium'],
    [ContactTodayPriority.High, 'Global.Options.ContactTodayPriorityLabels.High'],
]);
let ContactTodayPriorityService = class ContactTodayPriorityService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ContactTodayPriorityLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
ContactTodayPriorityService.ctorParameters = () => [
    { type: TranslateService }
];
ContactTodayPriorityService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ContactTodayPriorityService);
export { ContactTodayPriorityService };
