import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartService } from './services/chart.service';
let ChartDataAccessModule = class ChartDataAccessModule {
};
ChartDataAccessModule = __decorate([
    NgModule({
        imports: [CommonModule],
        providers: [ChartService]
    })
], ChartDataAccessModule);
export { ChartDataAccessModule };
