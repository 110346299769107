import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./country-flag-widget.component.html";
import __NG_CLI_RESOURCE__1 from "./country-flag-widget.component.css";
import { Component, Input } from '@angular/core';
let CountryFlagWidgetComponent = class CountryFlagWidgetComponent {
    constructor() { }
    ngOnInit() { }
};
CountryFlagWidgetComponent.ctorParameters = () => [];
CountryFlagWidgetComponent.propDecorators = {
    countryName: [{ type: Input }]
};
CountryFlagWidgetComponent = __decorate([
    Component({
        selector: 'ho-country-flag-widget',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [])
], CountryFlagWidgetComponent);
export { CountryFlagWidgetComponent };
