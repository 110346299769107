import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { SettingsApiService } from './settings-api.service';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
export var RegistrationType;
(function (RegistrationType) {
    RegistrationType[RegistrationType["Open"] = 1] = "Open";
    RegistrationType[RegistrationType["Moderated"] = 2] = "Moderated";
    RegistrationType[RegistrationType["Closed"] = 3] = "Closed";
})(RegistrationType || (RegistrationType = {}));
export var SystemSettingsConfirm;
(function (SystemSettingsConfirm) {
    SystemSettingsConfirm[SystemSettingsConfirm["Confirm"] = 1] = "Confirm";
})(SystemSettingsConfirm || (SystemSettingsConfirm = {}));
export const RegistrationTypeMap = new Map([
    [RegistrationType.Open, 'Global.Options.RegistrationType.Open'],
    [RegistrationType.Moderated, 'Global.Options.RegistrationType.Moderated'],
    [RegistrationType.Closed, 'Global.Options.RegistrationType.Closed'],
]);
export var RegistrationTypePermissions;
(function (RegistrationTypePermissions) {
    RegistrationTypePermissions["Open"] = "UI_Registration_Type_Open";
    RegistrationTypePermissions["Moderated"] = "UI_Registration_Type_Moderated";
    RegistrationTypePermissions["Closed"] = "UI_Registration_Type_Closed";
})(RegistrationTypePermissions || (RegistrationTypePermissions = {}));
export const RegistrationTypePermissionsMap = new Map([
    [RegistrationType.Open, RegistrationTypePermissions.Open],
    [RegistrationType.Moderated, RegistrationTypePermissions.Moderated],
    [RegistrationType.Closed, RegistrationTypePermissions.Closed],
]);
export const GENERAL_SETTINGS_SECTION = 'general-settings';
export const DEFAULT_REFERRAL_PROGRAM_SECTION = 'default-referral-program';
let SettingsService = class SettingsService {
    constructor(_settingsApiService, _ngxPermissionsService) {
        this._settingsApiService = _settingsApiService;
        this._ngxPermissionsService = _ngxPermissionsService;
        this.system$ = new BehaviorSubject({});
    }
    get settings() {
        return this._system;
    }
    system() {
        return this._settingsApiService.system().pipe(tap((r) => {
            this._system = r;
            this.system$.next(this._system);
            this._ngxPermissionsService.addPermission(RegistrationTypePermissionsMap.get(this._system.registration_type_id), () => true);
        }));
    }
    list(params = {}) {
        return this._settingsApiService.list(params);
    }
    batchUpdate(section, body) {
        return this._settingsApiService.batchUpdate(section, body);
    }
    updateRegistrationType(body) {
        return this._settingsApiService.updateRegistrationType(body).pipe(tap(() => {
            this._system = Object.assign(Object.assign(Object.assign({}, this._system), body), { default_affiliate_manager_id: body.default_affiliate_manager_id || null });
            this.system$.next(this._system);
            RegistrationTypePermissionsMap.forEach((permission) => {
                this._ngxPermissionsService.removePermission(permission);
            });
            this._ngxPermissionsService.addPermission(RegistrationTypePermissionsMap.get(this._system.registration_type_id), () => true);
        }));
    }
};
SettingsService.ctorParameters = () => [
    { type: SettingsApiService },
    { type: NgxPermissionsService }
];
SettingsService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [SettingsApiService,
        NgxPermissionsService])
], SettingsService);
export { SettingsService };
