export function controlsMatchValidator(objectName, subjectName) {
    return (group) => {
        const objControl = group.get(objectName);
        const subjectControl = group.get(subjectName);
        if (objControl.value !== subjectControl.value) {
            subjectControl.setErrors({ notSame: true });
        }
        return null;
    };
}
