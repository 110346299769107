import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PaidForType;
(function (PaidForType) {
    PaidForType["Lead"] = "lead";
    PaidForType["Ftd"] = "ftd";
    PaidForType["ReferralBonus"] = "referral_bonus";
    PaidForType["OptIn"] = "opt-in";
    PaidForType["Revshare"] = "revshare";
    PaidForType["CPA"] = "cpa";
    PaidForType["CPL"] = "cpl";
})(PaidForType || (PaidForType = {}));
export const PaidForTypeLabels = new Map([
    [PaidForType.Lead, 'Global.Options.PaidForTypeLabels.Lead'],
    [PaidForType.Ftd, 'Global.Options.PaidForTypeLabels.Ftd'],
    [PaidForType.ReferralBonus, 'Global.Options.PaidForTypeLabels.ReferralBonus'],
    [PaidForType.OptIn, 'Global.Options.PaidForTypeLabels.OptIn'],
    [PaidForType.Revshare, 'Global.Options.Revshare'],
    [PaidForType.CPA, 'Global.Options.PaidForTypeLabels.CPA'],
    [PaidForType.CPL, 'Global.Options.PaidForTypeLabels.CPL'],
]);
let PaidForTypeService = class PaidForTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PaidForTypeLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        return status
            ? status
                .split(/, ?/)
                .map((key) => {
                const k = this._data.get(key);
                return k ? this._translate.instant(k) : k;
            })
                .filter((item) => item)
                .join(', ')
            : null;
    }
};
PaidForTypeService.ctorParameters = () => [
    { type: TranslateService }
];
PaidForTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PaidForTypeService);
export { PaidForTypeService };
