import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let PermissionSettingsService = class PermissionSettingsService {
    constructor(_http) {
        this._http = _http;
        this._endpointUrl = `/rbac/ui-permission/`;
    }
    allowedUserPermissions(id) {
        return this._http.get(`${this._endpointUrl}allowed/${id}`);
    }
    activeUserPermissions(id) {
        return this._http.get(`${this._endpointUrl}active/${id}`);
    }
    setUserPermissions(body) {
        const model = { user_id: body.user_id, permissions: body.permissions };
        return this._http.post(`${this._endpointUrl}set-state`, model);
    }
};
PermissionSettingsService.ctorParameters = () => [
    { type: HttpClient }
];
PermissionSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], PermissionSettingsService);
export { PermissionSettingsService };
