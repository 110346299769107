import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CampaignUserRuleStatuses;
(function (CampaignUserRuleStatuses) {
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Deleted"] = -1] = "Deleted";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Pending"] = 0] = "Pending";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Rejected"] = 1] = "Rejected";
    CampaignUserRuleStatuses[CampaignUserRuleStatuses["Approved"] = 2] = "Approved";
})(CampaignUserRuleStatuses || (CampaignUserRuleStatuses = {}));
export const CampaignUserRuleStatusLabels = new Map([
    [CampaignUserRuleStatuses.Deleted, 'Global.Status.Deleted'],
    [CampaignUserRuleStatuses.Pending, 'Global.Status.Pending'],
    [CampaignUserRuleStatuses.Rejected, 'Global.Status.Rejected'],
    [CampaignUserRuleStatuses.Approved, 'Global.Status.Approved'],
]);
let CampaignUserRuleStatusService = class CampaignUserRuleStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CampaignUserRuleStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case CampaignUserRuleStatuses.Approved:
                return 'text-success';
            case CampaignUserRuleStatuses.Rejected:
                return 'text-danger';
            case CampaignUserRuleStatuses.Pending:
                return 'text-warning';
        }
    }
};
CampaignUserRuleStatusService.ctorParameters = () => [
    { type: TranslateService }
];
CampaignUserRuleStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CampaignUserRuleStatusService);
export { CampaignUserRuleStatusService };
