import { __decorate, __metadata } from "tslib";
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Inject, Injectable } from '@angular/core';
import { resetStores, Store, StoreConfig } from '@datorama/akita';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as storage from '../storage';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
const jwtHelper = new JwtHelperService();
let SessionStore = class SessionStore extends Store {
    constructor(environment) {
        super({});
        this.environment = environment;
        const token = this._value().token;
        if (token && jwtHelper.isTokenExpired(token)) {
            this.logout();
        }
    }
    login(token) {
        if (token) {
            this.update({ token });
            storage.set(this.environment.jwtTokenName, token);
            localStorage.setItem(this.environment.jwtTokenName, token);
        }
    }
    logout() {
        const exception = ['deployed_apps', 'language', 'show_welcome_page'];
        resetStores({ exclude: [StoreNames.Session] });
        this.reset();
        storage.remove(this.environment.jwtTokenName);
        Object.keys(localStorage).forEach((key) => {
            if (!exception.includes(key)) {
                localStorage.removeItem(key);
            }
        });
    }
};
SessionStore.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] }
];
SessionStore = __decorate([
    Injectable(),
    StoreConfig({ name: StoreNames.Session }),
    __metadata("design:paramtypes", [Object])
], SessionStore);
export { SessionStore };
