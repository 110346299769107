import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SuccessSnackBarComponent } from './components/success-snack-bar/success-snack-bar.component';
import { SnackbarService } from './services/snackbar.service';
import { WarningSnackBarComponent } from './components/warning-snack-bar/warning-snack-bar.component';
import { InfoSnackBarComponent } from './components/info-snack-bar/info-snack-bar.component';
import { AccentSnackBarComponent } from './components/accent-snack-bar/accent-snack-bar.component';
import { RemoveSnackBarComponent } from './components/remove-snack-bar/remove-snack-bar.component';
import { ErrorSnackBarComponent } from './components/error-snack-bar/error-snack-bar.component';
let SnackBarModule = class SnackBarModule {
};
SnackBarModule = __decorate([
    NgModule({
        imports: [CommonModule, MatSnackBarModule, FlexLayoutModule],
        declarations: [
            SuccessSnackBarComponent,
            ErrorSnackBarComponent,
            WarningSnackBarComponent,
            InfoSnackBarComponent,
            AccentSnackBarComponent,
            RemoveSnackBarComponent
        ],
        providers: [SnackbarService],
        entryComponents: [
            SuccessSnackBarComponent,
            ErrorSnackBarComponent,
            WarningSnackBarComponent,
            InfoSnackBarComponent,
            AccentSnackBarComponent,
            RemoveSnackBarComponent
        ]
    })
], SnackBarModule);
export { SnackBarModule };
