import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UiSettingsApiService } from '../services/ui-settings-api.service';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { DEVICE, TypeDevice } from '@ho/shared/constants/device';
import { UiSettingsQuery } from '../+state/ui-settings.query';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { UiSettingsQueueService } from './ui-settings-queue.service';
import { cloneDeep, uniq } from 'lodash-es';
let GridSettingsService = class GridSettingsService {
    constructor(_uiSettingsApiService, _uiSettingsQuery, _uiSettingsStore, _uiSettingsQueueService) {
        this._uiSettingsApiService = _uiSettingsApiService;
        this._uiSettingsQuery = _uiSettingsQuery;
        this._uiSettingsStore = _uiSettingsStore;
        this._uiSettingsQueueService = _uiSettingsQueueService;
        this._userViewType = localStorage.getItem('user_view_type');
    }
    getGridSettings() {
        return this._userViewType
            ? of({})
            : this._uiSettingsApiService.getUiSettings().pipe(tap((res) => this._uiSettingsStore.updateGridSettings(res.grid_settings)), map((r) => r.grid_settings || {}));
    }
    getSettings(params, defaultGridSettings) {
        return new Observable((observer) => {
            const settings = this._uiSettingsQuery.queryGridSettings;
            const gridId = params.grid_id;
            if (Object.keys(settings).length || this._userViewType) {
                observer.next(this._getPlatformSettings(gridId, settings, defaultGridSettings));
                observer.complete();
            }
            else {
                this.getGridSettings().subscribe((res) => {
                    observer.next(this._getPlatformSettings(gridId, res, defaultGridSettings));
                    observer.complete();
                }, (err) => observer.error(err));
            }
        });
    }
    saveSettings(body, defaultGridSettings) {
        delete body.grid_settings.isMobileResizable;
        return new Observable((observer) => {
            const gridSettings = this._uiSettingsQuery.queryGridSettings;
            const gridId = body.grid_id;
            const settingsForMobile = cloneDeep(Object.assign({}, body));
            const settingsForDesktop = cloneDeep(Object.assign({}, body));
            const gridIdMobile = gridId + '-mob';
            const gridReversePlatformId = this._isMobile ? gridId : gridIdMobile;
            const gridSettingsExist = !!(gridSettings &&
                gridSettings[gridReversePlatformId] &&
                Object.keys(gridSettings[gridReversePlatformId]).length);
            settingsForMobile.grid_id = gridIdMobile;
            delete settingsForMobile.grid_settings.columns["show_details" /* ShowDetails */];
            const showDetailsIndex = settingsForMobile.grid_settings.columnsList.findIndex((item) => item === "show_details" /* ShowDetails */);
            if (showDetailsIndex !== -1) {
                const showDetailsIndexInVisibleColumns = settingsForMobile.grid_settings.visibleColumns.findIndex((item) => item === "show_details" /* ShowDetails */);
                if (showDetailsIndexInVisibleColumns !== -1) {
                    settingsForMobile.grid_settings.visibleColumns.splice(showDetailsIndexInVisibleColumns, 1);
                }
                settingsForMobile.grid_settings.columnsList.splice(showDetailsIndex, 1);
            }
            if (this._isMobile) {
                settingsForMobile.grid_settings.visibleFilters = gridSettingsExist
                    ? uniq(settingsForMobile.grid_settings.visibleFilters)
                    : undefined;
                settingsForDesktop.grid_settings = {};
            }
            else {
                settingsForMobile.grid_settings = gridSettingsExist
                    ? gridSettings[gridIdMobile]
                    : defaultGridSettings;
                settingsForMobile.grid_settings.visibleFilters = uniq(body.grid_settings.visibleFilters);
            }
            if (!this._userViewType) {
                if (Object.keys((settingsForMobile === null || settingsForMobile === void 0 ? void 0 : settingsForMobile.grid_settings) || {}).length) {
                    this._uiSettingsQueueService.invoke(settingsForMobile);
                }
                if (Object.keys((settingsForDesktop === null || settingsForDesktop === void 0 ? void 0 : settingsForDesktop.grid_settings) || {}).length) {
                    this._uiSettingsQueueService.invoke(settingsForDesktop);
                }
            }
            if (Object.keys(gridSettings).length || this._userViewType) {
                gridSettings[gridIdMobile] = settingsForMobile.grid_settings;
                if (!this._isMobile) {
                    gridSettings[gridId] = settingsForDesktop.grid_settings;
                }
            }
            this._uiSettingsStore.updateGridSettings(gridSettings);
            observer.next({ [gridId]: body.grid_settings });
            observer.complete();
        });
    }
    get _isMobile() {
        return DEVICE.type === TypeDevice.Mobile;
    }
    _getPlatformSettings(gridId, settings, defaultGridSettings) {
        return {
            [gridId]: settings[gridId + (this._isMobile ? '-mob' : '')] || defaultGridSettings,
        };
    }
};
GridSettingsService.ctorParameters = () => [
    { type: UiSettingsApiService },
    { type: UiSettingsQuery },
    { type: UiSettingsStore },
    { type: UiSettingsQueueService }
];
GridSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService,
        UiSettingsQuery,
        UiSettingsStore,
        UiSettingsQueueService])
], GridSettingsService);
export { GridSettingsService };
