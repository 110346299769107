import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var IntegrationStatus;
(function (IntegrationStatus) {
    IntegrationStatus[IntegrationStatus["NoPostback"] = 0] = "NoPostback";
    IntegrationStatus[IntegrationStatus["ActivePostback"] = 1] = "ActivePostback";
})(IntegrationStatus || (IntegrationStatus = {}));
export const IntegrationStatusMap = new Map([
    [IntegrationStatus.NoPostback, 'RegistrationsStats.IntegrationStatus.NoPostback'],
    [IntegrationStatus.ActivePostback, 'RegistrationsStats.IntegrationStatus.ActivePostback'],
]);
let IntegrationStatusService = class IntegrationStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = IntegrationStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case IntegrationStatus.ActivePostback:
                return 'text-success';
            case IntegrationStatus.NoPostback:
                return 'text-danger';
            default:
                return 'text-warning';
        }
    }
};
IntegrationStatusService.ctorParameters = () => [
    { type: TranslateService }
];
IntegrationStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], IntegrationStatusService);
export { IntegrationStatusService };
