import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PixelProcessingLogStatus;
(function (PixelProcessingLogStatus) {
    PixelProcessingLogStatus[PixelProcessingLogStatus["Processed"] = 1] = "Processed";
    PixelProcessingLogStatus[PixelProcessingLogStatus["Failed"] = 2] = "Failed";
})(PixelProcessingLogStatus || (PixelProcessingLogStatus = {}));
export const PixelProcessingLogStatusMap = new Map([
    [PixelProcessingLogStatus.Processed, 'Global.Options.PixelProcessingLogStatus.Processed'],
    [PixelProcessingLogStatus.Failed, 'Global.Options.PixelProcessingLogStatus.Failed'],
]);
let PixelProcessingLogStatusService = class PixelProcessingLogStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PixelProcessingLogStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
PixelProcessingLogStatusService.ctorParameters = () => [
    { type: TranslateService }
];
PixelProcessingLogStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PixelProcessingLogStatusService);
export { PixelProcessingLogStatusService };
