export var AlertPriority;
(function (AlertPriority) {
    AlertPriority[AlertPriority["Low"] = 0] = "Low";
    AlertPriority[AlertPriority["Medium"] = 1] = "Medium";
    AlertPriority[AlertPriority["High"] = 2] = "High";
})(AlertPriority || (AlertPriority = {}));
export var AlertType;
(function (AlertType) {
    AlertType[AlertType["Notification"] = 1] = "Notification";
})(AlertType || (AlertType = {}));
export var AlertStatus;
(function (AlertStatus) {
    AlertStatus[AlertStatus["New"] = 0] = "New";
    AlertStatus[AlertStatus["Read"] = 1] = "Read";
})(AlertStatus || (AlertStatus = {}));
