import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mapTo } from 'rxjs/operators';
import { FileHelper } from '@ho/shared/helpers/file.helper';
let UserManagementApiService = class UserManagementApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = `/user-management`;
    }
    affiliates(body) {
        return this._httpClient.post(`${this.endpointUrl}/affiliates/list`, body, {
            observe: 'response',
        });
    }
    managers(body) {
        return this._httpClient.post(`${this.endpointUrl}/managers/list`, body, {
            observe: 'response',
        });
    }
    advertisers(body) {
        return this._httpClient.post(`${this.endpointUrl}/customers/list`, body, {
            observe: 'response',
        });
    }
    userAccounts(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/list`, body, {
            observe: 'response',
        });
    }
    affiliateRequests(body) {
        return this._httpClient.post(`${this.endpointUrl}/affiliate-requests/list`, body, {
            observe: 'response',
        });
    }
    affiliateRegistrationStats(body) {
        return this._httpClient.post(`${this.endpointUrl}/registration-stats/list`, body, {
            observe: 'response',
        });
    }
    exportRegistrationStatsCsv(params) {
        return this._httpClient
            .post(`/user-management/registration-stats/export-csv`, Object.assign({}, params), { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map((r) => FileHelper.save(r, 'registration-stats.csv')), mapTo(true));
    }
    getAffiliateRequest(id) {
        return this._httpClient.get(`${this.endpointUrl}/affiliate-requests/${id}`);
    }
    batchApproveRequests(body) {
        return this._httpClient.post(`${this.endpointUrl}/affiliate-requests/batch-approve`, body);
    }
    batchDeclineRequests(body) {
        return this._httpClient.post(`${this.endpointUrl}/affiliate-requests/batch-decline`, body);
    }
    batchSetStatus(body, type) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/batch-set-status/${type}`, body);
    }
    batchSetCommissionType(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/batch-set-commission-type`, body);
    }
    commentRequest(body, id) {
        return this._httpClient.put(`${this.endpointUrl}/affiliate-requests/${id}/comment`, body);
    }
    commentAccount(body, id) {
        return this._httpClient.put(`${this.endpointUrl}/user-accounts/${id}/comment`, body);
    }
    getAccount(id) {
        return this._httpClient.get(`${this.endpointUrl}/user-accounts/${id}`);
    }
    getAccountRole(id) {
        return this._httpClient.get(`${this.endpointUrl}/user-accounts/${id}/role`);
    }
    banAccount(id, body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/${id}/ban`, body);
    }
    unbanAccount(id, body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/${id}/unban`, body);
    }
    getProfile(id) {
        return this._httpClient.get(`${this.endpointUrl}/user-profiles/${id}`);
    }
    patchAffiliateProfile(id, section, params) {
        return this._httpClient.patch(`${this.endpointUrl}/user-profiles/${id}/${section}`, params);
    }
    updateAccount(id, body) {
        return this._httpClient.put(`${this.endpointUrl}/user-accounts/${id}`, body);
    }
    changeAccountPassword(id, body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/${id}/change-password`, body);
    }
    userChangeLog(body) {
        return this._httpClient.post(`/user-management/user-change-log/list`, body, {
            observe: 'response',
        });
    }
    emailVerification(body) {
        return this._httpClient.post(`/user-management/email-verification/send`, body, {
            observe: 'response',
        });
    }
    exportCsv(params) {
        return this._httpClient
            .post(`/user-management/user-change-log/export-csv`, Object.assign({}, params), { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map((r) => FileHelper.save(r, 'user-changes-log.csv')), mapTo(true));
    }
    registrationLogExportPdf(params) {
        return this._httpClient
            .post(`/user-management/user-change-log/registration-log-export-pdf`, Object.assign({}, params), { observe: 'response', responseType: 'arraybuffer' })
            .pipe(map((r) => FileHelper.save(r, 'registration-log.pdf')), mapTo(true));
    }
    setTrafficChannel(id, body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/${id}/set-traffic-channel`, body);
    }
    createManager(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/create/manager`, body);
    }
    createAD(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/create/advertiser`, body);
    }
    createAffiliate(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/create/affiliate`, body);
    }
    createAdmin(body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/create/admin`, body);
    }
    batchChangeManager(body) {
        return this._httpClient.post(`${this.endpointUrl}/affiliates/batch-change-manager`, body);
    }
    changeSecretKey(id, body) {
        return this._httpClient.post(`${this.endpointUrl}/user-accounts/${id}/change-callback-secret-key`, body);
    }
};
UserManagementApiService.ctorParameters = () => [
    { type: HttpClient }
];
UserManagementApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], UserManagementApiService);
export { UserManagementApiService };
