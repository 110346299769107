import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { LanguageStatusService } from './language-status.service';
import { map as _map } from 'lodash-es';
let LanguageService = class LanguageService {
    constructor(_httpClient, _languageStatusService) {
        this._httpClient = _httpClient;
        this._languageStatusService = _languageStatusService;
        this.endpointUrl = `/dictionary/languages`;
    }
    list(params) {
        return this._httpClient
            .post(`${this.endpointUrl}/list`, params, { observe: 'response' })
            .pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                return Object.assign(Object.assign({}, item), { system_name: item.system_name.toUpperCase(), status_label: this._languageStatusService.get(item.status), statusClass: this._languageStatusService.getStyleClass(item.status) });
            });
            return response;
        }));
    }
    post(body, options) {
        return this._httpClient.post(this.endpointUrl, body, options).pipe(pluck('data'));
    }
    put(id, body, options) {
        return this._httpClient.put(`${this.endpointUrl}/${id}`, body, options).pipe(pluck('data'));
    }
    save(body, options) {
        return body.id ? this.put(body.id, body, options) : this.post(body, options);
    }
    get(id, options) {
        return this._httpClient.get(`${this.endpointUrl}/${id}`, options).pipe(pluck('data'));
    }
    delete(id, options) {
        return this._httpClient
            .delete(`${this.endpointUrl}/${id}`, options)
            .pipe(map((r) => r.success === true));
    }
};
LanguageService.ctorParameters = () => [
    { type: HttpClient },
    { type: LanguageStatusService }
];
LanguageService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, LanguageStatusService])
], LanguageService);
export { LanguageService };
