import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ChangeAmountReason;
(function (ChangeAmountReason) {
    ChangeAmountReason["Fraud"] = "fraud";
    ChangeAmountReason["DuplicateTransaction"] = "duplicate_transaction";
    ChangeAmountReason["TestSale"] = "test_sale";
    ChangeAmountReason["MisfiredPostback"] = "misfired_pixel";
    ChangeAmountReason["Other"] = "other";
})(ChangeAmountReason || (ChangeAmountReason = {}));
export const ChangeAmountReasonMap = new Map([
    [ChangeAmountReason.Fraud, 'Global.Options.ChangeAmountReason.Fraud'],
    [ChangeAmountReason.DuplicateTransaction, 'Global.Options.ChangeAmountReason.DuplicateTransaction'],
    [ChangeAmountReason.TestSale, 'Global.Options.ChangeAmountReason.TestSale'],
    [ChangeAmountReason.MisfiredPostback, 'Global.Options.ChangeAmountReason.MisfiredPostback'],
    [ChangeAmountReason.Other, 'Global.Options.ChangeAmountReason.Other'],
]);
let ChangeAmountReasonService = class ChangeAmountReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ChangeAmountReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
ChangeAmountReasonService.ctorParameters = () => [
    { type: TranslateService }
];
ChangeAmountReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ChangeAmountReasonService);
export { ChangeAmountReasonService };
