import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PaymentMethod;
(function (PaymentMethod) {
    // PayPal = 1,
    PaymentMethod[PaymentMethod["BankWire"] = 1] = "BankWire";
    // Other = 3
})(PaymentMethod || (PaymentMethod = {}));
export const PaymentMethodMap = new Map([
    [PaymentMethod.BankWire, 'Global.Options.PaymentMethod.BankWire'],
]);
let PaymentMethodService = class PaymentMethodService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PaymentMethodMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
PaymentMethodService.ctorParameters = () => [
    { type: TranslateService }
];
PaymentMethodService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PaymentMethodService);
export { PaymentMethodService };
