export const contactTodayReasonColor = {
    no_lead_24h: 'red',
    no_ftd_24h: 'red',
    ctl_decrease_24h: 'red',
    cr_decrease_24h: 'red',
    epc_decrease_24h: 'red',
    no_traffic_24h: 'red',
    lds_negative_status_increase_24h: 'red',
    active_affiliate_decrease: 'red',
    unresolved_critical_alerts: 'red',
    ctl_increase_24h: 'green',
    cr_increase_24h: 'green',
    epc_increase_24h: 'green',
    active_affiliate_increase: 'green',
    new_geo_started_click: 'orange',
    new_geo_started_lead: 'orange',
    traffic_on_weekend: 'orange',
    new_affiliate_increase: 'orange',
    expired_payment_date: 'orange',
    first_campaign_started: 'orange',
    weekly_ftd_compare: 'orange',
    weekly_epc_compare: 'orange',
    monthly_ftd_compare: 'orange',
    monthly_epc_compare: 'orange'
};
export const countryCodeList = [
    {
        name: 'Afghanistan',
        alpha_code: 'AF',
        country_code: 4
    },
    {
        name: 'Åland Islands',
        alpha_code: 'AX',
        country_code: 248
    },
    {
        name: 'Albania',
        alpha_code: 'AL',
        country_code: 8
    },
    {
        name: 'Algeria',
        alpha_code: 'DZ',
        country_code: 12
    },
    {
        name: 'American Samoa',
        alpha_code: 'AS',
        country_code: 16
    },
    {
        name: 'Andorra',
        alpha_code: 'AD',
        country_code: 20
    },
    {
        name: 'Angola',
        alpha_code: 'AO',
        country_code: 24
    },
    {
        name: 'Anguilla',
        alpha_code: 'AI',
        country_code: 660
    },
    {
        name: 'Antarctica',
        alpha_code: 'AQ',
        country_code: 10
    },
    {
        name: 'Antigua and Barbuda',
        alpha_code: 'AG',
        country_code: 28
    },
    {
        name: 'Argentina',
        alpha_code: 'AR',
        country_code: 32
    },
    {
        name: 'Armenia',
        alpha_code: 'AM',
        country_code: 51
    },
    {
        name: 'Aruba',
        alpha_code: 'AW',
        country_code: 533
    },
    {
        name: 'Australia',
        alpha_code: 'AU',
        country_code: 36
    },
    {
        name: 'Austria',
        alpha_code: 'AT',
        country_code: 40
    },
    {
        name: 'Azerbaijan',
        alpha_code: 'AZ',
        country_code: 31
    },
    {
        name: 'Bahamas',
        alpha_code: 'BS',
        country_code: 44
    },
    {
        name: 'Bahrain',
        alpha_code: 'BH',
        country_code: 48
    },
    {
        name: 'Bangladesh',
        alpha_code: 'BD',
        country_code: 50
    },
    {
        name: 'Barbados',
        alpha_code: 'BB',
        country_code: 52
    },
    {
        name: 'Belarus',
        alpha_code: 'BY',
        country_code: 112
    },
    {
        name: 'Belgium',
        alpha_code: 'BE',
        country_code: 56
    },
    {
        name: 'Belize',
        alpha_code: 'BZ',
        country_code: 84
    },
    {
        name: 'Benin',
        alpha_code: 'BJ',
        country_code: 204
    },
    {
        name: 'Bermuda',
        alpha_code: 'BM',
        country_code: 60
    },
    {
        name: 'Bhutan',
        alpha_code: 'BT',
        country_code: 64
    },
    {
        name: 'Bolivia (Plurinational State of)',
        alpha_code: 'BO',
        country_code: 68
    },
    {
        name: 'Bonaire, Sint Eustatius and Saba',
        alpha_code: 'BQ',
        country_code: 535
    },
    {
        name: 'Bosnia and Herzegovina',
        alpha_code: 'BA',
        country_code: 70
    },
    {
        name: 'Botswana',
        alpha_code: 'BW',
        country_code: 72
    },
    {
        name: 'Bouvet Island',
        alpha_code: 'BV',
        country_code: 74
    },
    {
        name: 'Brazil',
        alpha_code: 'BR',
        country_code: 76
    },
    {
        name: 'British Indian Ocean Territory',
        alpha_code: 'IO',
        country_code: 86
    },
    {
        name: 'Brunei Darussalam',
        alpha_code: 'BN',
        country_code: 96
    },
    {
        name: 'Bulgaria',
        alpha_code: 'BG',
        country_code: 100
    },
    {
        name: 'Burkina Faso',
        alpha_code: 'BF',
        country_code: 854
    },
    {
        name: 'Burundi',
        alpha_code: 'BI',
        country_code: 108
    },
    {
        name: 'Cabo Verde',
        alpha_code: 'CV',
        country_code: 132
    },
    {
        name: 'Cambodia',
        alpha_code: 'KH',
        country_code: 116
    },
    {
        name: 'Cameroon',
        alpha_code: 'CM',
        country_code: 120
    },
    {
        name: 'Canada',
        alpha_code: 'CA',
        country_code: 124
    },
    {
        name: 'Cayman Islands',
        alpha_code: 'KY',
        country_code: 136
    },
    {
        name: 'Central African Republic',
        alpha_code: 'CF',
        country_code: 140
    },
    {
        name: 'Chad',
        alpha_code: 'TD',
        country_code: 148
    },
    {
        name: 'Chile',
        alpha_code: 'CL',
        country_code: 152
    },
    {
        name: 'China',
        alpha_code: 'CN',
        country_code: 156
    },
    {
        name: 'Christmas Island',
        alpha_code: 'CX',
        country_code: 162
    },
    {
        name: 'Cocos (Keeling) Islands',
        alpha_code: 'CC',
        country_code: 166
    },
    {
        name: 'Colombia',
        alpha_code: 'CO',
        country_code: 170
    },
    {
        name: 'Comoros',
        alpha_code: 'KM',
        country_code: 174
    },
    {
        name: 'Congo',
        alpha_code: 'CG',
        country_code: 178
    },
    {
        name: 'Congo, Democratic Republic of the',
        alpha_code: 'CD',
        country_code: 180
    },
    {
        name: 'Cook Islands',
        alpha_code: 'CK',
        country_code: 184
    },
    {
        name: 'Costa Rica',
        alpha_code: 'CR',
        country_code: 188
    },
    {
        name: "Côte d'Ivoire",
        alpha_code: 'CI',
        country_code: 384
    },
    {
        name: 'Croatia',
        alpha_code: 'HR',
        country_code: 191
    },
    {
        name: 'Cuba',
        alpha_code: 'CU',
        country_code: 192
    },
    {
        name: 'Curaçao',
        alpha_code: 'CW',
        country_code: 531
    },
    {
        name: 'Cyprus',
        alpha_code: 'CY',
        country_code: 196
    },
    {
        name: 'Czechia',
        alpha_code: 'CZ',
        country_code: 203
    },
    {
        name: 'Denmark',
        alpha_code: 'DK',
        country_code: 208
    },
    {
        name: 'Djibouti',
        alpha_code: 'DJ',
        country_code: 262
    },
    {
        name: 'Dominica',
        alpha_code: 'DM',
        country_code: 212
    },
    {
        name: 'Dominican Republic',
        alpha_code: 'DO',
        country_code: 214
    },
    {
        name: 'Ecuador',
        alpha_code: 'EC',
        country_code: 218
    },
    {
        name: 'Egypt',
        alpha_code: 'EG',
        country_code: 818
    },
    {
        name: 'El Salvador',
        alpha_code: 'SV',
        country_code: 222
    },
    {
        name: 'Equatorial Guinea',
        alpha_code: 'GQ',
        country_code: 226
    },
    {
        name: 'Eritrea',
        alpha_code: 'ER',
        country_code: 232
    },
    {
        name: 'Estonia',
        alpha_code: 'EE',
        country_code: 233
    },
    {
        name: 'Eswatini',
        alpha_code: 'SZ',
        country_code: 748
    },
    {
        name: 'Ethiopia',
        alpha_code: 'ET',
        country_code: 231
    },
    {
        name: 'Falkland Islands (Malvinas)',
        alpha_code: 'FK',
        country_code: 238
    },
    {
        name: 'Faroe Islands',
        alpha_code: 'FO',
        country_code: 234
    },
    {
        name: 'Fiji',
        alpha_code: 'FJ',
        country_code: 242
    },
    {
        name: 'Finland',
        alpha_code: 'FI',
        country_code: 246
    },
    {
        name: 'France',
        alpha_code: 'FR',
        country_code: 250
    },
    {
        name: 'French Guiana',
        alpha_code: 'GF',
        country_code: 254
    },
    {
        name: 'French Polynesia',
        alpha_code: 'PF',
        country_code: 258
    },
    {
        name: 'French Southern Territories',
        alpha_code: 'TF',
        country_code: 260
    },
    {
        name: 'Gabon',
        alpha_code: 'GA',
        country_code: 266
    },
    {
        name: 'Gambia',
        alpha_code: 'GM',
        country_code: 270
    },
    {
        name: 'Georgia',
        alpha_code: 'GE',
        country_code: 268
    },
    {
        name: 'Germany',
        alpha_code: 'DE',
        country_code: 276
    },
    {
        name: 'Ghana',
        alpha_code: 'GH',
        country_code: 288
    },
    {
        name: 'Gibraltar',
        alpha_code: 'GI',
        country_code: 292
    },
    {
        name: 'Greece',
        alpha_code: 'GR',
        country_code: 300
    },
    {
        name: 'Greenland',
        alpha_code: 'GL',
        country_code: 304
    },
    {
        name: 'Grenada',
        alpha_code: 'GD',
        country_code: 308
    },
    {
        name: 'Guadeloupe',
        alpha_code: 'GP',
        country_code: 312
    },
    {
        name: 'Guam',
        alpha_code: 'GU',
        country_code: 316
    },
    {
        name: 'Guatemala',
        alpha_code: 'GT',
        country_code: 320
    },
    {
        name: 'Guernsey',
        alpha_code: 'GG',
        country_code: 831
    },
    {
        name: 'Guinea',
        alpha_code: 'GN',
        country_code: 324
    },
    {
        name: 'Guinea-Bissau',
        alpha_code: 'GW',
        country_code: 624
    },
    {
        name: 'Guyana',
        alpha_code: 'GY',
        country_code: 328
    },
    {
        name: 'Haiti',
        alpha_code: 'HT',
        country_code: 332
    },
    {
        name: 'Heard Island and McDonald Islands',
        alpha_code: 'HM',
        country_code: 334
    },
    {
        name: 'Holy See',
        alpha_code: 'VA',
        country_code: 336
    },
    {
        name: 'Honduras',
        alpha_code: 'HN',
        country_code: 340
    },
    {
        name: 'Hong Kong',
        alpha_code: 'HK',
        country_code: 344
    },
    {
        name: 'Hungary',
        alpha_code: 'HU',
        country_code: 348
    },
    {
        name: 'Iceland',
        alpha_code: 'IS',
        country_code: 352
    },
    {
        name: 'India',
        alpha_code: 'IN',
        country_code: 356
    },
    {
        name: 'Indonesia',
        alpha_code: 'ID',
        country_code: 360
    },
    {
        name: 'Iran (Islamic Republic of)',
        alpha_code: 'IR',
        country_code: 364
    },
    {
        name: 'Iraq',
        alpha_code: 'IQ',
        country_code: 368
    },
    {
        name: 'Ireland',
        alpha_code: 'IE',
        country_code: 372
    },
    {
        name: 'Isle of Man',
        alpha_code: 'IM',
        country_code: 833
    },
    {
        name: 'Israel',
        alpha_code: 'IL',
        country_code: 376
    },
    {
        name: 'Italy',
        alpha_code: 'IT',
        country_code: 380
    },
    {
        name: 'Jamaica',
        alpha_code: 'JM',
        country_code: 388
    },
    {
        name: 'Japan',
        alpha_code: 'JP',
        country_code: 392
    },
    {
        name: 'Jersey',
        alpha_code: 'JE',
        country_code: 832
    },
    {
        name: 'Jordan',
        alpha_code: 'JO',
        country_code: 400
    },
    {
        name: 'Kazakhstan',
        alpha_code: 'KZ',
        country_code: 398
    },
    {
        name: 'Kenya',
        alpha_code: 'KE',
        country_code: 404
    },
    {
        name: 'Kiribati',
        alpha_code: 'KI',
        country_code: 296
    },
    {
        name: "Korea (Democratic People's Republic of)",
        alpha_code: 'KP',
        country_code: 408
    },
    {
        name: 'Korea, Republic of',
        alpha_code: 'KR',
        country_code: 410
    },
    {
        name: 'Kuwait',
        alpha_code: 'KW',
        country_code: 414
    },
    {
        name: 'Kyrgyzstan',
        alpha_code: 'KG',
        country_code: 417
    },
    {
        name: "Lao People's Democratic Republic",
        alpha_code: 'LA',
        country_code: 418
    },
    {
        name: 'Latvia',
        alpha_code: 'LV',
        country_code: 428
    },
    {
        name: 'Lebanon',
        alpha_code: 'LB',
        country_code: 422
    },
    {
        name: 'Lesotho',
        alpha_code: 'LS',
        country_code: 426
    },
    {
        name: 'Liberia',
        alpha_code: 'LR',
        country_code: 430
    },
    {
        name: 'Libya',
        alpha_code: 'LY',
        country_code: 434
    },
    {
        name: 'Liechtenstein',
        alpha_code: 'LI',
        country_code: 438
    },
    {
        name: 'Lithuania',
        alpha_code: 'LT',
        country_code: 440
    },
    {
        name: 'Luxembourg',
        alpha_code: 'LU',
        country_code: 442
    },
    {
        name: 'Macao',
        alpha_code: 'MO',
        country_code: 446
    },
    {
        name: 'Madagascar',
        alpha_code: 'MG',
        country_code: 450
    },
    {
        name: 'Malawi',
        alpha_code: 'MW',
        country_code: 454
    },
    {
        name: 'Malaysia',
        alpha_code: 'MY',
        country_code: 458
    },
    {
        name: 'Maldives',
        alpha_code: 'MV',
        country_code: 462
    },
    {
        name: 'Mali',
        alpha_code: 'ML',
        country_code: 466
    },
    {
        name: 'Malta',
        alpha_code: 'MT',
        country_code: 470
    },
    {
        name: 'Marshall Islands',
        alpha_code: 'MH',
        country_code: 584
    },
    {
        name: 'Martinique',
        alpha_code: 'MQ',
        country_code: 474
    },
    {
        name: 'Mauritania',
        alpha_code: 'MR',
        country_code: 478
    },
    {
        name: 'Mauritius',
        alpha_code: 'MU',
        country_code: 480
    },
    {
        name: 'Mayotte',
        alpha_code: 'YT',
        country_code: 175
    },
    {
        name: 'Mexico',
        alpha_code: 'MX',
        country_code: 484
    },
    {
        name: 'Micronesia (Federated States of)',
        alpha_code: 'FM',
        country_code: 583
    },
    {
        name: 'Moldova, Republic of',
        alpha_code: 'MD',
        country_code: 498
    },
    {
        name: 'Monaco',
        alpha_code: 'MC',
        country_code: 492
    },
    {
        name: 'Mongolia',
        alpha_code: 'MN',
        country_code: 496
    },
    {
        name: 'Montenegro',
        alpha_code: 'ME',
        country_code: 499
    },
    {
        name: 'Montserrat',
        alpha_code: 'MS',
        country_code: 500
    },
    {
        name: 'Morocco',
        alpha_code: 'MA',
        country_code: 504
    },
    {
        name: 'Mozambique',
        alpha_code: 'MZ',
        country_code: 508
    },
    {
        name: 'Myanmar',
        alpha_code: 'MM',
        country_code: 104
    },
    {
        name: 'Namibia',
        alpha_code: 'NA',
        country_code: 516
    },
    {
        name: 'Nauru',
        alpha_code: 'NR',
        country_code: 520
    },
    {
        name: 'Nepal',
        alpha_code: 'NP',
        country_code: 524
    },
    {
        name: 'Netherlands',
        alpha_code: 'NL',
        country_code: 528
    },
    {
        name: 'New Caledonia',
        alpha_code: 'NC',
        country_code: 540
    },
    {
        name: 'New Zealand',
        alpha_code: 'NZ',
        country_code: 554
    },
    {
        name: 'Nicaragua',
        alpha_code: 'NI',
        country_code: 558
    },
    {
        name: 'Niger',
        alpha_code: 'NE',
        country_code: 562
    },
    {
        name: 'Nigeria',
        alpha_code: 'NG',
        country_code: 566
    },
    {
        name: 'Niue',
        alpha_code: 'NU',
        country_code: 570
    },
    {
        name: 'Norfolk Island',
        alpha_code: 'NF',
        country_code: 574
    },
    {
        name: 'North Macedonia',
        alpha_code: 'MK',
        country_code: 807
    },
    {
        name: 'Northern Mariana Islands',
        alpha_code: 'MP',
        country_code: 580
    },
    {
        name: 'Norway',
        alpha_code: 'NO',
        country_code: 578
    },
    {
        name: 'Oman',
        alpha_code: 'OM',
        country_code: 512
    },
    {
        name: 'Pakistan',
        alpha_code: 'PK',
        country_code: 586
    },
    {
        name: 'Palau',
        alpha_code: 'PW',
        country_code: 585
    },
    {
        name: 'Palestine, State of',
        alpha_code: 'PS',
        country_code: 275
    },
    {
        name: 'Panama',
        alpha_code: 'PA',
        country_code: 591
    },
    {
        name: 'Papua New Guinea',
        alpha_code: 'PG',
        country_code: 598
    },
    {
        name: 'Paraguay',
        alpha_code: 'PY',
        country_code: 600
    },
    {
        name: 'Peru',
        alpha_code: 'PE',
        country_code: 604
    },
    {
        name: 'Philippines',
        alpha_code: 'PH',
        country_code: 608
    },
    {
        name: 'Pitcairn',
        alpha_code: 'PN',
        country_code: 612
    },
    {
        name: 'Poland',
        alpha_code: 'PL',
        country_code: 616
    },
    {
        name: 'Portugal',
        alpha_code: 'PT',
        country_code: 620
    },
    {
        name: 'Puerto Rico',
        alpha_code: 'PR',
        country_code: 630
    },
    {
        name: 'Qatar',
        alpha_code: 'QA',
        country_code: 634
    },
    {
        name: 'Réunion',
        alpha_code: 'RE',
        country_code: 638
    },
    {
        name: 'Romania',
        alpha_code: 'RO',
        country_code: 642
    },
    {
        name: 'Russian Federation',
        alpha_code: 'RU',
        country_code: 643
    },
    {
        name: 'Rwanda',
        alpha_code: 'RW',
        country_code: 646
    },
    {
        name: 'Saint Barthélemy',
        alpha_code: 'BL',
        country_code: 652
    },
    {
        name: 'Saint Helena, Ascension and Tristan da Cunha',
        alpha_code: 'SH',
        country_code: 654
    },
    {
        name: 'Saint Kitts and Nevis',
        alpha_code: 'KN',
        country_code: 659
    },
    {
        name: 'Saint Lucia',
        alpha_code: 'LC',
        country_code: 662
    },
    {
        name: 'Saint Martin (French part)',
        alpha_code: 'MF',
        country_code: 663
    },
    {
        name: 'Saint Pierre and Miquelon',
        alpha_code: 'PM',
        country_code: 666
    },
    {
        name: 'Saint Vincent and the Grenadines',
        alpha_code: 'VC',
        country_code: 670
    },
    {
        name: 'Samoa',
        alpha_code: 'WS',
        country_code: 882
    },
    {
        name: 'San Marino',
        alpha_code: 'SM',
        country_code: 674
    },
    {
        name: 'Sao Tome and Principe',
        alpha_code: 'ST',
        country_code: 678
    },
    {
        name: 'Saudi Arabia',
        alpha_code: 'SA',
        country_code: 682
    },
    {
        name: 'Senegal',
        alpha_code: 'SN',
        country_code: 686
    },
    {
        name: 'Serbia',
        alpha_code: 'RS',
        country_code: 688
    },
    {
        name: 'Seychelles',
        alpha_code: 'SC',
        country_code: 690
    },
    {
        name: 'Sierra Leone',
        alpha_code: 'SL',
        country_code: 694
    },
    {
        name: 'Singapore',
        alpha_code: 'SG',
        country_code: 702
    },
    {
        name: 'Sint Maarten (Dutch part)',
        alpha_code: 'SX',
        country_code: 534
    },
    {
        name: 'Slovakia',
        alpha_code: 'SK',
        country_code: 703
    },
    {
        name: 'Slovenia',
        alpha_code: 'SI',
        country_code: 705
    },
    {
        name: 'Solomon Islands',
        alpha_code: 'SB',
        country_code: 90
    },
    {
        name: 'Somalia',
        alpha_code: 'SO',
        country_code: 706
    },
    {
        name: 'South Africa',
        alpha_code: 'ZA',
        country_code: 710
    },
    {
        name: 'South Georgia and the South Sandwich Islands',
        alpha_code: 'GS',
        country_code: 239
    },
    {
        name: 'South Sudan',
        alpha_code: 'SS',
        country_code: 728
    },
    {
        name: 'Spain',
        alpha_code: 'ES',
        country_code: 724
    },
    {
        name: 'Sri Lanka',
        alpha_code: 'LK',
        country_code: 144
    },
    {
        name: 'Sudan',
        alpha_code: 'SD',
        country_code: 729
    },
    {
        name: 'Suriname',
        alpha_code: 'SR',
        country_code: 740
    },
    {
        name: 'Svalbard and Jan Mayen',
        alpha_code: 'SJ',
        country_code: 744
    },
    {
        name: 'Sweden',
        alpha_code: 'SE',
        country_code: 752
    },
    {
        name: 'Switzerland',
        alpha_code: 'CH',
        country_code: 756
    },
    {
        name: 'Syrian Arab Republic',
        alpha_code: 'SY',
        country_code: 760
    },
    {
        name: 'Taiwan, Province of China',
        alpha_code: 'TW',
        country_code: 158
    },
    {
        name: 'Tajikistan',
        alpha_code: 'TJ',
        country_code: 762
    },
    {
        name: 'Tanzania, United Republic of',
        alpha_code: 'TZ',
        country_code: 834
    },
    {
        name: 'Thailand',
        alpha_code: 'TH',
        country_code: 764
    },
    {
        name: 'Timor-Leste',
        alpha_code: 'TL',
        country_code: 626
    },
    {
        name: 'Togo',
        alpha_code: 'TG',
        country_code: 768
    },
    {
        name: 'Tokelau',
        alpha_code: 'TK',
        country_code: 772
    },
    {
        name: 'Tonga',
        alpha_code: 'TO',
        country_code: 776
    },
    {
        name: 'Trinidad and Tobago',
        alpha_code: 'TT',
        country_code: 780
    },
    {
        name: 'Tunisia',
        alpha_code: 'TN',
        country_code: 788
    },
    {
        name: 'Turkey',
        alpha_code: 'TR',
        country_code: 792
    },
    {
        name: 'Turkmenistan',
        alpha_code: 'TM',
        country_code: 795
    },
    {
        name: 'Turks and Caicos Islands',
        alpha_code: 'TC',
        country_code: 796
    },
    {
        name: 'Tuvalu',
        alpha_code: 'TV',
        country_code: 798
    },
    {
        name: 'Uganda',
        alpha_code: 'UG',
        country_code: 800
    },
    {
        name: 'Ukraine',
        alpha_code: 'UA',
        country_code: 804
    },
    {
        name: 'United Arab Emirates',
        alpha_code: 'AE',
        country_code: 784
    },
    {
        name: 'United Kingdom of Great Britain and Northern Ireland',
        alpha_code: 'GB',
        country_code: 826
    },
    {
        name: 'United States of America',
        alpha_code: 'US',
        country_code: 840
    },
    {
        name: 'United States Minor Outlying Islands',
        alpha_code: 'UM',
        country_code: 581
    },
    {
        name: 'Uruguay',
        alpha_code: 'UY',
        country_code: 858
    },
    {
        name: 'Uzbekistan',
        alpha_code: 'UZ',
        country_code: 860
    },
    {
        name: 'Vanuatu',
        alpha_code: 'VU',
        country_code: 548
    },
    {
        name: 'Venezuela (Bolivarian Republic of)',
        alpha_code: 'VE',
        country_code: 862
    },
    {
        name: 'Viet Nam',
        alpha_code: 'VN',
        country_code: 704
    },
    {
        name: 'Virgin Islands (British)',
        alpha_code: 'VG',
        country_code: 92
    },
    {
        name: 'Virgin Islands (U.S.)',
        alpha_code: 'VI',
        country_code: 850
    },
    {
        name: 'Wallis and Futuna',
        alpha_code: 'WF',
        country_code: 876
    },
    {
        name: 'Western Sahara',
        alpha_code: 'EH',
        country_code: 732
    },
    {
        name: 'Yemen',
        alpha_code: 'YE',
        country_code: 887
    },
    {
        name: 'Zambia',
        alpha_code: 'ZM',
        country_code: 894
    },
    {
        name: 'Zimbabwe',
        alpha_code: 'ZW',
        country_code: 716
    }
];
