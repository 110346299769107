import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
export const PROFILE_SECTION_PERSONAL = 'personal';
export const PROFILE_SECTION_CONTACTS = 'contacts';
export const PROFILE_SECTION_ADDRESS = 'address';
export const PROFILE_SECTION_BANK = 'bank';
export const PROFILE_SECTION_BENEFICIARY_DETAILS = 'bank-beneficiary';
export const PROFILE_SECTION_PROMO = 'promo';
export const PROFILE_SECTION_THRESHOLD = 'commission-threshold';
export const PROFILE_SECTION_DYNAMIC_PARAMETERS = 'dynamic-params';
export const PROFILE_SECTION_TRAFFIC_CHANNELS = 'traffic-channels';
export const PROFILE_SECTION_PROMOTIONAL_INFO = 'promotional-details';
let ProfileApiService = class ProfileApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/profile';
        this.endpointUrlAccount = '/account';
        this.endpointUrlSecretKey = '/callback-secret-key';
        this.endpointUploadAvatar = '/profile/upload-image';
        this.endpointGetAvatar = '/profile/download-image';
        this.endpointRemoveAvatar = '/profile/remove-image';
    }
    get(section) {
        return this._httpClient
            .get(this.endpointUrl, { params: { section } })
            .pipe(pluck('data'));
    }
    getMyAccount() {
        return this._httpClient.get(`${this.endpointUrlAccount}`).pipe(pluck('data'));
    }
    patchSection(section, params) {
        return this._httpClient.patch(`${this.endpointUrl}/${section}`, params).pipe(pluck('data'));
    }
    post(body) {
        return this._httpClient.post(`${this.endpointUrl}`, body);
    }
    changeSecretKey(body) {
        return this._httpClient.post(`${this.endpointUrlSecretKey}`, body).pipe(pluck('data'));
    }
    getAvatar() {
        return this._httpClient.get(`${this.endpointGetAvatar}`).pipe(pluck('data'));
    }
    uploadAvatar(image) {
        return this._httpClient.post(`${this.endpointUploadAvatar}`, { file: image }).pipe(pluck('data'));
    }
    removeAvatar() {
        return this._httpClient.post(`${this.endpointRemoveAvatar}`, {}).pipe(pluck('data'));
    }
};
ProfileApiService.ctorParameters = () => [
    { type: HttpClient }
];
ProfileApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], ProfileApiService);
export { ProfileApiService };
