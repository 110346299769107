import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var FlowStatus;
(function (FlowStatus) {
    FlowStatus[FlowStatus["Draft"] = -2] = "Draft";
    FlowStatus[FlowStatus["Deleted"] = -1] = "Deleted";
    FlowStatus[FlowStatus["Active"] = 1] = "Active";
})(FlowStatus || (FlowStatus = {}));
export const FlowStatusLabels = new Map([
    [FlowStatus.Draft, 'Global.Options.FlowStatusLabels.Draft'],
    [FlowStatus.Deleted, 'Global.Status.Deleted'],
    [FlowStatus.Active, 'Global.Status.Active'],
]);
let FlowStatusService = class FlowStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = FlowStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
FlowStatusService.ctorParameters = () => [
    { type: TranslateService }
];
FlowStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], FlowStatusService);
export { FlowStatusService };
