import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
export var BlockRuleType;
(function (BlockRuleType) {
    BlockRuleType[BlockRuleType["Permit"] = 1] = "Permit";
    BlockRuleType[BlockRuleType["Block"] = 2] = "Block";
})(BlockRuleType || (BlockRuleType = {}));
let BlockingRulesApiService = class BlockingRulesApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this.endpointUrl = '/offer-management/blocking-rules';
    }
    head(params) {
        return this._httpClient.head(this.endpointUrl, { params, observe: 'response' });
    }
    blockingRules(params) {
        return this._httpClient.get(this.endpointUrl, { params, observe: 'response' });
    }
    get(id) {
        return this._httpClient.get(`${this.endpointUrl}/${id}`);
    }
    save(body, options) {
        return body.id ? this.put(body.id, body, options) : this.post(body, options);
    }
    delete(id, options) {
        return this._httpClient.delete(`${this.endpointUrl}/${id}`, options);
    }
    post(body, options) {
        return this._httpClient.post(this.endpointUrl, body, options);
    }
    put(id, body, options) {
        return this._httpClient.put(`${this.endpointUrl}/${id}`, body, options);
    }
};
BlockingRulesApiService.ctorParameters = () => [
    { type: HttpClient }
];
BlockingRulesApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], BlockingRulesApiService);
export { BlockingRulesApiService };
