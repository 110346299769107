import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var RewardTypes;
(function (RewardTypes) {
    RewardTypes[RewardTypes["PercentageOfFriendsPayment"] = 1] = "PercentageOfFriendsPayment";
    RewardTypes[RewardTypes["FixedAmountPerCPL"] = 3] = "FixedAmountPerCPL";
    RewardTypes[RewardTypes["FixedAmountPerCPA"] = 4] = "FixedAmountPerCPA";
    RewardTypes[RewardTypes["FixedAmountPerRevshare"] = 5] = "FixedAmountPerRevshare";
})(RewardTypes || (RewardTypes = {}));
export const RewardTypesLabels = new Map([
    [RewardTypes.PercentageOfFriendsPayment, 'Global.Options.RewardTypesLabels.PercentageOfFriendsPayment'],
    [RewardTypes.FixedAmountPerCPL, 'Global.Options.RewardTypesLabels.FixedAmountPerCPL'],
    [RewardTypes.FixedAmountPerCPA, 'Global.Options.RewardTypesLabels.FixedAmountPerCPA'],
    [RewardTypes.FixedAmountPerRevshare, 'Global.Options.RewardTypesLabels.FixedAmountPerRevshare'],
]);
let RewardTypesLabelsService = class RewardTypesLabelsService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = new Map(RewardTypesLabels);
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
RewardTypesLabelsService.ctorParameters = () => [
    { type: TranslateService }
];
RewardTypesLabelsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], RewardTypesLabelsService);
export { RewardTypesLabelsService };
