import { __decorate, __metadata } from "tslib";
import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
let QaAttributeWidgetDirective = class QaAttributeWidgetDirective {
    constructor(renderer, el) {
        this.renderer = renderer;
        this.el = el;
    }
    ngOnInit() {
        if (this.qaAttributeValue) {
            this.renderer.setAttribute(this.el.nativeElement, 'qa_id', this.qaAttributeValue);
        }
    }
};
QaAttributeWidgetDirective.ctorParameters = () => [
    { type: Renderer2 },
    { type: ElementRef }
];
QaAttributeWidgetDirective.propDecorators = {
    qaAttributeValue: [{ type: Input, args: ['hoQaAttributeWidget',] }]
};
QaAttributeWidgetDirective = __decorate([
    Directive({
        selector: '[hoQaAttributeWidget]'
    }),
    __metadata("design:paramtypes", [Renderer2, ElementRef])
], QaAttributeWidgetDirective);
export { QaAttributeWidgetDirective };
