import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var BounceReason;
(function (BounceReason) {
    BounceReason[BounceReason["InvalidBankDetails"] = 0] = "InvalidBankDetails";
    BounceReason[BounceReason["IncorrectAccountDetails"] = 1] = "IncorrectAccountDetails";
    BounceReason[BounceReason["IncorrectBeneficiaryDetails"] = 2] = "IncorrectBeneficiaryDetails";
    BounceReason[BounceReason["IncorrectAmount"] = 3] = "IncorrectAmount";
    BounceReason[BounceReason["Fraud"] = 4] = "Fraud";
    BounceReason[BounceReason["Other"] = 5] = "Other";
})(BounceReason || (BounceReason = {}));
export const BounceReasonMap = new Map([
    [BounceReason.InvalidBankDetails, 'Global.Options.BounceReason.InvalidBankDetails'],
    [BounceReason.IncorrectAccountDetails, 'Global.Options.BounceReason.IncorrectAccountDetails'],
    [BounceReason.IncorrectBeneficiaryDetails, 'Global.Options.BounceReason.IncorrectBeneficiaryDetails'],
    [BounceReason.IncorrectAmount, 'Global.Options.BounceReason.IncorrectAmount'],
    [BounceReason.Fraud, 'Global.Options.BounceReason.Fraud'],
    [BounceReason.Other, 'Global.Options.BounceReason.Other'],
]);
let BounceReasonService = class BounceReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = BounceReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
BounceReasonService.ctorParameters = () => [
    { type: TranslateService }
];
BounceReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], BounceReasonService);
export { BounceReasonService };
