export const RED_BUTTON_COLORS = [
    {
        variable: '--btn-gradient-left-bg',
        value: '#5d58f7',
    },
    {
        variable: '--btn-gradient-center-bg',
        value: '#966df5',
    },
    {
        variable: '--btn-gradient-right-bg',
        value: '#543fdc',
    },
];
export const RED_LAYOUT_COLORS = [
    {
        variable: '--layout-content-bg',
        value: '#320d31',
    },
    {
        variable: '--layout-page-bg',
        value: '#420d3f',
    },
];
export const RED_COLORS = [
    {
        variable: '--primary',
        value: '#7d527b',
        tooltip: 'usage in snack, input, links, buttons',
    },
    {
        variable: '--primary-light',
        value: '#734971',
    },
    {
        variable: '--primary-lighter',
        value: '#8b6889',
    },
    {
        variable: '--primary-dark',
        value: '#415da0',
    },
    {
        variable: '--blue-light',
        value: '#420d3f',
    },
    {
        variable: '--blue-lighter',
        value: '#420d3f',
    },
    {
        variable: '--success',
        value: '#29af61',
        tooltip: 'usage in snack, buttons, badge',
    },
    {
        variable: '--success-light',
        value: '#54bf81',
    },
    {
        variable: '--success-lighter',
        value: '#bde6ce',
    },
    {
        variable: '--success-dark',
        value: '#1f8349',
    },
    {
        variable: '--light-grey',
        value: '#6a6a6b',
    },
    {
        variable: '--accent-white',
        value: '#c3cfde',
    },
    {
        variable: '--grey',
        value: '#a5a5a5',
    },
    {
        variable: '--grey-dark',
        value: '#a5a5a5',
    },
    {
        variable: '--grey-light',
        value: '#8095c7',
    },
    {
        variable: '--black',
        value: '#d7d9da',
    },
    {
        variable: '--danger',
        value: '#f54662',
        tooltip: 'usage in inputs, buttons, badge, snack',
    },
    {
        variable: '--danger-light',
        value: '#f76b81',
    },
    {
        variable: '--danger-lighter',
        value: '#fbc6cf',
    },
    {
        variable: '--danger-dark',
        value: '#b8354a',
    },
    {
        variable: '--warning',
        value: '#ff9e35',
        tooltip: 'usage in buttons, badge, snack',
    },
    {
        variable: '--warning-light',
        value: '#ffb15d',
    },
    {
        variable: '--warning-lighter',
        value: '#ffe1c1',
    },
    {
        variable: '--warning-dark',
        value: '#d37016',
    },
    {
        variable: '--accent',
        value: '#36a0f2',
    },
    {
        variable: '--accent-light',
        value: '#86c6f7',
    },
    {
        variable: '--accent-lighter',
        value: '#c1e2fb',
    },
    {
        variable: '--accent-dark',
        value: '#2978b5',
    },
    {
        variable: '--info',
        value: '#8171e7',
        tooltip: 'usage in buttons, badge, snack',
    },
    {
        variable: '--info-light',
        value: '#9a8dec',
    },
    {
        variable: '--info-lighter',
        value: '#676a6c',
    },
    {
        variable: '--info-dark',
        value: '#6155ad',
    },
    {
        variable: '--white',
        value: '#ffffff',
        tooltip: 'usage in buttons/badge/snack... color text',
    },
    {
        variable: '--white-darker',
        value: '#676a6c',
    },
    {
        variable: '--light-hover',
        value: '#420d3f',
    },
    {
        variable: '--disabled-color',
        value: '#6a4669',
    },
    {
        variable: '--landing-bg',
        value: '#320d31',
    },
    {
        variable: '--loading-bg',
        value: 'rgba(50, 13,38, .7)',
    },
    {
        variable: '--sign-in-color-bg',
        value: '#eeeeee',
    },
    {
        variable: '--btn-link-hover-bg',
        value: '#577cd5',
    },
    {
        variable: '--btn-link-disabled-color',
        value: '#adadae',
    },
];
export const RED_ALL_COLORS = [...RED_COLORS, ...RED_BUTTON_COLORS, ...RED_LAYOUT_COLORS];
export const RED_COLOR_SCHEME = {
    otherColors: RED_COLORS,
    buttonColors: RED_BUTTON_COLORS,
    layoutColors: RED_LAYOUT_COLORS,
};
