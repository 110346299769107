import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DataSourceHandlerService } from '../+state/data-source-handler.service';
import { pluck } from 'rxjs/operators';
// LeadDist Traffic Channels
let LeadDistTrafficChannelsService = class LeadDistTrafficChannelsService {
    constructor(_httpClient, _dataSourceHandlerService) {
        this._httpClient = _httpClient;
        this._dataSourceHandlerService = _dataSourceHandlerService;
        this._endpointUrl = '/dictionary';
    }
    list() {
        return this._dataSourceHandlerService
            .getData(`${this._endpointUrl}/traffic-channels`)
            .pipe(pluck('data'))
            .toPromise();
    }
};
LeadDistTrafficChannelsService.ctorParameters = () => [
    { type: HttpClient },
    { type: DataSourceHandlerService }
];
LeadDistTrafficChannelsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, DataSourceHandlerService])
], LeadDistTrafficChannelsService);
export { LeadDistTrafficChannelsService };
