import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
let DateTimeHelper = class DateTimeHelper {
    static parseISO(date) {
        return DateTime.fromISO(date).toISO({
            suppressMilliseconds: true
        });
    }
    static parseFormat(date, format) {
        return DateTime.fromFormat(date, format);
    }
};
/**
 * Acceptable by backend server ISO format
 * It's a bit different from moment.js ISO format
 * @type {string}
 */
DateTimeHelper.ISO_FORMAT = "y-LL-dd'T'HH:mm:ssZZ";
DateTimeHelper = __decorate([
    Injectable()
], DateTimeHelper);
export { DateTimeHelper };
