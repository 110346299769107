import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UserTypes;
(function (UserTypes) {
    UserTypes[UserTypes["Guest"] = -1] = "Guest";
    UserTypes[UserTypes["Admin"] = 0] = "Admin";
    UserTypes[UserTypes["AffiliateManager"] = 1] = "AffiliateManager";
    UserTypes[UserTypes["Affiliate"] = 2] = "Affiliate";
    UserTypes[UserTypes["MediaBuyer"] = 3] = "MediaBuyer";
})(UserTypes || (UserTypes = {}));
export const UserTypesMap = new Map([
    [UserTypes.Guest, 'Global.UserRole.Guest'],
    [UserTypes.Admin, 'Global.UserRole.Admin'],
    [UserTypes.AffiliateManager, 'Global.UserRole.Manager'],
    [UserTypes.Affiliate, 'Global.UserRole.Affiliate'],
    [UserTypes.MediaBuyer, 'Global.UserRole.MediaBuyer'],
]);
let UserTypeService = class UserTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UserTypesMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
UserTypeService.ctorParameters = () => [
    { type: TranslateService }
];
UserTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UserTypeService);
export { UserTypeService };
