import { __decorate, __metadata } from "tslib";
import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { SERVER, TypeServer } from '@ho/shared/constants/server';
import { SessionQuery, SessionService } from '@ho/shared/data-access/session';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Observable, of } from 'rxjs';
let GuestUserGuard = class GuestUserGuard {
    constructor(_authQuery, _authService, _router, environment, document) {
        this._authQuery = _authQuery;
        this._authService = _authService;
        this._router = _router;
        this.environment = environment;
        this.document = document;
    }
    canActivate(route, state) {
        return Observable.create((observer) => {
            const isAuthApp = !this.environment.role.length; // it's a separate application for Production's build mode
            if (this.environment.production && !isAuthApp && SERVER.type === TypeServer.NotLocalhost) {
                // redirect to auth-app
                this.document.location.href = `${location.origin}/v3/auth`;
            }
            else {
                if (this._authQuery.isLoggedIn() && this._authQuery.userInfo()) {
                    this._navigateUserToAppropriateApp(isAuthApp, observer);
                    return of(true);
                }
                if (localStorage.getItem(this.environment.jwtTokenName)) {
                    return this._authService
                        .autologin(localStorage.getItem(this.environment.jwtTokenName))
                        .subscribe(() => {
                        this._navigateUserToAppropriateApp(isAuthApp, observer);
                    });
                }
            }
            observer.next(true);
            observer.complete();
        });
    }
    _navigateUserToAppropriateApp(isAuthApp, observer) {
        if (!isAuthApp) {
            this._router.navigate(['/d']);
            observer.next(false);
            observer.complete();
        }
        else {
            this._authService.switchApp(observer);
        }
    }
};
GuestUserGuard.ctorParameters = () => [
    { type: SessionQuery },
    { type: SessionService },
    { type: Router },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Document, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];
GuestUserGuard = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [SessionQuery,
        SessionService,
        Router, Object, Document])
], GuestUserGuard);
export { GuestUserGuard };
