import { __decorate } from "tslib";
import { Injectable } from '@angular/core';
import { DateTimeHelper } from './date.helper';
import { DateTime } from 'luxon';
import { isNil } from 'lodash-es';
let RoutingHelper = class RoutingHelper {
    /**
     * Form to Route
     * @param v
     */
    static preparePayload(v) {
        const result = Object.assign({}, v);
        Object.keys(v).forEach((key) => {
            if (DateTime.isDateTime(result[key])) {
                result[key] = result[key].toFormat(DateTimeHelper.ISO_FORMAT);
            }
        });
        return result;
    }
    static stringToMoment(v, keys) {
        const result = Object.assign({}, v);
        keys.forEach((key) => {
            const value = result[key];
            if (value && typeof value === 'string') {
                result[key] = DateTime.fromISO(value);
            }
        });
        return result;
    }
    static convertParamsToArray(v) {
        if ((v && Array.isArray(v)) || isNil(v)) {
            return v;
        }
        return v.split(',').filter((e) => e);
    }
};
RoutingHelper = __decorate([
    Injectable()
], RoutingHelper);
export { RoutingHelper };
