import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionsBlockComponent } from './components/permissions-block/permissions-block.component';
import { PermissionsBlockListContainer } from './containers/permissions-block-list/permissions-block-list.container';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PermissionSettingsService } from './services/permission-settings.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxPermissionsModule } from 'ngx-permissions';
import { QaAttributeWidgetModule } from '@ho/shared/widgets/qa-attribute-widget';
import { MobileTooltipHandlerModule } from '@ho/shared/ui/directives';
import { TranslateModule } from '@ngx-translate/core';
let PermissionSettingsModule = class PermissionSettingsModule {
};
PermissionSettingsModule = __decorate([
    NgModule({
        imports: [
            CommonModule,
            ReactiveFormsModule,
            MatButtonModule,
            MatCheckboxModule,
            FlexLayoutModule,
            MatFormFieldModule,
            MatTooltipModule,
            MobileTooltipHandlerModule,
            NgxPermissionsModule.forChild(),
            QaAttributeWidgetModule,
            TranslateModule,
        ],
        declarations: [PermissionsBlockListContainer, PermissionsBlockComponent],
        exports: [PermissionsBlockListContainer],
        providers: [PermissionSettingsService],
    })
], PermissionSettingsModule);
export { PermissionSettingsModule };
