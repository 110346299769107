import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UiSettingsApiService } from '../services/ui-settings-api.service';
import { tap } from 'rxjs/operators';
import { fromEvent, of } from 'rxjs';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
let ThemeSettingsService = class ThemeSettingsService {
    constructor(_uiSettingsApiService, _uiSettingsStore) {
        this._uiSettingsApiService = _uiSettingsApiService;
        this._uiSettingsStore = _uiSettingsStore;
        this.root = document === null || document === void 0 ? void 0 : document.documentElement;
    }
    getSelectedTheme() { }
    setProperty(variable, value) {
        this.root.style.setProperty(variable, value);
    }
    updateColorSchemes(schemes) {
        return this._uiSettingsApiService.updateColorSchemes(schemes);
    }
    patchProperties(properties) {
        this.root.setAttribute('style', properties);
    }
    initColorSchemeWatcher() {
        if (this._isColorSchemeWatcherTurnOn) {
            return;
        }
        this._isColorSchemeWatcherTurnOn = true;
        fromEvent(window, 'storage').subscribe((evt) => {
            if (evt.key !== StoreNames.ColorScheme) {
                return;
            }
            this.applyThemes(JSON.parse(evt.newValue));
        });
    }
    applyThemes(colorSchemes) {
        const colorScheme = colorSchemes.color_schemes.find((item) => item.name === colorSchemes.selected_color_scheme).color_scheme;
        this.patchProperties(Object.keys(colorScheme).reduce((accScheme, key) => {
            accScheme += colorScheme[key].reduce((acc, item) => {
                acc += `${item.variable}:${item.value};`;
                return acc;
            }, '');
            return accScheme;
        }, ''));
        localStorage.setItem('selected_color_scheme', colorSchemes.selected_color_scheme);
    }
    updateThemeConfig(schemes) {
        return (localStorage.getItem('user_view_type')
            ? of({})
            : this._uiSettingsApiService.updateColorSchemes(schemes)).pipe(tap(() => {
            this._uiSettingsStore.updateThemeSettings({
                selected_color_scheme: schemes.selected_color_scheme,
            });
        }));
    }
};
ThemeSettingsService.ctorParameters = () => [
    { type: UiSettingsApiService },
    { type: UiSettingsStore }
];
ThemeSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService,
        UiSettingsStore])
], ThemeSettingsService);
export { ThemeSettingsService };
