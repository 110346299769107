import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableAkitaProdMode, persistState, akitaConfig } from '@datorama/akita';
import { StoreNames } from '@ho/shared/constants/store-config.enum';
if (environment.production) {
    enableProdMode();
    enableAkitaProdMode();
}
persistState({
    include: [StoreNames.Session, StoreNames.UiSettings],
    preStorageUpdate: (storeName, state) => state,
    preStoreUpdate(storeName, state) {
        return state;
    },
});
akitaConfig({
    resettable: true,
});
platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
