import { __decorate, __metadata } from "tslib";
import { TranslateService } from '@ngx-translate/core';
import { TimezonesService } from './timezones.service';
import { SessionQuery, SessionService, SessionUserTypeService } from '@ho/shared/data-access/session';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { catchError, timeout } from 'rxjs/operators';
import { throwError, TimeoutError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams, } from '@angular/common/http';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '@ho/shared/helpers/date.helper';
import { DOCUMENT } from '@angular/common';
import { SnackbarService } from '@ho/shared/ui/snack-bar';
import { prepareBodyParams } from '@ho/shared/utils';
let RequestInterceptorService = class RequestInterceptorService {
    constructor(_router, _sessionQuery, _sessionService, _sessionUserTypeService, _snack, _timezoneService, _translate, environment, _document) {
        this._router = _router;
        this._sessionQuery = _sessionQuery;
        this._sessionService = _sessionService;
        this._sessionUserTypeService = _sessionUserTypeService;
        this._snack = _snack;
        this._timezoneService = _timezoneService;
        this._translate = _translate;
        this.environment = environment;
        this._document = _document;
    }
    intercept(req, next) {
        return next.handle(this._configureRequest(req)).pipe(timeout(90000), catchError((err) => {
            let resp = err instanceof TimeoutError ? new HttpErrorResponse({ status: 524 }) : err;
            switch (resp.status) {
                case 401:
                    this._sessionService.logout();
                    this._document.location.href = `${location.origin}/v3/auth/a/login`;
                    break;
                case 403:
                    this._snack.error(resp.error['data'].message || this._translate.instant('Global.Notification.AccessDenied'));
                    break;
            }
            if (resp.error instanceof Error) {
                console.error('An error occurred:', resp.error.message);
            }
            else {
                console.error(`Backend returned code ${resp.status}, body was: ${JSON.stringify(req.body)}`);
            }
            return throwError(new HttpErrorResponse({ error: { response: resp, request: req } }));
        }));
    }
    _prepareQueryParams(params) {
        let result = new HttpParams();
        params.keys().forEach((key) => {
            if (params.getAll(key).length > 1) {
                params.getAll(key).forEach((k, idx) => (result = result.append(`${key}[${idx}]`, k.toString())));
            }
            else {
                let v = params.get(key);
                if (DateTime.isDateTime(v)) {
                    v = v.toFormat(DateTimeHelper.ISO_FORMAT);
                }
                if (v === null) {
                    v = '';
                }
                result = result.set(key, v);
            }
        });
        return result;
    }
    // string | string[]
    _prepareBodyParams(body) {
        return prepareBodyParams(body, this._timezoneService.getTimeZoneOffset());
    }
    _configureRequest(req) {
        const headers = {};
        if (this._sessionQuery.isLoggedIn() && !req.headers.get('Authorization')) {
            headers['Authorization'] = `Bearer ${this._sessionQuery.token()}`;
        }
        if (['POST', 'PUT', 'PATCH'].includes(req.method)) {
            headers['Content-Type'] = 'application/x-www-form-urlencoded';
        }
        return req.clone({
            params: this.environment.production
                ? this._prepareQueryParams(req.params).append('ngsw-bypass', 'true')
                : this._prepareQueryParams(req.params),
            body: this._prepareBodyParams(req.body),
            setHeaders: headers,
            url: this._processingRequestUrl(req),
        });
    }
    _processingRequestUrl(req) {
        var _a;
        let url;
        if ((!!~req.url.indexOf('.json') || !!~req.url.indexOf('http://') || !!~req.url.indexOf('https://s3')) &&
            !~req.url.indexOf('http://localhost')) {
            url = req.url;
        }
        else if (!!~req.url.indexOf('/wl')) {
            url = this.environment.apiUrl + req.url;
        }
        else {
            url =
                this.environment.apiUrl +
                    '/v3' +
                    (!this._containsExceptedUrl(req.url) ? `/${this._sessionUserTypeService.getAPIUserRoleUrl()}` : '') +
                    req.url;
        }
        if (req.method === 'DELETE') {
            const a = new URL(url);
            a.searchParams.append('th-lang', ((_a = this._sessionQuery.userInfo()) === null || _a === void 0 ? void 0 : _a.language) || localStorage.getItem('language') || 'en');
            url = a.href;
        }
        return url;
    }
    _containsExceptedUrl(url) {
        const exceptionV3Requests = [
            'auth',
            'account',
            'profile',
            'me',
            'notifications',
            'tg',
            'jwt',
            'preview-image',
        ];
        let found = false;
        exceptionV3Requests.forEach((exceptedUrl) => {
            const regexp = new RegExp(`\/${exceptedUrl}((\\/)|([^\\w]?$))`);
            if (regexp.test(url) && !found) {
                found = true;
                return;
            }
        });
        return found;
    }
};
RequestInterceptorService.ctorParameters = () => [
    { type: Router },
    { type: SessionQuery },
    { type: SessionService },
    { type: SessionUserTypeService },
    { type: SnackbarService },
    { type: TimezonesService },
    { type: TranslateService },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: Document, decorators: [{ type: Inject, args: [DOCUMENT,] }] }
];
RequestInterceptorService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Router,
        SessionQuery,
        SessionService,
        SessionUserTypeService,
        SnackbarService,
        TimezonesService,
        TranslateService, Object, Document])
], RequestInterceptorService);
export { RequestInterceptorService };
