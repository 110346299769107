import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SessionQuery } from '@ho/shared/data-access/session';
let PromotionRequestApiService = class PromotionRequestApiService {
    constructor(_http, _sessionQuery) {
        this._http = _http;
        this._sessionQuery = _sessionQuery;
        this.endpointUrl = '/offer-management/campaign-user-rules';
    }
    all(body) {
        return this._http.post(`${this.endpointUrl}/list`, body, {
            observe: 'response',
        });
    }
    get(id) {
        return this._http.get(`${this.endpointUrl}/${id}`);
    }
    post(body, options) {
        return this._http.post(this.endpointUrl, body, options);
    }
    put(id, body, options) {
        return this._http.put(`${this.endpointUrl}/${id}`, body, options);
    }
    save(body, options) {
        return body.id ? this.put(body.id, body, options) : this.post(body, options);
    }
    delete(id, options) {
        return this._http.delete(`${this.endpointUrl}/${id}`, options);
    }
    batchCreate(body, options) {
        return this._http.post(`${this.endpointUrl}/batch-create`, body, options);
    }
    batchApprove(body, options) {
        return this._http.post(`${this.endpointUrl}/batch-approve`, body, options);
    }
    batchReject(body, options) {
        return this._http.post(`${this.endpointUrl}/batch-reject`, body, options);
    }
    offerCampaignRequests(body) {
        return this._http.post(`${this.endpointUrl}/offer-campaign-requests`, body, {
            observe: 'response',
        });
    }
    adminCampaignRequests(body) {
        return this._http.post(`${this.endpointUrl}/admin-campaign-requests`, body, {
            observe: 'response',
        });
    }
    offerCampaignRequestsForAff(body) {
        return this._http.post(`${this.endpointUrl}/offer-campaign-requests`, body, {
            observe: 'response',
        });
    }
    adminCampaignRequestsForAff(body) {
        return this._http.post(`${this.endpointUrl}/admin-campaign-requests`, body, {
            observe: 'response',
        });
    }
};
PromotionRequestApiService.ctorParameters = () => [
    { type: HttpClient },
    { type: SessionQuery }
];
PromotionRequestApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient, SessionQuery])
], PromotionRequestApiService);
export { PromotionRequestApiService };
