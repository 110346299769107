export function get(token) {
    const session = localStorage.getItem(token);
    return session ? JSON.parse(session) : null;
}
export function set(key, value) {
    localStorage.setItem(key, typeof value !== 'string' ? JSON.stringify(value) : value);
}
export function remove(key) {
    localStorage.removeItem(key);
}
