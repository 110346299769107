import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { BlockingRulesApiService, } from '@ho/shared/data-access/core/lib/+state/blocking-rules-api.service';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
let BlockingRulesService = class BlockingRulesService {
    constructor(_blockingRulesApiService) {
        this._blockingRulesApiService = _blockingRulesApiService;
        this.counterUpdated$ = new Subject();
    }
    head(params) {
        return this._blockingRulesApiService.head(params).pipe(map((res) => {
            const counter = parseInt(res.headers.get('x-blocked-countries-count'), 10);
            return {
                counter: counter === -1 ? 'All' : counter,
                message: counter
                    ? counter !== -1
                        ? `Traffic from ${res.headers.get('x-blocked-countries')} redirected`
                        : `Traffic from all countries redirected`
                    : '',
            };
        }));
    }
    blockingRules(params) {
        return this._blockingRulesApiService.blockingRules(params);
    }
    save(body, options) {
        return this._blockingRulesApiService.save(body, options);
    }
    delete(id, options) {
        return this._blockingRulesApiService.delete(id, options);
    }
};
BlockingRulesService.ctorParameters = () => [
    { type: BlockingRulesApiService }
];
BlockingRulesService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [BlockingRulesApiService])
], BlockingRulesService);
export { BlockingRulesService };
