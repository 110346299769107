import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UnvoidReason;
(function (UnvoidReason) {
    UnvoidReason["VoidByMistake"] = "void_by_mistake";
    UnvoidReason["Other"] = "other";
})(UnvoidReason || (UnvoidReason = {}));
export const UnvoidReasonMap = new Map([
    [UnvoidReason.VoidByMistake, 'Global.Options.UnvoidReason.VoidByMistake'],
    [UnvoidReason.Other, 'Global.Options.UnvoidReason.Other'],
]);
let UnvoidReasonService = class UnvoidReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UnvoidReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
    has(key) {
        return this._data.has(key);
    }
};
UnvoidReasonService.ctorParameters = () => [
    { type: TranslateService }
];
UnvoidReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UnvoidReasonService);
export { UnvoidReasonService };
