import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { SessionStore } from './session.store';
import { JwtHelperService } from '@auth0/angular-jwt';
let SessionQuery = class SessionQuery extends Query {
    constructor(store, _jwtHelper) {
        super(store);
        this.store = store;
        this._jwtHelper = _jwtHelper;
        this.userInfo$ = this.select((state) => state.userInfo);
        this.isLoggedIn$ = this.select((state) => toBoolean(state.token));
        this.token$ = this.select((state) => state.token);
    }
    isLoggedIn() {
        const token = this.getValue().token;
        return toBoolean(token) && !this._jwtHelper.isTokenExpired(token);
    }
    token() {
        return this.getValue().token;
    }
    userInfo() {
        return this.getValue().userInfo;
    }
    get user() {
        // return this.getValue().user;
        return {};
    }
};
SessionQuery.ctorParameters = () => [
    { type: SessionStore },
    { type: JwtHelperService }
];
SessionQuery = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [SessionStore, JwtHelperService])
], SessionQuery);
export { SessionQuery };
