export var StoreNames;
(function (StoreNames) {
    StoreNames["UiSettings"] = "ui-settings";
    StoreNames["Dictionaries"] = "dictionaries";
    StoreNames["DynamicParams"] = "dynamic-params";
    StoreNames["Autocomplete"] = "autocomplete";
    StoreNames["Session"] = "session";
    StoreNames["Campaign"] = "campaign";
    StoreNames["ColorScheme"] = "color-scheme";
    StoreNames["UiSettingsGridCache"] = "ui-settings-grid-cache";
})(StoreNames || (StoreNames = {}));
