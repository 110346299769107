import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var UserStatus;
(function (UserStatus) {
    UserStatus[UserStatus["New"] = 0] = "New";
    UserStatus[UserStatus["Active"] = 1] = "Active";
    UserStatus[UserStatus["Pending"] = 2] = "Pending";
    UserStatus[UserStatus["Banned"] = 3] = "Banned";
})(UserStatus || (UserStatus = {}));
export const UserStatusMap = new Map([
    [UserStatus.New, 'Global.Status.New'],
    [UserStatus.Active, 'Global.Status.Active'],
    [UserStatus.Pending, 'Global.Status.Pending'],
    [UserStatus.Banned, 'Global.UserStatus.Banned'],
]);
let UserStatusService = class UserStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = UserStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
    getStyleClass(status) {
        switch (status) {
            case UserStatus.Active:
                return 'text-success';
            case UserStatus.Banned:
                return 'text-danger';
            case UserStatus.Pending:
            case UserStatus.New:
                return 'text-warning';
            default:
                return '';
        }
    }
};
UserStatusService.ctorParameters = () => [
    { type: TranslateService }
];
UserStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], UserStatusService);
export { UserStatusService };
