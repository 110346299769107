import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { RegistrationApiService } from './registration-api.service';
let RegistrationService = class RegistrationService {
    constructor(apiService) {
        this.apiService = apiService;
    }
    register(body, options) {
        return this.apiService.register(body, options);
    }
    storeEmail(email) {
        localStorage.setItem('userEmail', email);
    }
    getRegistrationEmail() {
        return localStorage.getItem('userEmail');
    }
    verifyEmail(body, options) {
        return this.apiService.verifyEmail(body, options);
    }
};
RegistrationService.ctorParameters = () => [
    { type: RegistrationApiService }
];
RegistrationService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [RegistrationApiService])
], RegistrationService);
export { RegistrationService };
