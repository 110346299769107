import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { PromotionRequestApiService } from './promotion-request-api.service';
import { isNil, map as _map } from 'lodash-es';
import { map } from 'rxjs/operators';
import { CampaignUserRuleStatusService } from '@ho/shared/data-access/dictionary';
import { TranslateService } from '@ngx-translate/core';
let PromotionRequestService = class PromotionRequestService {
    constructor(_promotionRequestApiService, _campaignUserRuleStatusService, _translate) {
        this._promotionRequestApiService = _promotionRequestApiService;
        this._campaignUserRuleStatusService = _campaignUserRuleStatusService;
        this._translate = _translate;
    }
    all(body) {
        return this._promotionRequestApiService.all(body).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                return Object.assign(Object.assign({}, item), { revshare_commission_value: isNil(item.default_revshare_first_commission_percent) &&
                        isNil(item.default_revshare_recurrent_commission_percent)
                        ? this._translate.instant('Global.Text.Default')
                        : `${item.default_revshare_first_commission_percent}% / ${item.default_revshare_recurrent_commission_percent}%`, revshare_tooltip_info: isNil(item.default_revshare_first_commission_percent) &&
                        isNil(item.default_revshare_recurrent_commission_percent)
                        ? null
                        : this._translate.instant('OfferManagement.RevshareInfoTooltip', {
                            revshareFirstCommissionPercent: item.default_revshare_first_commission_percent,
                            revshareRecurrentCommissionPercent: item.default_revshare_recurrent_commission_percent,
                        }), statusClass: this._campaignUserRuleStatusService.getStyleClass(item.status_id) });
            });
            return response;
        }));
    }
    get(id) {
        return this._promotionRequestApiService.get(id);
    }
    post(body, options) {
        return this._promotionRequestApiService.post(body, options);
    }
    put(id, body, options) {
        return this._promotionRequestApiService.put(id, body, options);
    }
    save(body, options) {
        return this._promotionRequestApiService.save(body, options);
    }
    delete(id, options) {
        return this._promotionRequestApiService.delete(id, options);
    }
    batchCreate(body, options) {
        return this._promotionRequestApiService.batchCreate(body, options);
    }
    batchApprove(body, options) {
        return this._promotionRequestApiService.batchApprove(body, options);
    }
    batchReject(body, options) {
        return this._promotionRequestApiService.batchReject(body, options);
    }
    offerCampaignRequests(params = {}) {
        return this._promotionRequestApiService.offerCampaignRequests(params).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                var _a;
                return Object.assign(Object.assign({}, item), { statusClass: this._campaignUserRuleStatusService.getStyleClass(item.status_id), languages: ((_a = item.language_system_names) === null || _a === void 0 ? void 0 : _a.join(', ').toUpperCase()) || null });
            });
            return response;
        }));
    }
    adminCampaignRequests(params = {}) {
        return this._promotionRequestApiService.adminCampaignRequests(params).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                return Object.assign(Object.assign({}, item), { language_system_name: item.language_system_name.toUpperCase(), statusClass: this._campaignUserRuleStatusService.getStyleClass(item.status_id) });
            });
            return response;
        }));
    }
    offerCampaignRequestsForAff(params = {}) {
        return this._promotionRequestApiService.offerCampaignRequestsForAff(params).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                var _a;
                return Object.assign(Object.assign({}, item), { statusClass: this._campaignUserRuleStatusService.getStyleClass(item.status_id), languages: (_a = item.language_system_names) === null || _a === void 0 ? void 0 : _a.join(', ').toUpperCase() });
            });
            return response;
        }));
    }
    adminCampaignRequestsForAff(params) {
        return this._promotionRequestApiService.adminCampaignRequestsForAff(params).pipe(map((response) => {
            response.body['data'] = _map(response.body['data'], (item) => {
                return Object.assign(Object.assign({}, item), { statusClass: this._campaignUserRuleStatusService.getStyleClass(item.status_id) });
            });
            return response;
        }));
    }
};
PromotionRequestService.ctorParameters = () => [
    { type: PromotionRequestApiService },
    { type: CampaignUserRuleStatusService },
    { type: TranslateService }
];
PromotionRequestService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [PromotionRequestApiService,
        CampaignUserRuleStatusService,
        TranslateService])
], PromotionRequestService);
export { PromotionRequestService };
