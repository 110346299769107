import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./confirm-modal.container.html";
import __NG_CLI_RESOURCE__1 from "./confirm-modal.container.scss";
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
let ConfirmModalContainer = class ConfirmModalContainer {
    constructor(_dialogRef, modalData) {
        this._dialogRef = _dialogRef;
        this.modalData = modalData;
    }
    onSubmit(key) {
        this._dialogRef.close(key);
    }
};
ConfirmModalContainer.ctorParameters = () => [
    { type: MatDialogRef },
    { type: undefined, decorators: [{ type: Inject, args: [MAT_DIALOG_DATA,] }] }
];
ConfirmModalContainer = __decorate([
    Component({
        selector: 'ho-confirm-modal-container',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [MatDialogRef, Object])
], ConfirmModalContainer);
export { ConfirmModalContainer };
