import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus[InvoiceStatus["Moderation"] = 0] = "Moderation";
    InvoiceStatus[InvoiceStatus["Approved"] = 1] = "Approved";
    InvoiceStatus[InvoiceStatus["Rejected"] = 2] = "Rejected";
})(InvoiceStatus || (InvoiceStatus = {}));
export const InvoiceStatusMap = new Map([
    [InvoiceStatus.Moderation, 'Global.Options.InvoiceStatus.Moderation'],
    [InvoiceStatus.Approved, 'Global.Status.Approved'],
    [InvoiceStatus.Rejected, 'Global.Status.Rejected'],
]);
let InvoiceStatusService = class InvoiceStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = InvoiceStatusMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
InvoiceStatusService.ctorParameters = () => [
    { type: TranslateService }
];
InvoiceStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], InvoiceStatusService);
export { InvoiceStatusService };
