import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
let PermissionsService = class PermissionsService {
    constructor(_ngxPermissionsService) {
        this._ngxPermissionsService = _ngxPermissionsService;
    }
    hasPermissions(permissions) {
        return this._hasPermissions(permissions);
    }
    hasPermissions$(permissions) {
        return of(this._hasPermissions(permissions));
    }
    _hasPermissions(permissions) {
        if (!(permissions === null || permissions === void 0 ? void 0 : permissions.length)) {
            return [];
        }
        return permissions.reduce((acc, permission) => {
            const permissionObject = this._ngxPermissionsService.getPermission(permission);
            acc.push((permissionObject === null || permissionObject === void 0 ? void 0 : permissionObject.validationFunction) ? permissionObject.validationFunction() : false);
            return acc;
        }, []);
    }
};
PermissionsService.ctorParameters = () => [
    { type: NgxPermissionsService }
];
PermissionsService = __decorate([
    Injectable({ providedIn: 'root' }),
    __metadata("design:paramtypes", [NgxPermissionsService])
], PermissionsService);
export { PermissionsService };
