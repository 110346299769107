import { __decorate } from "tslib";
import { Component, HostListener } from '@angular/core';
let HasUnsavedData = class HasUnsavedData {
    unloadNotification($event) {
        if (!this.canDeactivate()) {
            $event.returnValue = true;
        }
    }
    canDeactivate() {
        return true;
    }
};
HasUnsavedData.propDecorators = {
    unloadNotification: [{ type: HostListener, args: ['window:beforeunload', ['$event'],] }]
};
HasUnsavedData = __decorate([
    Component({
        selector: 'base-has-unsaved-date-class',
        template: '',
    })
], HasUnsavedData);
export { HasUnsavedData };
