import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var Tags;
(function (Tags) {
    Tags["AllowAffiliate"] = "allow_affiliate";
    Tags["AllowOffer"] = "allow_offer";
    Tags["AllowAdvertiser"] = "allow_advertiser";
})(Tags || (Tags = {}));
export const TagsMap = new Map([
    [Tags.AllowAffiliate, 'Global.Options.Tags.AllowAffiliate'],
    [Tags.AllowOffer, 'Global.Options.Offer'],
    [Tags.AllowAdvertiser, 'Global.Options.Tags.AllowAdvertiser'],
]);
let TagsService = class TagsService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TagsMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
TagsService.ctorParameters = () => [
    { type: TranslateService }
];
TagsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TagsService);
export { TagsService };
