import { StoreNames } from '@ho/shared/constants/store-config.enum';
import { STANDARD_ALL_COLORS } from '@ho/shared/data-access/ui-settings';
export class ColorSchemeFactory {
    constructor(document) {
        this.document = document;
        this.root = document === null || document === void 0 ? void 0 : document.documentElement;
    }
    patchProperties(properties) {
        this.root.setAttribute('style', properties);
    }
    patchPropertiesFromWlConfig(scheme) {
        const localColorSchemesJson = localStorage.getItem(StoreNames.ColorScheme);
        const localColorSchemes = localColorSchemesJson ? JSON.parse(localColorSchemesJson) : null;
        if (scheme && !localColorSchemes) {
            this.patchProperties(Object.keys(scheme).reduce((accScheme, key) => {
                accScheme += scheme[key].reduce((acc, item) => {
                    acc += `${item.variable}:${item.value};`;
                    return acc;
                }, '');
                return accScheme;
            }, ''));
        }
        else if (localColorSchemes) {
            const localScheme = localColorSchemes.color_schemes.find((item) => item.name === localColorSchemes.selected_color_scheme).color_scheme;
            this.patchProperties(Object.keys(localScheme).reduce((accScheme, key) => {
                accScheme += localScheme[key].reduce((acc, item) => {
                    acc += `${item.variable}:${item.value};`;
                    return acc;
                }, '');
                return accScheme;
            }, ''));
        }
        else {
            this.patchProperties(STANDARD_ALL_COLORS.reduce((acc, item) => {
                acc += `${item.variable}:${item.value};`;
                return acc;
            }, ''));
        }
    }
}
