import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CampaignAccess;
(function (CampaignAccess) {
    CampaignAccess[CampaignAccess["Unlisted"] = 1] = "Unlisted";
    CampaignAccess[CampaignAccess["Private"] = 2] = "Private";
    CampaignAccess[CampaignAccess["ApplyToPromote"] = 3] = "ApplyToPromote";
    CampaignAccess[CampaignAccess["Public"] = 4] = "Public";
})(CampaignAccess || (CampaignAccess = {}));
export const CampaignAccessLabels = new Map([
    [CampaignAccess.Unlisted, 'Global.Options.CampaignAccessLabels.Unlisted'],
    [CampaignAccess.Private, 'Global.Options.CampaignAccessLabels.Private'],
    [CampaignAccess.ApplyToPromote, 'Global.Options.CampaignAccessLabels.ApplyToPromote'],
    [CampaignAccess.Public, 'Global.Options.CampaignAccessLabels.Public'],
]);
let CampaignAccessService = class CampaignAccessService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CampaignAccessLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
CampaignAccessService.ctorParameters = () => [
    { type: TranslateService }
];
CampaignAccessService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CampaignAccessService);
export { CampaignAccessService };
