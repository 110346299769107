import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { ProfileApiService } from '@ho/shared/data-access/core/lib/+state/profile-api.service';
import { BehaviorSubject } from 'rxjs';
let ProfileService = class ProfileService {
    constructor(_profileApiService) {
        this._profileApiService = _profileApiService;
        this.timeZoneChange$$ = new BehaviorSubject('+00:00');
    }
    get(section) {
        return this._profileApiService.get(section);
    }
    getMyAccount() {
        return this._profileApiService.getMyAccount();
    }
    patchSection(section, params) {
        return this._profileApiService.patchSection(section, params);
    }
    post(body) {
        return this._profileApiService.post(body);
    }
    getTimeZoneOffset() {
        return localStorage.getItem('timeZone') || '+00:00';
    }
    changeSecretKey(body) {
        return this._profileApiService.changeSecretKey(body);
    }
    uploadAvatar(image) {
        return this._profileApiService.uploadAvatar(image);
    }
    getAvatar() {
        return this._profileApiService.getAvatar();
    }
    removeAvatar() {
        return this._profileApiService.removeAvatar();
    }
};
ProfileService.ctorParameters = () => [
    { type: ProfileApiService }
];
ProfileService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [ProfileApiService])
], ProfileService);
export { ProfileService };
