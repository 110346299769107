import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "!raw-loader!./app.component.html";
import __NG_CLI_RESOURCE__1 from "./app.component.scss";
import { Component, HostBinding } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Settings } from 'luxon';
import { SessionService } from '@ho/shared/data-access/session';
let AppComponent = class AppComponent {
    constructor(_router, _authService, _translate) {
        this._router = _router;
        this._authService = _authService;
        this._translate = _translate;
        this._showAppLoader = true;
        this._dBlock = true;
    }
    ngOnInit() {
        // Set UTC as default local timezone (Luxon)
        Settings.defaultZoneName = 'utc';
        this._initAppLoader();
        // this._translate.addLangs(['en', 'ru']);
        // this._translate.currentLoader.getTranslation('ru');
        // this._translate.use('ru');
    }
    _initAppLoader() {
        this._router.events.subscribe((evt) => {
            if (evt instanceof NavigationStart) {
                this._showAppLoader = this._dBlock = true;
            }
            if (evt instanceof NavigationEnd || evt instanceof NavigationCancel) {
                this._showAppLoader = this._dBlock = false;
            }
        });
    }
};
AppComponent.ctorParameters = () => [
    { type: Router },
    { type: SessionService },
    { type: TranslateService }
];
AppComponent.propDecorators = {
    _showAppLoader: [{ type: HostBinding, args: ['class.loading',] }],
    _dBlock: [{ type: HostBinding, args: ['class.d-block',] }]
};
AppComponent = __decorate([
    Component({
        selector: 'ho-root',
        template: __NG_CLI_RESOURCE__0,
        styles: [__NG_CLI_RESOURCE__1]
    }),
    __metadata("design:paramtypes", [Router,
        SessionService,
        TranslateService])
], AppComponent);
export { AppComponent };
