import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var TrafficChannelTypes;
(function (TrafficChannelTypes) {
    TrafficChannelTypes["Email"] = "email";
    TrafficChannelTypes["Native"] = "native";
    TrafficChannelTypes["Seo"] = "seo";
    TrafficChannelTypes["Push"] = "push";
    TrafficChannelTypes["Sms"] = "sms";
    TrafficChannelTypes["Bing"] = "bing";
    TrafficChannelTypes["Fb"] = "fb";
    TrafficChannelTypes["GoogleSearch"] = "google_search";
    TrafficChannelTypes["Pops"] = "pops";
    TrafficChannelTypes["Youtube"] = "youtube";
    TrafficChannelTypes["Mixed"] = "mixed";
    TrafficChannelTypes["Twitter"] = "twitter";
    TrafficChannelTypes["Tiktok"] = "tiktok";
    TrafficChannelTypes["Gdn"] = "gdn";
})(TrafficChannelTypes || (TrafficChannelTypes = {}));
export const TrafficChannelTypesMap = new Map([
    [TrafficChannelTypes.Email, 'Global.Options.TrafficChannelTypes.Email'],
    [TrafficChannelTypes.Native, 'Global.Options.TrafficChannelTypes.Native'],
    [TrafficChannelTypes.Seo, 'Global.Options.TrafficChannelTypes.Seo'],
    [TrafficChannelTypes.Push, 'Global.Options.TrafficChannelTypes.Push'],
    [TrafficChannelTypes.Sms, 'Global.Options.TrafficChannelTypes.Sms'],
    [TrafficChannelTypes.Bing, 'Global.Options.TrafficChannelTypes.Bing'],
    [TrafficChannelTypes.Fb, 'Global.Options.TrafficChannelTypes.Fb'],
    [TrafficChannelTypes.GoogleSearch, 'Global.Options.TrafficChannelTypes.GoogleSearch'],
    [TrafficChannelTypes.Pops, 'Global.Options.TrafficChannelTypes.Pops'],
    [TrafficChannelTypes.Youtube, 'Global.Options.TrafficChannelTypes.Youtube'],
    [TrafficChannelTypes.Mixed, 'Global.Options.TrafficChannelTypes.Mixed'],
    [TrafficChannelTypes.Twitter, 'Global.Options.TrafficChannelTypes.Twitter'],
    [TrafficChannelTypes.Tiktok, 'Global.Options.TrafficChannelTypes.Tiktok'],
    [TrafficChannelTypes.Gdn, 'Global.Options.TrafficChannelTypes.Gdn'],
]);
let TrafficChannelsTypesService = class TrafficChannelsTypesService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TrafficChannelTypesMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
TrafficChannelsTypesService.ctorParameters = () => [
    { type: TranslateService }
];
TrafficChannelsTypesService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TrafficChannelsTypesService);
export { TrafficChannelsTypesService };
