export class FileHelper {
    static save(r, defaultFilename) {
        const blob = new Blob([r.body], { type: r.headers.get('Content-Type') });
        const filename = FileHelper.extractFilename(r.headers) || defaultFilename;
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename);
        }
        else {
            // In FF link must be added to DOM to be clicked
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
    static extractFilename(h) {
        const cd = h.get('Content-Disposition');
        if (!cd) {
            return null;
        }
        const match = /filename=(?:"([^"]+)"|([^;]+))/.exec(cd);
        return match[1] || null;
    }
}
