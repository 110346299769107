import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { CountersApiService } from '@ho/shared/data-access/core/lib/+state/counters-api.service';
import { BehaviorSubject } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
const DEFAULT_VALUE = {
    new_affiliate_request_count: 0,
    pending_offer_campaign_user_rule_count: 0,
    pending_admin_campaign_user_rule_count: 0,
    pending_campaign_user_rule_count: 0,
};
let CountersService = class CountersService {
    constructor(_countersApiService) {
        this._countersApiService = _countersApiService;
        this.counters$ = new BehaviorSubject(DEFAULT_VALUE);
    }
    counters() {
        return this._countersApiService.counters().pipe(pluck('data'), tap((r) => this.counters$.next(r)));
    }
};
CountersService.ctorParameters = () => [
    { type: CountersApiService }
];
CountersService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [CountersApiService])
], CountersService);
export { CountersService };
