import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { AccountApiService } from '@ho/shared/data-access/core/lib/+state/account-api.service';
let AccountService = class AccountService {
    constructor(_accountApiService) {
        this._accountApiService = _accountApiService;
    }
    get(options) {
        return this._accountApiService.get(options);
    }
    changePassword(body) {
        return this._accountApiService.changePassword(body);
    }
};
AccountService.ctorParameters = () => [
    { type: AccountApiService }
];
AccountService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [AccountApiService])
], AccountService);
export { AccountService };
