import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pluck } from 'rxjs/operators';
let TrackingLinksApiService = class TrackingLinksApiService {
    constructor(httpClient) {
        this.httpClient = httpClient;
    }
    config(body, options) {
        return this.httpClient
            .post('/affiliate-campaigns/tracking-link-configs', body, options)
            .pipe(pluck('data'));
    }
};
TrackingLinksApiService.ctorParameters = () => [
    { type: HttpClient }
];
TrackingLinksApiService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [HttpClient])
], TrackingLinksApiService);
export { TrackingLinksApiService };
