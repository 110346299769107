import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var CampaignRequestStatuses;
(function (CampaignRequestStatuses) {
    CampaignRequestStatuses[CampaignRequestStatuses["Pending"] = 0] = "Pending";
    CampaignRequestStatuses[CampaignRequestStatuses["Rejected"] = 1] = "Rejected";
    CampaignRequestStatuses[CampaignRequestStatuses["Approved"] = 2] = "Approved";
})(CampaignRequestStatuses || (CampaignRequestStatuses = {}));
export const CampaignRequestStatusesMap = new Map([
    [CampaignRequestStatuses.Pending, 'Global.Status.Pending'],
    [CampaignRequestStatuses.Rejected, 'Global.Status.Rejected'],
    [CampaignRequestStatuses.Approved, 'Global.Status.Approved'],
]);
let CampaignRequestStatusesService = class CampaignRequestStatusesService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = CampaignRequestStatusesMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
CampaignRequestStatusesService.ctorParameters = () => [
    { type: TranslateService }
];
CampaignRequestStatusesService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], CampaignRequestStatusesService);
export { CampaignRequestStatusesService };
