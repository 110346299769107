import { APP_INITIALIZER } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { SessionQuery, SessionService, UserWorkspaceMap, UserWorkspaceRole, } from '@ho/shared/data-access/session';
import { ConfirmModalService } from '@ho/shared/ui/confirm-modal';
import { SnackbarService } from '@ho/shared/ui/snack-bar';
import { Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { fromEvent, interval } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import { filter, flatMap } from 'rxjs/operators';
export const ServiceWorkerInitializerProvider = {
    provide: APP_INITIALIZER,
    useFactory: (updates, confirm, snack, sessionService, sessionQuery, environment) => () => {
        unregisterExtraSW();
        if (!window.navigator.onLine) {
            snack.error('Sorry, you have no internet connection :(', '', 3600000);
        }
        updates.available.subscribe((event) => {
            if (event.available) {
                const itsAuthApp = !environment.role.length;
                const deployedApps = JSON.parse(localStorage.getItem('deployed_apps'));
                deployedApps && deployedApps.length && deployedApps.includes(itsAuthApp ? 0 : environment.role[0])
                    ? updateAppSWCache(deployedApps, itsAuthApp)
                    : displayConfirmModal(itsAuthApp);
            }
        });
        if (updates.isEnabled) {
            interval(60000)
                .pipe(flatMap(() => fromPromise(updates.checkForUpdate())))
                .subscribe();
        }
        fromEvent(window, 'offline').subscribe((_) => {
            snack.error("Sorry, you've lost your internet connection :(", '', 3600000);
        });
        function unregisterExtraSW() {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (const registration of registrations) {
                        if (location.href.indexOf(registration.scope) === -1) {
                            registration.unregister();
                        }
                    }
                });
            }
        }
        function updateAppSWCache(deployedApps, itsAuthApp) {
            deployedApps.forEach((app, index) => {
                if (environment.role.includes(app) || (app === 0 && itsAuthApp)) {
                    deployedApps.splice(index, 1);
                    refreshPage(deployedApps);
                }
            });
        }
        function displayConfirmModal(itsAuthApp) {
            confirm
                .open({
                title: 'New version has been released',
                body: 'Please refresh the page to view the up to date data.',
                resolveText: 'Refresh',
                rejectText: 'Stay here',
            })
                .afterClosed()
                .pipe(filter((r) => !!r))
                .subscribe((_) => {
                const deployedApps = [...(!itsAuthApp ? [0] : [])]; //add 0 for auth app in case of current app is not auth
                UserWorkspaceMap.forEach((value, key) => {
                    // mark other apps as not updated
                    if (![UserWorkspaceRole.Guest, UserWorkspaceRole.ADM].includes(key) &&
                        !environment.role.includes(key)) {
                        deployedApps.push(key);
                    }
                });
                Sentry.setTag('CLIENT_ID', environment.sentryClientTag);
                Sentry.setTag('USER_ROLE', environment.role.length ? UserWorkspaceMap.get(environment.role[0]) : 'auth');
                Sentry.captureMessage('New deploy modal', Severity.Info);
                refreshPage(deployedApps);
            });
        }
        function refreshPage(deployedApps) {
            localStorage.setItem('deployed_apps', JSON.stringify(deployedApps));
            if (environment.role.length && sessionQuery.token() && sessionQuery.token().length) {
                sessionService.autologin(sessionQuery.token()).subscribe();
            }
            document.location.reload();
        }
    },
    deps: [SwUpdate, ConfirmModalService, SnackbarService, SessionService, SessionQuery, AppEnvironment],
    multi: true,
};
