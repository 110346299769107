import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var TrafficSafety;
(function (TrafficSafety) {
    TrafficSafety[TrafficSafety["Safe"] = 1] = "Safe";
    TrafficSafety[TrafficSafety["Mixed"] = 2] = "Mixed";
    TrafficSafety[TrafficSafety["Risky"] = 3] = "Risky";
})(TrafficSafety || (TrafficSafety = {}));
export const TrafficSafetyMap = new Map([
    [TrafficSafety.Safe, 'Global.Options.TrafficSafety.Safe'],
    [TrafficSafety.Mixed, 'Global.Options.TrafficSafety.Mixed'],
    [TrafficSafety.Risky, 'Global.Options.TrafficSafety.Risky'],
]);
let TrafficSafetyService = class TrafficSafetyService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = TrafficSafetyMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
TrafficSafetyService.ctorParameters = () => [
    { type: TranslateService }
];
TrafficSafetyService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], TrafficSafetyService);
export { TrafficSafetyService };
