import { __decorate, __metadata } from "tslib";
import { Inject, Injectable } from '@angular/core';
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { SessionQuery } from './session.query';
export var UserWorkspaceRole;
(function (UserWorkspaceRole) {
    UserWorkspaceRole[UserWorkspaceRole["Guest"] = -1] = "Guest";
    UserWorkspaceRole[UserWorkspaceRole["Admin"] = 1] = "Admin";
    UserWorkspaceRole[UserWorkspaceRole["ATL"] = 2] = "ATL";
    UserWorkspaceRole[UserWorkspaceRole["AM"] = 3] = "AM";
    UserWorkspaceRole[UserWorkspaceRole["Aff"] = 4] = "Aff";
    UserWorkspaceRole[UserWorkspaceRole["AD"] = 5] = "AD";
    UserWorkspaceRole[UserWorkspaceRole["ADM"] = 6] = "ADM";
})(UserWorkspaceRole || (UserWorkspaceRole = {}));
export const UserWorkspaceMap = new Map([
    [UserWorkspaceRole.Guest, 'guest'],
    [UserWorkspaceRole.Admin, 'admin'],
    [UserWorkspaceRole.ATL, 'atl'],
    [UserWorkspaceRole.AM, 'manager'],
    [UserWorkspaceRole.Aff, 'affiliate'],
    [UserWorkspaceRole.AD, 'customer'],
    [UserWorkspaceRole.ADM, 'adm'],
]);
let SessionUserTypeService = class SessionUserTypeService {
    constructor(environment, _sessionQuery) {
        this.environment = environment;
        this._sessionQuery = _sessionQuery;
        this.userRole = UserWorkspaceRole;
    }
    isAdmin() {
        return this.environment.role.includes(UserWorkspaceRole.Admin);
    }
    isATL() {
        return this.environment.role.includes(UserWorkspaceRole.ATL);
    }
    isAffiliate() {
        return this.environment.role.includes(UserWorkspaceRole.Aff);
    }
    isManager() {
        return this.environment.role.includes(UserWorkspaceRole.AM);
    }
    isADM() {
        return this.environment.role.includes(UserWorkspaceRole.ADM);
    }
    isAD() {
        return this.environment.role.includes(UserWorkspaceRole.AD);
    }
    isAdminRole() {
        return this._sessionQuery.userInfo().workspace_role_id === this.userRole.Admin;
    }
    isATLRole() {
        return this._sessionQuery.userInfo().workspace_role_id === this.userRole.ATL;
    }
    isAffiliateRole() {
        return this._sessionQuery.userInfo().workspace_role_id === this.userRole.Aff;
    }
    isManagerRole() {
        return this._sessionQuery.userInfo().workspace_role_id === this.userRole.AM;
    }
    isADRole() {
        return this._sessionQuery.userInfo().workspace_role_id === this.userRole.AD;
    }
    getAPIUserRoleUrl() {
        const userInfo = this._sessionQuery.userInfo();
        const workspace_role_id = userInfo ? userInfo.workspace_role_id : this.userRole.Guest;
        return UserWorkspaceMap.get(workspace_role_id);
    }
};
SessionUserTypeService.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] },
    { type: SessionQuery }
];
SessionUserTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [Object, SessionQuery])
], SessionUserTypeService);
export { SessionUserTypeService };
