import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PayoutRollbackStatus;
(function (PayoutRollbackStatus) {
    PayoutRollbackStatus[PayoutRollbackStatus["AwaitingInvoice"] = 1] = "AwaitingInvoice";
    PayoutRollbackStatus[PayoutRollbackStatus["Bounced"] = 3] = "Bounced";
    PayoutRollbackStatus[PayoutRollbackStatus["InvoiceConfirmed"] = 7] = "InvoiceConfirmed";
    PayoutRollbackStatus[PayoutRollbackStatus["PaymentSent"] = 8] = "PaymentSent";
})(PayoutRollbackStatus || (PayoutRollbackStatus = {}));
export const PayoutRollbackStatusLabels = new Map([
    [PayoutRollbackStatus.AwaitingInvoice, 'Statuses.PayoutRollbackStatusLabels.AwaitingInvoice'],
    [PayoutRollbackStatus.Bounced, 'Statuses.PayoutRollbackStatusLabels.Bounced'],
    [PayoutRollbackStatus.InvoiceConfirmed, 'Statuses.PayoutRollbackStatusLabels.InvoiceConfirmed'],
    [PayoutRollbackStatus.PaymentSent, 'Statuses.PayoutRollbackStatusLabels.PaymentSent'],
]);
let PayoutRollbackStatusService = class PayoutRollbackStatusService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PayoutRollbackStatusLabels;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
PayoutRollbackStatusService.ctorParameters = () => [
    { type: TranslateService }
];
PayoutRollbackStatusService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PayoutRollbackStatusService);
export { PayoutRollbackStatusService };
