import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { cloneDeep } from 'lodash-es';
import { UiSettingsGridCacheStore } from './ui-settings-grid-cache.store';
let UiSettingsGridCacheQuery = class UiSettingsGridCacheQuery extends Query {
    constructor(gridCacheStore) {
        super(gridCacheStore);
        this.gridCacheStore = gridCacheStore;
    }
    getCache() {
        return cloneDeep(this.getValue().cache);
    }
    getPayload() {
        return cloneDeep(this.getValue().payload);
    }
};
UiSettingsGridCacheQuery.ctorParameters = () => [
    { type: UiSettingsGridCacheStore }
];
UiSettingsGridCacheQuery = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsGridCacheStore])
], UiSettingsGridCacheQuery);
export { UiSettingsGridCacheQuery };
