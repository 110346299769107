import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { AlertsApiService } from '@ho/shared/data-access/core/lib/+state/alerts-api.service';
import { map } from 'rxjs/operators';
import { SessionUserTypeService } from '@ho/shared/data-access/session';
import { AlertPriority } from '@ho/shared/data-access/core-data-access';
let AlertsService = class AlertsService {
    constructor(_alertsApiService, _sessionUserType) {
        this._alertsApiService = _alertsApiService;
        this._sessionUserType = _sessionUserType;
    }
    list(body) {
        return this._alertsApiService.list(body).pipe(map((response) => {
            response.body['data'].forEach((alert) => {
                alert = this._getContent(alert);
                alert['priorityClass'] = this._getPriorityClass(alert.priority);
            });
            return response;
        }));
    }
    head(params) {
        return this._alertsApiService.head(params).pipe(map((res) => {
            const count = parseInt(res.headers.get('x-new-alert-count'), 10) || 0;
            return count;
        }));
    }
    batchMarkAsRead(body) {
        return this._alertsApiService.batchMarkAsRead(body);
    }
    batchDelete(body) {
        return this._alertsApiService.batchDelete(body);
    }
    _getContent(alert) {
        var _a, _b, _c;
        alert.content = alert.content
            .replace('{offer_campaign_name}', (alert === null || alert === void 0 ? void 0 : alert.extra) ? this._getOfferGroupsLink(alert.extra) : '')
            .replace('{offer_campaign_id}', (((_a = alert === null || alert === void 0 ? void 0 : alert.extra) === null || _a === void 0 ? void 0 : _a.offer_campaign_id) || ''))
            .replace('{admin_campaign_name}', (alert === null || alert === void 0 ? void 0 : alert.extra) ? this._getOfferLink(alert.extra) : '')
            .replace('{admin_campaign_id}', (((_b = alert === null || alert === void 0 ? void 0 : alert.extra) === null || _b === void 0 ? void 0 : _b.admin_campaign_id) || ''))
            .replace('{affiliate_name}', (alert === null || alert === void 0 ? void 0 : alert.extra) ? this._getAffiliateLink(alert.extra) : '')
            .replace('{affiliate_id}', (((_c = alert === null || alert === void 0 ? void 0 : alert.extra) === null || _c === void 0 ? void 0 : _c.affiliate_id) || ''));
        return alert;
    }
    _getOfferGroupsLink(extra) {
        let link = '';
        if (this._sessionUserType.isAdmin() || this._sessionUserType.isManager()) {
            link = `<a href="d/offer-management/offer-groups/offer-group/${extra.offer_campaign_id}" title="View Offer Group">${extra.offer_campaign_name}</a>`;
        }
        if (this._sessionUserType.isAffiliate()) {
            link = `<a href="d/offer-management/offer-catalogue/offer-group/${extra.offer_campaign_id}" title="View Offer Group">${extra.offer_campaign_name}</a>`;
        }
        return link;
    }
    _getOfferLink(extra) {
        let link = '';
        if (this._sessionUserType.isAdmin() || this._sessionUserType.isManager()) {
            link = `<a href="d/offer-management/offer-groups/offer/${extra.admin_campaign_id}" title="View Offer">${extra.admin_campaign_name}</a>`;
        }
        if (this._sessionUserType.isAffiliate()) {
            link = `<a href="d/offer-management/offer-catalogue/offer/${extra.admin_campaign_id}" title="View Offer">${extra.admin_campaign_name}</a>`;
        }
        return link;
    }
    _getAffiliateLink(extra) {
        let link = '';
        if (this._sessionUserType.isAdmin() || this._sessionUserType.isManager()) {
            link = `<a href="d/user-operations/affiliates/${extra.affiliate_id}" title="View Affiliate">${extra.affiliate_name}</a>`;
        }
        return link;
    }
    _getPriorityClass(status) {
        switch (status) {
            case AlertPriority.High:
                return 'text-danger';
            case AlertPriority.Medium:
                return 'text-warning';
            case AlertPriority.Low:
                return 'text-success';
        }
    }
};
AlertsService.ctorParameters = () => [
    { type: AlertsApiService },
    { type: SessionUserTypeService }
];
AlertsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [AlertsApiService,
        SessionUserTypeService])
], AlertsService);
export { AlertsService };
