import { __decorate, __metadata } from "tslib";
import { SessionQuery, SessionUserTypeService } from '@ho/shared/data-access/session';
import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { intersection } from 'lodash-es';
let GoogleAnalyticsService = class GoogleAnalyticsService {
    constructor(_route, _router, _authQuery, _sessionUserTypeService) {
        this._route = _route;
        this._router = _router;
        this._authQuery = _authQuery;
        this._sessionUserTypeService = _sessionUserTypeService;
        this.triggerOnPages = ['reports', 'dashboard', 'billing', 'campaigns', 'referral-program'];
    }
    emitEvent(event, eventCategory, eventLabel) {
        const url = this._router.url.match('^[^?]+')[0].split('/');
        // TODO: ugly solution because of AFS-4145
        if (typeof gtag !== 'undefined' &&
            intersection(url, this.triggerOnPages).length &&
            (this._sessionUserTypeService.isATL() || this._sessionUserTypeService.isAdmin())) {
            gtag('event', event, {
                event_category: eventCategory,
                event_label: eventLabel
            });
        }
    }
};
GoogleAnalyticsService.ctorParameters = () => [
    { type: ActivatedRoute },
    { type: Router },
    { type: SessionQuery },
    { type: SessionUserTypeService }
];
GoogleAnalyticsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [ActivatedRoute,
        Router,
        SessionQuery,
        SessionUserTypeService])
], GoogleAnalyticsService);
export { GoogleAnalyticsService };
