import { __decorate, __metadata } from "tslib";
import __NG_CLI_RESOURCE__0 from "./warning-snack-bar.component.scss";
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
let WarningSnackBarComponent = class WarningSnackBarComponent {
    constructor(data, snackBarRef) {
        this.data = data;
        this.snackBarRef = snackBarRef;
    }
    onClose() {
        this.snackBarRef.dismiss();
    }
};
WarningSnackBarComponent.ctorParameters = () => [
    { type: undefined, decorators: [{ type: Inject, args: [MAT_SNACK_BAR_DATA,] }] },
    { type: MatSnackBarRef }
];
WarningSnackBarComponent = __decorate([
    Component({
        selector: 'ho-warning-snack-bar',
        template: `
    <div
      fxLayout="row nowrap"
      class="snack-container toast-warning"
      fxLayoutAlign="flex-start center"
      fxLayoutGap="10px"
      (click)="onClose()"
    >
      <i class="icon-exclamation-circle snack-container__icon"></i>
      <div fxLayout="column">
        <div>
          <b>{{ data?.title }}</b>
        </div>
        <div>
          <span>{{ data?.message }}</span>
        </div>
      </div>
      <button class="snack-container__icon snack-container__close-btn" (click)="onClose()">
        <span class="icon-close"></span>
      </button>
    </div>
  `,
        styles: [__NG_CLI_RESOURCE__0]
    }),
    __metadata("design:paramtypes", [Object, MatSnackBarRef])
], WarningSnackBarComponent);
export { WarningSnackBarComponent };
