import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ForceBillingPeriodReason;
(function (ForceBillingPeriodReason) {
    ForceBillingPeriodReason[ForceBillingPeriodReason["PaymentTermsChanged"] = 0] = "PaymentTermsChanged";
    ForceBillingPeriodReason[ForceBillingPeriodReason["AffiliateRequest"] = 1] = "AffiliateRequest";
    ForceBillingPeriodReason[ForceBillingPeriodReason["BannedUser"] = 2] = "BannedUser";
    ForceBillingPeriodReason[ForceBillingPeriodReason["Other"] = 3] = "Other";
})(ForceBillingPeriodReason || (ForceBillingPeriodReason = {}));
export const ForceBillingPeriodReasonMap = new Map([
    [
        ForceBillingPeriodReason.PaymentTermsChanged,
        'Global.Options.ForceBillingPeriodReason.PaymentTermsChanged',
    ],
    [ForceBillingPeriodReason.AffiliateRequest, 'Global.Options.ForceBillingPeriodReason.AffiliateRequest'],
    [ForceBillingPeriodReason.BannedUser, 'Global.Options.ForceBillingPeriodReason.BannedUser'],
    [ForceBillingPeriodReason.Other, 'Global.Options.ForceBillingPeriodReason.Other'],
]);
let ForceBillingPeriodReasonService = class ForceBillingPeriodReasonService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ForceBillingPeriodReasonMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
ForceBillingPeriodReasonService.ctorParameters = () => [
    { type: TranslateService }
];
ForceBillingPeriodReasonService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ForceBillingPeriodReasonService);
export { ForceBillingPeriodReasonService };
