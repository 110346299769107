import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { UiSettingsQuery } from '../+state/ui-settings.query';
import { UiSettingsStore } from '../+state/ui-settings.store';
import { UiSettingsService } from './ui-settings.service';
import { UiSettingsApiService } from './ui-settings-api.service';
import { prepareBodyParams } from '@ho/shared/utils';
let ModalSettingsService = class ModalSettingsService {
    constructor(_uiSettingsService, _uiSettingsAipService, _uiSettingsQuery, _uiSettingsStore) {
        this._uiSettingsService = _uiSettingsService;
        this._uiSettingsAipService = _uiSettingsAipService;
        this._uiSettingsQuery = _uiSettingsQuery;
        this._uiSettingsStore = _uiSettingsStore;
        this._userViewType = localStorage.getItem('user_view_type');
    }
    getModalSettingsFromApi() {
        return this._userViewType
            ? of({})
            : this._uiSettingsService.getUiSettings().pipe(tap((res) => this._uiSettingsStore.updateModalSettings(res.modal_settings || {})), map((r) => r.modal_settings || {}));
    }
    getModalSetting(id) {
        return new Observable((observer) => {
            const queryModalSettings = this._uiSettingsQuery.queryModalSettings;
            if (Object.keys(queryModalSettings).length && queryModalSettings[id]) {
                observer.next(queryModalSettings[id]);
                observer.complete();
            }
            else {
                this.getModalSettingsFromApi().subscribe((r) => {
                    if (!r[id]) {
                        observer.complete();
                        return;
                    }
                    observer.next(r[id]);
                    observer.complete();
                });
            }
        });
    }
    updateModalConfig(id, data) {
        const settings = prepareBodyParams(data);
        return (this._userViewType
            ? of({})
            : this._uiSettingsAipService.saveModalSettings({
                modal_id: id,
                modal_settings: settings,
            })).pipe(tap(() => {
            this._uiSettingsStore.updateModalSettings({
                [id]: settings,
            });
        }));
    }
};
ModalSettingsService.ctorParameters = () => [
    { type: UiSettingsService },
    { type: UiSettingsApiService },
    { type: UiSettingsQuery },
    { type: UiSettingsStore }
];
ModalSettingsService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [UiSettingsService,
        UiSettingsApiService,
        UiSettingsQuery,
        UiSettingsStore])
], ModalSettingsService);
export { ModalSettingsService };
