import { __decorate } from "tslib";
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileTooltipHandlerDirective } from './mobile-tooltip-handler.directive';
let MobileTooltipHandlerModule = class MobileTooltipHandlerModule {
};
MobileTooltipHandlerModule = __decorate([
    NgModule({
        imports: [CommonModule],
        declarations: [MobileTooltipHandlerDirective],
        exports: [MobileTooltipHandlerDirective],
    })
], MobileTooltipHandlerModule);
export { MobileTooltipHandlerModule };
