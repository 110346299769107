import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UiSettingsStore } from './ui-settings.store';
let UiSettingsQuery = class UiSettingsQuery extends Query {
    constructor(gridSettingsStore) {
        super(gridSettingsStore);
        this.gridSettingsStore = gridSettingsStore;
    }
    get queryGridSettings() {
        return JSON.parse(JSON.stringify(this.getValue().grid_settings || {}));
    }
    get queryDashboardSettings() {
        return JSON.parse(JSON.stringify(this.getValue().dashboard_settings || {}));
    }
    get queryModalSettings() {
        return JSON.parse(JSON.stringify(this.getValue().modal_settings || {}));
    }
    get queryThemeSettings() {
        return JSON.parse(JSON.stringify(this.getValue().theme_settings || {}));
    }
    get queryTutorialSettings() {
        return JSON.parse(JSON.stringify(this.getValue().tutorial_settings || {}));
    }
    get querySkipTutorial() {
        var _a, _b;
        return JSON.parse(JSON.stringify(((_b = (_a = this.getValue()) === null || _a === void 0 ? void 0 : _a.tutorial_settings) === null || _b === void 0 ? void 0 : _b.skip_tutorial) || false));
    }
    get queryConfigSettings() {
        return JSON.parse(JSON.stringify(this.getValue().config_settings || {}));
    }
};
UiSettingsQuery.ctorParameters = () => [
    { type: UiSettingsStore }
];
UiSettingsQuery = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsStore])
], UiSettingsQuery);
export { UiSettingsQuery };
