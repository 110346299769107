import { __decorate, __metadata } from "tslib";
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmModalContainer } from '../containers/confirm-modal/confirm-modal.container';
import { ModalHelper } from '@ho/shared/helpers/modal.helper';
import { ConfirmActionModalContainer } from '../containers/confirm-action-modal/confirm-action-modal.container';
let ConfirmModalService = class ConfirmModalService {
    constructor(dialog) {
        this.dialog = dialog;
        this._defaultOptions = {
            maxWidth: '590px',
            width: 'calc(100% - 30px)',
            minWidth: '290px',
            autoFocus: false,
            panelClass: 'modal-dialog',
        };
    }
    open(data) {
        const ref = this.dialog.open(ConfirmModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: Object.assign({}, data) }));
        ModalHelper.addClassToParent(ref);
        return ref;
    }
    openAction(data) {
        const ref = this.dialog.open(ConfirmActionModalContainer, Object.assign(Object.assign({}, this._defaultOptions), { data: Object.assign({}, data) }));
        ModalHelper.addClassToParent(ref);
        return ref;
    }
};
ConfirmModalService.ctorParameters = () => [
    { type: MatDialog }
];
ConfirmModalService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [MatDialog])
], ConfirmModalService);
export { ConfirmModalService };
