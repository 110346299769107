import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { pluck } from 'rxjs/operators';
let UiSettingsApiService = class UiSettingsApiService {
    constructor(_httpClient) {
        this._httpClient = _httpClient;
        this._endpointUrl = '/me/ui-settings';
    }
    getUiSettings() {
        return this._httpClient.get(this._endpointUrl).pipe(pluck('data', 'ui_settings'));
    }
    saveGridSettings(body) {
        if (!body.length) {
            return of({});
        }
        return this._httpClient.put(`${this._endpointUrl}/grid`, body);
    }
    saveDashboardSettings(body) {
        if (!Object.keys(body.ui_settings).length) {
            return of({});
        }
        return this._httpClient.put(`${this._endpointUrl}/dashboard`, {
            dashboard_id: body.dashboard_id,
            ui_settings: body.ui_settings,
        });
    }
    saveModalSettings(body) {
        if (!Object.keys(body.modal_settings).length) {
            return of({});
        }
        return this._httpClient.put(`${this._endpointUrl}/modal`, {
            modal_id: body.modal_id,
            ui_settings: body.modal_settings,
        });
    }
    updateColorSchemes(schemes) {
        return this._httpClient.put(`${this._endpointUrl}/color-schemes`, schemes);
    }
    skipTutorial() {
        return this._httpClient.put(`${this._endpointUrl}/tutorial`, { skip_tutorial: true });
    }
    updateConfigSettings(configSettings) {
        if (!Object.keys(configSettings).length) {
            return of({});
        }
        const body = {
            config_settings: configSettings,
        };
        return this._httpClient.put(`${this._endpointUrl}/config`, body);
    }
};
UiSettingsApiService.ctorParameters = () => [
    { type: HttpClient }
];
UiSettingsApiService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient])
], UiSettingsApiService);
export { UiSettingsApiService };
