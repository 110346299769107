import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TrackingLinksApiService, } from '@ho/shared/data-access/core/lib/+state/tracking-links-api.service';
let TrackingLinksService = class TrackingLinksService {
    constructor(_trackingLinksApiService) {
        this._trackingLinksApiService = _trackingLinksApiService;
    }
    config(body, options) {
        return this._trackingLinksApiService.config(body, options);
    }
};
TrackingLinksService.ctorParameters = () => [
    { type: TrackingLinksApiService }
];
TrackingLinksService = __decorate([
    Injectable({
        providedIn: 'root',
    }),
    __metadata("design:paramtypes", [TrackingLinksApiService])
], TrackingLinksService);
export { TrackingLinksService };
