var SessionDataAccessModule_1;
import { __decorate, __metadata } from "tslib";
import { SessionQuery } from './state/session.query';
import { SessionStore } from './state/session.store';
import { SessionService } from './state/session.service';
import { SessionApiService } from './state/session-api.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SessionUserTypeService } from './state/session-user-type-service.service';
let SessionDataAccessModule = SessionDataAccessModule_1 = class SessionDataAccessModule {
    constructor(parentModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
    static forRoot() {
        return {
            ngModule: SessionDataAccessModule_1,
            providers: [
                SessionApiService,
                SessionService,
                SessionStore,
                SessionQuery,
                SessionUserTypeService,
                { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
                JwtHelperService,
            ],
        };
    }
};
SessionDataAccessModule.ctorParameters = () => [
    { type: SessionDataAccessModule, decorators: [{ type: Optional }, { type: SkipSelf }] }
];
SessionDataAccessModule = SessionDataAccessModule_1 = __decorate([
    NgModule({
        imports: [CommonModule],
    }),
    __metadata("design:paramtypes", [SessionDataAccessModule])
], SessionDataAccessModule);
export { SessionDataAccessModule };
