import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var NotPayableSalesReasonsStatus;
(function (NotPayableSalesReasonsStatus) {
    NotPayableSalesReasonsStatus["commission_type_not_matched"] = "commission_type_not_matched";
    NotPayableSalesReasonsStatus["recurrent_duration_exceeded"] = "recurrent_duration_exceeded";
    NotPayableSalesReasonsStatus["recurrent_cpa_sale"] = "recurrent_cpa_sale";
    NotPayableSalesReasonsStatus["click_session_lifespan_exceeded"] = "click_session_lifespan_exceeded";
})(NotPayableSalesReasonsStatus || (NotPayableSalesReasonsStatus = {}));
export const NotPayableSalesReasonsStatusMap = new Map([
    [
        NotPayableSalesReasonsStatus.commission_type_not_matched,
        'ActionInfoModal.Payable.Tooltip.CommissionTypeNotMatched',
    ],
    [
        NotPayableSalesReasonsStatus.recurrent_duration_exceeded,
        'ActionInfoModal.Payable.Tooltip.RecurrentDurationsExceeded',
    ],
    [NotPayableSalesReasonsStatus.recurrent_cpa_sale, 'ActionInfoModal.Payable.Tooltip.RecurrentCPASale'],
    [
        NotPayableSalesReasonsStatus.click_session_lifespan_exceeded,
        'ActionInfoModal.Payable.Tooltip.ClickSessionLifespanExceeded',
    ],
]);
let NotPayableSalesReasonsService = class NotPayableSalesReasonsService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = NotPayableSalesReasonsStatusMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
NotPayableSalesReasonsService.ctorParameters = () => [
    { type: TranslateService }
];
NotPayableSalesReasonsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], NotPayableSalesReasonsService);
export { NotPayableSalesReasonsService };
