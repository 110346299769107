import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PixelCategoryType;
(function (PixelCategoryType) {
    PixelCategoryType["global"] = "global";
    PixelCategoryType["affiliate_campaign"] = "affiliate_campaign";
    PixelCategoryType["admin_campaign"] = "admin_campaign";
    PixelCategoryType["traffic_source"] = "traffic_source";
    PixelCategoryType["marketing_campaign"] = "marketing_campaign";
})(PixelCategoryType || (PixelCategoryType = {}));
export const PixelCategoryTypeMap = new Map([
    [PixelCategoryType.global, 'Global.Options.PixelCategoryType.Global'],
    [PixelCategoryType.affiliate_campaign, 'Global.Options.PixelCategoryType.AffiliateCampaign'],
    [PixelCategoryType.admin_campaign, 'Global.Options.Offer'],
    [PixelCategoryType.traffic_source, 'Global.Options.PixelCategoryType.TrafficSource'],
    [PixelCategoryType.marketing_campaign, 'Global.Options.PixelCategoryType.MarketingCampaign'],
]);
let PixelCategoryTypeService = class PixelCategoryTypeService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PixelCategoryTypeMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
PixelCategoryTypeService.ctorParameters = () => [
    { type: TranslateService }
];
PixelCategoryTypeService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PixelCategoryTypeService);
export { PixelCategoryTypeService };
