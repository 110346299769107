import { APP_INITIALIZER } from '@angular/core';
import { CustomAppConfigService } from '@ho/shared/data-access/core/lib/services/custom-app-config.service';
export const customAppConfigInitializerProvider = {
    provide: APP_INITIALIZER,
    useFactory: function customAppConfigInitializerFactory(service) {
        return () => {
            if (navigator &&
                /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
                navigator.serviceWorker) {
                navigator.serviceWorker.getRegistrations().then((registrations) => {
                    for (const registration of registrations) {
                        registration.unregister();
                    }
                });
            }
            return service.init();
        };
    },
    multi: true,
    deps: [CustomAppConfigService],
};
