import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var PerformancesByProfit;
(function (PerformancesByProfit) {
    PerformancesByProfit["EPC"] = "epc";
    PerformancesByProfit["Clicks"] = "click_count";
    PerformancesByProfit["FTDs"] = "ftd_count";
    PerformancesByProfit["Revshare"] = "revshare_count";
    PerformancesByProfit["SystemProfit"] = "system_profit";
    PerformancesByProfit["IncomeBenchmark"] = "income_benchmark";
    PerformancesByProfit["CR"] = "cr";
    PerformancesByProfit["ROI"] = "roi";
    PerformancesByProfit["CLRate"] = "cl_rate";
})(PerformancesByProfit || (PerformancesByProfit = {}));
export const PerformancesByProfitMap = new Map([
    [PerformancesByProfit.EPC, 'Global.Options.PerformancesByProfit.EPC'],
    [PerformancesByProfit.Clicks, 'Global.Options.PerformancesByProfit.Clicks'],
    [PerformancesByProfit.FTDs, 'Global.Options.PerformancesByProfit.FTDs'],
    [PerformancesByProfit.Revshare, 'Global.Options.Revshare'],
    [PerformancesByProfit.SystemProfit, 'Global.Options.PerformancesByProfit.SystemProfit'],
    [PerformancesByProfit.IncomeBenchmark, 'Global.Options.PerformancesByProfit.IncomeBenchmark'],
    [PerformancesByProfit.CR, 'Global.Options.PerformancesByProfit.CR'],
    [PerformancesByProfit.ROI, 'Global.Options.PerformancesByProfit.ROI'],
    [PerformancesByProfit.CLRate, 'Global.Options.PerformancesByProfit.CLRate'],
]);
let PerformanceByProfitService = class PerformanceByProfitService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = PerformancesByProfitMap;
    }
    list() {
        return this._data;
    }
    get(status) {
        const s = this._data.get(status);
        return s ? this._translate.instant(s) : s;
    }
};
PerformanceByProfitService.ctorParameters = () => [
    { type: TranslateService }
];
PerformanceByProfitService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], PerformanceByProfitService);
export { PerformanceByProfitService };
