import { __decorate, __metadata } from "tslib";
import { AppEnvironment } from '@ho/shared/data-access/core/app-environment.model';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
let SessionApiService = class SessionApiService {
    constructor(_http, _environment) {
        this._http = _http;
        this._environment = _environment;
        this.endpointUrl = '/auth';
    }
    login(credentials) {
        return this._http.post(`/auth/login?th-lang=${localStorage.getItem('language') || 'en'}&ws=${location.origin}`, Object.assign(Object.assign({}, credentials), { ws: location.origin }), {
            withCredentials: true,
        });
    }
    currentUserInfo() {
        return this._http.get(`/me`);
    }
    myPermissions() {
        return this._http.get(`/rbac/my-permissions`);
    }
};
SessionApiService.ctorParameters = () => [
    { type: HttpClient },
    { type: undefined, decorators: [{ type: Inject, args: [AppEnvironment,] }] }
];
SessionApiService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, Object])
], SessionApiService);
export { SessionApiService };
