import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { UiSettingsApiService } from './ui-settings-api.service';
import { pluck } from 'rxjs/operators';
let TutorialSettingsService = class TutorialSettingsService {
    constructor(_uiSettingsApiService) {
        this._uiSettingsApiService = _uiSettingsApiService;
    }
    skipTutorial() {
        return this._uiSettingsApiService.skipTutorial().pipe(pluck('data'));
    }
};
TutorialSettingsService.ctorParameters = () => [
    { type: UiSettingsApiService }
];
TutorialSettingsService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [UiSettingsApiService])
], TutorialSettingsService);
export { TutorialSettingsService };
