import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { pluck } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { DataSourceHandlerService } from '../+state/data-source-handler.service';
let RedirectTypesService = class RedirectTypesService {
    constructor(_httpClient, _dataSourceHandlerService) {
        this._httpClient = _httpClient;
        this._dataSourceHandlerService = _dataSourceHandlerService;
        this._endpointUrl = '/dictionary';
    }
    /**
     *
     * @returns {Observable<T>}
     */
    list() {
        return this._dataSourceHandlerService
            .getData(`${this._endpointUrl}/redirect-types`)
            .pipe(pluck('data'))
            .toPromise();
    }
    /**
     *
     * @returns {Observable<T>}
     */
    get(id) {
        return this._httpClient
            .get(`${this._endpointUrl}/redirect-types/${id}`)
            .pipe(pluck('data'));
    }
};
RedirectTypesService.ctorParameters = () => [
    { type: HttpClient },
    { type: DataSourceHandlerService }
];
RedirectTypesService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [HttpClient, DataSourceHandlerService])
], RedirectTypesService);
export { RedirectTypesService };
