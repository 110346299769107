import { __decorate, __metadata } from "tslib";
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
export var ReportVisibility;
(function (ReportVisibility) {
    ReportVisibility["Active"] = "active";
    ReportVisibility["Hidden"] = "hidden";
})(ReportVisibility || (ReportVisibility = {}));
export const ReportVisibilityMap = new Map([
    [ReportVisibility.Active, 'Global.Select.ReportVisibility.Option.ShowActive'],
    [ReportVisibility.Hidden, 'Global.Select.ReportVisibility.Option.ShowHidden'],
]);
let ReportVisibilityService = class ReportVisibilityService {
    constructor(_translate) {
        this._translate = _translate;
        this._data = ReportVisibilityMap;
    }
    list() {
        return this._data;
    }
    get(key) {
        const k = this._data.get(key);
        return k ? this._translate.instant(k) : k;
    }
};
ReportVisibilityService.ctorParameters = () => [
    { type: TranslateService }
];
ReportVisibilityService = __decorate([
    Injectable(),
    __metadata("design:paramtypes", [TranslateService])
], ReportVisibilityService);
export { ReportVisibilityService };
